<template>
  <b-field :label="label" :type="fieldType">
    <div class="control has-icons-right is-clearfix" style="margin:0px;padding:0px">
      <cleave v-model="dateValue" placeholder="mm-dd-yyyy" :class="inputClass" :options="optionsDate" :raw="false" v-bind="$attrs"></cleave>
      <span v-if="required">
        <span v-if="isValidDate()" class="icon is-right has-text-success"><i class="mdi mdi-check mdi-24px"></i></span>
        <span v-else class="icon is-right has-text-danger"><i class="mdi mdi-alert-circle mdi-24px"></i></span>
      </span>
    </div>
  </b-field>
</template>

<script>
import Cleave from 'vue-cleave-component'
import fecha from 'fecha'

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    includeClass: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      optionsDate: {
        date: true,
        delimiter: '-',
        datePattern: ['m', 'd', 'Y']
      }
    }
  },
  components: {
    Cleave
  },
  computed: {
    dateValue: {
      get() {
        return this.value
      },
      set(date) {
        this.$emit('input', date)
      }
    },
    fieldType() {
      if (this.includeClass && (this.isRequired || this.dateValue.length > 0)) {
        return this.isValidDate() ? 'is-success' : 'is-danger'
      } else {
        return ''
      }
    },
    inputClass() {
      let inputClass = ''
      if (this.includeClass && (this.isRequired || this.dateValue.length > 0)) {
        inputClass = this.isValidDate() ? 'input is-success' : 'input is-danger'
      } else {
        inputClass = 'input'
      }
      if (this.size) inputClass += ' ' + this.size
      return inputClass
    },
    required() {
      if (this.includeClass && (this.isRequired || this.dateValue.length > 0)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    isValidDate() {
      if (this.dateValue == '' || this.dateValue.length != 10) return false
      let nDate = fecha.parse(this.dateValue, 'MM-DD-YYYY')
      if (nDate != false) {
        this.$emit('update:date', this.dateValue)
        return true
      }
      return true
    }
  }
}
</script>

<style scoped></style>
