<template>
  <section>
    <div class="card">
      <div class="card-content">
        <p class="title">Oops</p>
        <b-message>
          <h3>The {{ resource }} you're looking for is not here.</h3>
          <router-link :to="{ name: 'dashboard' }">Back to the dashboard.</router-link>
        </b-message>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      default: 'page'
    }
  }
}
</script>

<style scoped></style>
