<template>
  <section class="box">
    <div class="columns">
      <div class="column is-5">
        <div v-if="!editing">
          <b-field label="Provider Type" type="is-success">
            <b-select @input="updateSubjectOptions()" v-model="provider.subjectType" name="subject-type" :disabled="editing || batchFile.verified">
              <option v-for="(subjectType, key, index) in providerTypes" :key="index" :value="key">{{ subjectType }}</option>
            </b-select>
          </b-field>
          <b-field label="Input Type" type="is-success">
            <b-select @input="updateSubjectOptions()" v-model="provider.inputOrderType" :disabled="editing || batchFile.verified">
              <option v-for="(inputOrderType, key, index) in td_constant.TYPH_DATA_INPUT_TYPES" :key="index" :value="key">{{ inputOrderType }} Order</option>
            </b-select>
          </b-field>
          <b-field label="Action Type" placeholder="Select an action type" type="provider.actionType ? 'is-success' : 'is-danger'" v-if="provider.inputOrderType == inputTypesL.batch && isMonitoring">
            <b-select @input="updateSubjectOptions()" v-model="provider.batchActionType" :disabled="editing || batchFile.verified">
              <option v-for="(batchActionType, key, index) in td_constant.TYPH_DATA_BATCH_ACTION_TYPE_OPTIONS" :key="index" :value="key" :disabled="batchFile.verified">{{ batchActionType }}</option>
            </b-select>
          </b-field>
          <b-field v-show="showFrequency" label="Frequency" type="is-success">
            <b-select @input="updateProductOptions()" v-model="provider.frequency" :disabled="editing || batchFile.verified">
              <option value="one-time">{{ td_constant.TYPH_DATA_FREQUENCY_ONE_TIME }}</option>
              <option value="monitoring">{{ td_constant.TYPH_DATA_FREQUENCY_RECURRING }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="column is-10">
        <div v-if="!editing">
          <b-field v-if="product.map.licenses && product.map.licenses.length > 0" label="Licensing"></b-field>
          <b-field v-if="product.map.licenses && product.map.licenses.length > 1">
            <b-checkbox v-model="provider.licenseProductId" :size="provider.licenseProductId == product.map.licenses[1].id ? 'is-default' : 'is-small'" :true-value="product.map.licenses[1].id">
              <span :class="{ 'has-text-weight-bold': provider.licenseProductId == product.map.licenses[1].id }">{{ product.map.licenses[1].name }}</span>
            </b-checkbox>
          </b-field>
          <b-field v-if="product.map.licenses && product.map.licenses.length > 0">
            <b-checkbox v-model="provider.licenseProductId" :size="provider.licenseProductId == product.map.licenses[0].id ? 'is-default' : 'is-small'" :true-value="product.map.licenses[0].id">
              <span :class="{ 'has-text-weight-bold': provider.licenseProductId == product.map.licenses[0].id }">{{ product.map.licenses[0].name }}</span>
            </b-checkbox>
          </b-field>
          <b-field v-if="product.map.exclusions && product.map.exclusions.length > 0" label="Products"></b-field>
          <b-field>
            <b-checkbox v-model="provider.verifyResults" name="verify-result" :indeterminate="provider.verifyResults == -1" :size="provider.verifyResults != false ? 'is-default' : 'is-small'">
              <span :class="{ 'has-text-weight-bold': provider.verifyResults == true }">Verify Results</span>
            </b-checkbox>
          </b-field>
          <b-field v-for="exclusionOption in product.map.exclusions" :key="exclusionOption.tier">
            <b-checkbox
              v-model="provider.exclusionProductTier"
              :size="provider.exclusionProductTier == exclusionOption.tier ? 'is-default' : 'is-small'"
              :true-value="exclusionOption.tier"
              :disabled="productDisabled(exclusionOption)"
            >
              <span :class="{ 'has-text-weight-bold': provider.exclusionProductTier == exclusionOption.tier }">{{ exclusionOption.name }}</span>
            </b-checkbox>
          </b-field>
          <b-field v-if="product.map.add_ons && product.map.add_ons.length > 0" label="Add-ons"></b-field>
          <b-field v-for="addOnOption in product.map.add_ons" :key="addOnOption.tier">
            <b-checkbox
              v-model="provider.addOnProductTiers"
              :size="provider.addOnProductTiers.includes(addOnOption.tier) ? 'is-default' : 'is-small'"
              :native-value="addOnOption.tier"
              :disabled="productDisabled(addOnOption)"
            >
              {{ addOnOption.name }}
              <sub style="display:none;font-size:66%">{{ addOnOption.tier }}</sub>
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div class="column is-1">&nbsp;</div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <div v-if="editing || (somethingChecked && provider.inputOrderType != inputTypesL.batch && provider.subjectType == subjectTypesL.individual)">
            <b-field label="First Name" message="Use full-names. For multiple names, separate with spaces. ie 'Clive John'" :type="!provider.firstName || provider.firstName == '' ? 'is-danger' : 'is-success'">
              <TdInput v-model.trim="provider.firstName" name="first-name" :reqMinLength="1" :minlength="1" :reqMaxLength="35" :maxlength="35" :isRequired="true"></TdInput>
            </b-field>
            <b-field label="Middle Name">
              <TdInput v-model.trim="provider.middleName" name="middle-name" :reqMinLength="1" :minlength="1" :reqMaxLength="35" :maxlength="35"></TdInput>
            </b-field>
            <b-field label="Last Name" message="For multiple names, separate with spaces. ie 'Johnson Smith'" :type="!provider.lastName || provider.lastName == '' ? 'is-danger' : 'is-success'">
              <TdInput v-model.trim="provider.lastName" name="last-name" :reqMinLength="1" :minlength="1" :reqMaxLength="35" :maxlength="35" :isRequired="true"></TdInput>
            </b-field>
            <b-field label="Provider Type" >
              <b-select v-model="provider.providerType" >
                <option value = "249">Acupuncturist</option>
                <option value = "194">Addictions Counselor</option>
                <option value = "198">Art Therapist</option>
                <option value = "250">Athletic Trainer</option>
                <option value = "238">Audiologist</option>
                <option value = "180">Certified or Qualified Medication Aide</option>
                <option value = "190">Chiropractic Assistant</option>
                <option value = "189">Chiropractor</option>
                <option value = "175">Clinical Nurse Specialist</option>
                <option value = "192">Counselor, Mental Health</option>
                <option value = "244">Cytotechnologist</option>
                <option value = "200">Dance Therapist</option>
                <option value = "184">Dental Assistant</option>
                <option value = "186">Dental Hygienist</option>
                <option value = "183">Dental Resident</option>
                <option value = "185">Dental Therapist/Dental Health Aide</option>
                <option value = "182">Dentist</option>
                <option value = "187">Denturist</option>
                <option value = "203">Dietitian</option>
                <option value = "206">Emergency Medical Responder</option>
                <option value = "207">Emergency Medical Technician</option>
                <option value = "208">Emergency Medical Technician, Advanced</option>
                <option value = "179">Health Care Aide/Direct Care Worker</option>
                <option value = "259">Health Care Facility Administrator</option>
                <option value = "240">Hearing Aid (or Instrument) Specialist, Dealer, Dispenser or Fitter</option>
                <option value = "178">Home Health Aide (Homemaker) </option>
                <option value = "251">Homeopath</option>
                <option value = "174">Licensed Practical or Vocational Nurse</option>
                <option value = "197">Marriage and Family Therapist</option>
                <option value = "228">Massage Therapist</option>
                <option value = "252">Medical Assistant</option>
                <option value = "242">Medical or Clinical Lab Technician/Technologist</option>
                <option value = "253">Midwife, Lay (Non-Nurse)</option>
                <option value = "201">Music Therapist</option>
                <option value = "254">Naturopath</option>
                <option value = "245">Nuclear Medicine Technologist</option>
                <option value = "177">Nurse Aid/Nursing Assistant</option>
                <option value = "171">Nurse Anesthetist</option>
                <option value = "172">Nurse Midwife</option>
                <option value = "173">Nurse Practitioner</option>
                <option value = "204">Nutritionist</option>
                <option value = "229">Occupational Therapist</option>
                <option value = "230">Occupational Therapy Assistant</option>
                <option value = "211">Ocularist</option>
                <option value = "212">Optician</option>
                <option value = "213">Optometrist</option>
                <option value = "255">Orthotics/Prosthetics Fitter</option>
                <option value = "168">Osteopathic Physician (DO)</option>
                <option value = "169">Osteopathic Physician Resident (DO)</option>
                <option value = "209">Paramedic</option>
                <option value = "196">Pastoral Counselor</option>
                <option value = "256">Perfusionist</option>
                <option value = "215">Pharmacist</option>
                <option value = "217">Pharmacy Assistant</option>
                <option value = "216">Pharmacy Intern</option>
                <option value = "218">Pharmacy Technician</option>
                <option value = "231">Physical Therapist</option>
                <option value = "232">Physical Therapy Assistant</option>
                <option value = "166">Physician (MD)</option>
                <option value = "220">Physician Assistant </option>
                <option value = "167">Physician Resident (MD)</option>
                <option value = "222">Podiatric Assistant</option>
                <option value = "221">Podiatrist</option>
                <option value = "193">Professional Counselor</option>
                <option value = "257">Psychiatric Technician</option>
                <option value = "226">Psychological Assistant, Associate, Examiner</option>
                <option value = "224">Psychologist</option>
                <option value = "246">Radiation Therapy Technologist</option>
                <option value = "247">Radiologic Technician/Technologist</option>
                <option value = "199">Recreation Therapist</option>
                <option value = "170">Registered Nurse</option>
                <option value = "233">Rehabilitation Therapist</option>
                <option value = "234">Respiratory Therapist</option>
                <option value = "235">Respiratory Therapy Technician</option>
                <option value = "225">School Psychologist</option>
                <option value = "195">Sex Offender Counselor</option>
                <option value = "237">Social Worker</option>
                <option value = "239">Speech/Language Pathologist</option>
                <option value = "243">Surgical Technologist/Assistant</option>
                <option value = "260">Other General - Not Classified, Specify</option>
              </b-select>
            </b-field>
            <TdInputDate v-model="provider.birthDate" label="Date of Birth" name="birth-date" :isRequired="isMonitoring || verificationProductChecked" />
            <TdInputSsn v-model="provider.socialSecurityNumber" label="SSN" name="ssn" :isRequired="isMonitoring || verificationProductChecked" />
            <TdInputGroup v-if="isMonitoring" :group.sync="provider.group" name="group" :isRequired="true" />
            
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="somethingChecked && provider.inputOrderType != inputTypesL.batch && provider.subjectType == subjectTypesL.organization">
            <b-field label="Organization Name" :type="!provider.organizationName || provider.organizationName == '' ? 'is-danger' : 'is-success'">
              <TdInput v-model.trim="provider.organizationName" name="org-name" :reqMinLength="2" :minlength="2" :reqMaxLength="100" :maxlength="100" :isRequired="true"></TdInput>
            </b-field>
            <TdInputGroup v-if="isMonitoring" :group.sync="provider.group" name="group" :isRequired="true" />
          </div>
          <div v-if="somethingChecked && provider.inputOrderType == inputTypesL.batch" class="column">
            <h2 class="title is-5">Batch Documents</h2>
            <ul>
              <li>
                <a href="/assets/files/Batch_Guide.pdf">Batch Guide</a>
              </li>
              <li>
                <a href="/assets/files/batch_templates.xlsx">Sample Batch Templates</a>
              </li>
            </ul>
            <br />
            <h2 class="title is-5">Batch Details</h2>
            <b-field label="Batch Name" message :type="!provider.batchName || provider.batchName.length < 5 ? 'is-danger' : 'is-success'">
              <TdInput v-model.trim="provider.batchName" :reqMinLength="5" :minlength="5" :reqMaxLength="100" :maxlength="100" :isRequired="true"></TdInput>
            </b-field>
            <TdInputGroup v-if="isMonitoring" :group.sync="provider.batchGroup" />
            <TdInputBatchFile v-if="readyToUpload" :file.sync="batchFile" :batchProduct="productOptions" :monitoring="isMonitoring" />
            <br />
            <b-field>
              <button type="submit" class="button is-primary" @click="onSubmitBatch()" :disabled="!isReady || sendingToServer">{{ submitLabel }}</button>
            </b-field>
          </div>
        </transition>
      </div>
      <transition name="slide-fade">
        <div v-if="editing || (somethingChecked && provider.inputOrderType != inputTypesL.batch)" class="column">
          <div v-if="provider.subjectType == subjectTypesL.individual">
            <b-field label="Gender">
              <b-select v-model="provider.gender">
                <option v-for="(batchActionType, key, index) in td_constant.TYPH_DATA_GENDERS" :key="index" :value="key">{{ batchActionType }}</option>
              </b-select>
            </b-field>
          </div>
          <b-field label="Reference">
            <!-- :type="!provider.reference || provider.reference == '' ? 'is-danger' : 'is-success'"> -->
            <TdInput v-model="provider.reference" label="Alternate or additional unique identifier."></TdInput>
          </b-field>
          <b-field label="Address Street">
            <TdInput class v-model="provider.addressStreet"></TdInput>
          </b-field>
          <b-field label="Address City">
            <TdInput class v-model="provider.addressCity"></TdInput>
          </b-field>
          <b-field label="Address State">
            <b-select v-model="provider.addressState">
              <option v-for="(jurisdiction, key, index) in td_constant.TYPH_DATA_JURISDICTIONS" :key="index" :value="key">{{ jurisdiction }}</option>
            </b-select>
          </b-field>
          <TdInputZip :zip.sync="provider.addressZip" />
          <b-field label="DEA">
            <TdInput class v-model="provider.dea"></TdInput>
          </b-field>
          <b-field label="NPI">
            <TdInput class v-model="provider.npi"></TdInput>
          </b-field>
        </div>
      </transition>
    </div>
    <transition name="slide-fade">
      <div v-if="editing || (somethingChecked && provider.inputOrderType != inputTypesL.batch)" class="columns">
        <div class="column">
          <LicensesForm v-if="licenseCheckProductChecked || licenseInfoProductChecked" :subjectId="provider.id" :subjectType="provider.subjectType" :monitoring="isMonitoring" />
          <br />
          <b-field>
            <button type="submit" name="submit-subject" class="button is-primary" @click="onSubmitSubject()" :disabled="!isReady || sendingToServer">{{ submitLabel }}</button>
          </b-field>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import fecha from 'fecha'
import LicensesForm from '@/components/bryce/LicensesForm.vue'

export default {
  props: {
    addressStreet: {
      type: String,
      default: null
    },
    addressCity: {
      type: String,
      default: null
    },
    addressState: {
      type: String,
      default: null
    },
    addressZip: {
      type: String,
      default: ''
    },
    birthDate: {
      type: String,
      default: ''
    },
    dea: {
      type: String,
      default: null
    },
    firstName: {
      type: String,
      default: ''
    },
    frequency: {
      type: String,
      default: 'monitoring'
    },
    gender: {
      type: String,
      default: null
    },
    providerType: {
      type: Number,
      default: null
    },
    id: {
      type: Number,
      default: null
    },
    inputOrderType: {
      type: String
    },
    lastName: {
      type: String,
      default: ''
    },
    middleName: {
      type: String,
      default: null
    },
    monitoring: {
      type: Boolean,
      default: null
    },
    npi: {
      type: String,
      default: null
    },
    organizationName: {
      type: String,
      default: ''
    },
    reference: {
      type: String,
      default: null
    },
    showFrequency: {
      type: Boolean,
      default: true
    },
    socialSecurityNumber: {
      type: String,
      default: ''
    },
    subjectType: {
      type: String
    }
  },
  components: {
    LicensesForm
  },
  data() {
    return {
      batchFile: {
        fileId: null,
        hasIssues: false,
        verified: false
      },
      editing: false,
      sendingToServer: false,
      provider: {
        addressStreet: this.addressStreet,
        addressCity: this.addressCity,
        addressState: this.addressState,
        addOnProductTiers: [],
        addressZip: this.addressZip,
        batchActionType: 'REPLACE',
        batchGroup: '',
        batchName: '',
        birthDate: this.birthDate,
        dea: this.dea,
        exclusionProductTier: '',
        frequency: this.$store.state.layout.intuitiveUI ? this.frequency : this.$store.state.layout.frequency,
        gender: null,
        group: '',
        firstName: this.firstName,
        inputOrderType: this.inputOrderType ? this.inputOrderType : this.$store.state.td_constant.TYPH_DATA_INPUT_TYPES_LOOKUP.single,
        lastName: this.lastName,
        licenseProductId: null,
        middleName: this.middleName,
        monitoring: this.monitoring == 'true' ? true : false,
        npi: this.npi,
        organizationName: this.organizationName,
        reference: this.reference,
        socialSecurityNumber: this.socialSecurityNumber,
        providerType: this.providerType,
        verifyResults: -1
      },
      inputTypesL: this.$store.state.td_constant.TYPH_DATA_INPUT_TYPES_LOOKUP,
      subjectTypesL: this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES_LOOKUP
    }
  },
  computed: {
    providerTypes() {
      if (this.$store.getters['layout/isFADV']) return this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES_ORG_ONLY
      else return this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES
    },
    isMonitoring() {
      return this.provider.frequency == 'monitoring'
      // return this.$store.getters['layout/isRecurring']
    },
    productOptions() {
      let productOptions = {
        isIndividual: this.provider.subjectType == this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES_LOOKUP.individual,
        batchName: this.provider.batchName,
        batchActionType: this.provider.batchActionType,
        group: this.provider.batchGroup
      }
      let productOption = this.$store.state.product.map.exclusions.find(eo => eo.tier == this.provider.exclusionProductTier)
      if (productOption) {
        productOptions.tier = productOption.tier
        // if (this.provider.verificationProductId) {
        //   productOptions.verify = true
        // }
      }
      if (this.isMonitoring) {
        productOptions.includeLicenseCheck = this.licenseCheckProductChecked
        productOptions.includeLicenseSearch = this.licenseSearchProductChecked
        productOptions.includeLicenseInfo = this.licenseInfoProductChecked

      } else {
        if (this.provider.licenseProductId) {
          let licenseProduct = this.$store.state.product.map.licenses.find(eo => eo.id == +this.provider.licenseProductId)
          if (licenseProduct.tier == 'LICENSECHECK') {
            productOptions.includeLicenseCheck = true
          } else if (licenseProduct.tier == 'LICENSESEARCH') {
            productOptions.includeLicenseSearch = true
          } else if (licenseProduct.tier == 'LICENSEINFO') {
            productOptions.includeLicenseInfo = true
          }
        }
      }
      return productOptions
    },
    exclusionProductChecked() {
      return this.provider.exclusionProductTier != ''
    },
    licenseSearchProductChecked() {
      return +this.provider.licenseProductId == 34 || +this.provider.licenseProductId == 35
    },
    licenseCheckProductChecked() {
      return +this.provider.licenseProductId == 11 || +this.provider.licenseProductId == 13
    },
    licenseInfoProductChecked() {
      return +this.provider.licenseProductId == 115 || +this.provider.licenseProductId == 116
    },
    verificationProductChecked() {
      return this.provider.verifyResults == true
    },
    somethingChecked() {
      return this.provider.frequency != '' && (this.provider.exclusionProductTier != '' || +this.provider.licenseProductId > 0)
      // return this.provider.exclusionProductTier != '' || +this.provider.licenseProductId > 0
    },
    submitLabel() {
      if (this.editing) return 'Update'
      else if (this.isMonitoring) return 'Enroll'
      else return 'Place Order'
    },
    isReady() {
      let ready = true
      if (this.provider.inputOrderType == this.inputTypesL.batch) {
        if (this.provider.batchName == '' || this.provider.batchName.length < 5) {
          ready = false
        }
        if (!this.batchFile.fileId) {
          ready = false
        }
        if (!this.batchFile.verified) {
          ready = false
        }
        if (this.batchFile.hasIssues) {
          ready = false
        }
      } else {
        if (this.provider.subjectType == this.subjectTypesL.organization) {
          if (this.isMonitoring) {
            if (this.provider.organizationName.length < 2 || this.provider.group.length == 0) {
              ready = false
            }
          } else {
            if (this.provider.organizationName.length < 2) {
              ready = false
            }
          }
        } else {
          if (this.isMonitoring) {
            if (this.provider.firstName == '' || this.provider.lastName == '' || this.provider.birthDate.length != 10 || this.provider.socialSecurityNumber.length != 9 || this.provider.group.length == 0) {
              ready = false
            }
          } else {
            if (this.verificationProductChecked) {
              if (this.provider.firstName == '' || this.provider.lastName == '' || this.provider.birthDate.length != 10 || this.provider.socialSecurityNumber.length != 9) {
                ready = false
              }
            } else if (this.provider.firstName == '' || this.provider.lastName == '' ) {
              ready = false
            }
            // else if (this.provider.providerType.length == 0){
            //   ready = false
            // }
          }
        }
        if (this.provider.addressZip.length > 0 && this.provider.addressZip.length < 5) {
          ready = false
        }
      }
      return ready
    },
    readyToUpload() {
      let ready = true
      if (this.provider.batchName == '' || this.provider.batchName.length < 5) {
        ready = false
      }
      if (this.isMonitoring) {
        if (this.provider.batchGroup == '') {
          ready = false
        }
      }
      return ready
    },
    ...mapState(['td_constant', 'product', 'product_order'])
  },
  mounted() {
    if (this.$store.getters['layout/isFADV']) this.provider.subjectType = this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES_LOOKUP.organization
    else this.provider.subjectType = this.subjectType ? this.subjectType : this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES_LOOKUP.individual
  },
  created() {
    if (this.id) {
      this.editing = true
      // We only edit monitoring
      this.$store.dispatch('subject/fetchSubjectInfo', { subjectId: this.id, monitoring: true }).then(subject => {
        let json = JSON.parse(subject.Json)
        this.provider.id = this.id
        this.provider.addressZip = subject.address_zip || ''
        this.provider.addressCity = subject.address_city || ''
        this.provider.addressState = subject.address_state || ''
        this.provider.addressStreet = subject.address_street || ''
        if (subject.dob) {
          this.provider.birthDate = fecha.parse(subject.dob, 'YYYY-MM-DD')
          this.provider.birthDate = fecha.format(this.provider.birthDate, 'MM-DD-YYYY')
        } else if (json.month_of_birth) {
          this.provider.birthDate = json.month_of_birth.padStart(2, '0') + '-' + json.day_of_birth.padStart(2, '0') + '-' + json.year_of_birth
        }
        this.provider.dea = subject.dea
        if (subject.ssn_last_4) {
          this.provider.socialSecurityNumber = subject.ssn_last_4.padStart(9, '0')
        } else {
          this.provider.socialSecurityNumber = ''
        }
        this.provider.gender = subject.gender
        this.provider.group = subject.group
        this.provider.firstName = subject.first_name
        this.provider.inputOrderType = this.$store.state.td_constant.TYPH_DATA_INPUT_TYPES_LOOKUP.single
        this.provider.lastName = subject.last_name
        this.provider.providerType = subject.providerType
        this.provider.middleName = subject.middle_name
        this.provider.monitoring = true
        this.provider.npi = subject.npi
        // this.provider.organizationName = subject.organizationName
        this.provider.reference = subject.reference
        this.provider.subjectType = this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES_LOOKUP.individual
      })
    }
    this.$store.dispatch('product_order/initProductOrders', {})
    this.$store.dispatch('product_order/pushProductOrder', {})
    this.$store
      .dispatch('product/fetchProducts', {
        inMonitoring: this.isMonitoring
      })
      .then(() => {
        this.setProductMap(true)
      })
  },
  methods: {
    isIndividual() {
      return this.subjectType === this.$store.state.td_constant.TYPH_DATA_SUBJECT_TYPES_LOOKUP
    },
    productDisabled(exclusionOption) {
      if (this.provider.verifyResults == -1) return true
      else if (this.provider.subjectType == this.subjectTypesL.individual) return false
      else if (!exclusionOption.canOrg) return true
      else return false
    },
    setProductMap(init) {
      this.$store
        .dispatch('product/setProductMap', {
          inMonitoring: this.isMonitoring,
          isOrganization: this.provider.subjectType == this.subjectTypesL.organization
        })
        .then(() => {
          if (init) this.provider.inputOrderType = this.$store.state.layout.inputType
        })
    },
    updateProductOptions() {
      if (this.editing) return
      this.updateSubjectOptions()
      this.provider.exclusionProductTier = ''
      this.provider.licenseProductId = null
      this.provider.addOnProductTiers = []
      this.setProductMap(false)
    },
    updateSubjectOptions() {
      if (this.editing) return
      this.provider.addressStreet = ''
      this.provider.addressCity = ''
      this.provider.addressState = ''
      this.provider.addressZip = ''
      this.provider.birthDate = ''
      this.provider.batchName = ''
      this.provider.dea = ''
      this.provider.firstName = ''
      this.provider.gender = null
      this.provider.group = ''
      this.provider.lastName = ''
      this.provider.middleName = ''
      this.provider.npi = ''
      this.provider.organizationName = ''
      this.provider.reference = ''
      this.provider.socialSecurityNumber = ''
      // this.provider.providerType = ''
    },
    onSubmitBatch() {
      let batchProduct = this.productOptions
      batchProduct.fileId = this.batchFile.fileId
      batchProduct.finalize = false
      this.sendingToServer = true
      batchProduct.verify = false
      if (this.verificationProductChecked) {
        batchProduct.verify = true
      }
      this.$store
        .dispatch('batch/uploadBatch', {
          batchProduct: batchProduct,
          monitoring: this.isMonitoring
        })
        .then(batchFileId => {
          this.sendingToServer = false
          this.$router.push({ path: 'batch-view', query: { id: batchFileId, monitoring: this.isMonitoring } })
        })
    },
    onSubmitSubject() {
      // event.preventDefault()
      let returnIds = {}
      if (!this.isReady) {
        return false
      } else {
        this.sendingToServer = true
        if (this.editing) {
          this.$store
            .dispatch('subject/updateSubject', {
              subject: this.provider,
              monitoring: this.isMonitoring
            })
            .then(() => {
              this.$router.push({ path: 'provider-view', query: { id: +this.provider.id, monitoring: this.isMonitoring } })
              this.sendingToServer = false
            })
        } else {
          this.$store
            .dispatch('subject/addSubject', {
              subject: this.provider,
              monitoring: this.isMonitoring
            })
            .then(subjectId => {
              returnIds['subjectId'] = subjectId
              this.provider.id = subjectId
              if (this.exclusionProductChecked) {
                if (this.provider.exclusionProductTier) {
                  let searchProduct = {
                    tier: this.provider.exclusionProductTier,
                    monitoring: this.isMonitoring,
                    subjectId: this.provider.id,
                    verify: false,
                    addOns: this.provider.addOnProductTiers
                  }
                  if (this.verificationProductChecked) {
                    searchProduct.verify = true
                  }
                  this.$store
                    .dispatch('product_order/addSearchOrder', {
                      searchProduct: searchProduct
                    })
                    .then(orderId => {
                      returnIds['searchOrderId'] = orderId
                    })
                }
              }
              let promises = []
              if (this.licenseCheckProductChecked) {
                for (let i in this.$store.state.product_order.product_orders) {
                  promises.push(this.$store.dispatch('product_order/addLicenseOrder', { index: i, subjectId: this.provider.id, monitoring: this.isMonitoring, info_only:false }))
                }
              }
              else if (this.licenseInfoProductChecked){
                for (let i in this.$store.state.product_order.product_orders) {
                  promises.push(this.$store.dispatch('product_order/addLicenseOrder', { index: i, subjectId: this.provider.id, monitoring: this.isMonitoring, info_only:true }))
                }
              }
              Promise.all(promises).finally(() => {
                this.$router.push({ path: 'provider-view', query: { id: +subjectId, monitoring: this.isMonitoring } })
              })
            })
          this.sendingToServer = false
          return false
        }
      }
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 1.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

input {
  border: 1px solid silver;
  border-radius: 4px;
  background: white;
  padding: 5px 10px;
}
.required {
  border-color: red;
}
.dirty {
  border-color: #5a5;
  background: #efe;
}
.dirty:focus {
  outline-color: #8e8;
}
.error {
  border-color: red;
  background: #fdd;
}
.error:focus {
  outline-color: #f99;
}

input[type='radio'] {
  margin-left: -20px;
  margin-right: 10px;
}
.product-selected {
  font-weight: bold;
  font-size: larger;
}
.product-heading {
  font-style: oblique;
}
.error-message {
  color: red;
}
</style>
