<template>
  <div>
    <ProviderResultsSearchBar :byDate="true" :byGroups="true" :byName="true" :byReference="true" :byCountLimit="true" />
    <section class="box">
      <b-tabs type="is-boxed">
        <b-tab-item label="by Provider" icon="format-list-bulleted-type">
          <ProviderRosterList @refresh="onGetResults({})" :showMonitoring="true" :showOnetime="true" :showFilter="true" :overrideIntuitive="true" />
        </b-tab-item>
        <b-tab-item label="by Result" icon="format-list-bulleted-type">
          <ProviderResultsList @refresh="onGetResults({})" :showMonitoring="true" :showOnetime="true" :showFilter="true" :overrideIntuitive="true" />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatDate, formatNumber } from '@/helpers/format_helpers'
import ProviderResultsSearchBar from '@/components/bryce/ProviderResultsSearchBar.vue'
import ProviderResultsList from '@/components/bryce/ProviderResultsList.vue'
import ProviderRosterList from '@/components/bryce/ProviderRosterList.vue'

export default {
  props: {
    showSummary: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  components: {
    ProviderResultsList,
    ProviderResultsSearchBar,
    ProviderRosterList
  },
  created() {
    this.$store.dispatch('user_profile/fetch', {})
  },
  mounted() {
    this.$store.dispatch('provider_result/fetchInit', { options: { fetchAction: 'BothResults', enableLocalFilters: true, days: 1 } })
    // if (this.$store.getters['provider_result/isByBatch']) this.$store.dispatch('provider_result/clearResults')
  },
  computed: {
    ...mapState(['provider_result'])
  },
  methods: {
    fetchResults() {
      let searchOptions = {
        includeMonitoring: true,
        includeOnetime: true
      }
      this.$store.dispatch('provider_result/fetchIt', { options: searchOptions })
    },
    onGetResults() {
      this.fetchResults({})
    }
  }
}
</script>

<style scoped>
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #3498db;
  border-color: #3498db;
}
/* buefy BEGIN */
.button {
  white-space: normal;
  height: auto;
}
/* buefy END */

.btn-primary {
  border-color: #3498db;
}

.btn-primary:hover {
  background-color: #369ade;
  border-color: #3498db;
}

.btn-primary:active {
  background-color: #3498db;
  border-color: #3498db;
}

.btn-primary:visited {
  background-color: #3498db;
  border-color: #3498db;
}
</style>
