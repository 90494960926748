<template>
  <div>
    <section class="section">
      <b-table :striped="true" :data="[licenseOrder]" narrowed :opened-detailed="defaultOpenedOrder" detail-key="id">
        <template slot="empty"></template>
        <template slot-scope="props">
          <b-table-column label="Order Id">{{ props.row.order_id }}</b-table-column>
          <b-table-column label="Product">{{ props.row.product_option.name }}</b-table-column>
          <b-table-column label="Completed">{{ props.row.completed_at | longDate }}</b-table-column>
          <b-table-column label="Verify">{{ props.row.verify | yesNo }}</b-table-column>
        </template>
      </b-table>
    </section>
    <div class="buttons">
      <b-field label="With Checked" v-if="checkedLicenseResults.length">
        <b-select class="" id="actions_license_select" name="actionsLicenseSelect" v-model="selectedLicenseAction" @input="onLicenseWithChecked()" placeholder="With Selected">
          <option value="ack">{{ labelRead }}</option>
          <option value="unack">{{ labelUnRead }}</option>
        </b-select>
      </b-field>
    </div>
    <b-table
      :striped="true"
      :data="licenseResultsFiltered"
      :checked-rows.sync="checkedLicenseResults"
      :checkable="true"
      :current-page.sync="currentPageLicenseResults"
      narrowed
      :paginated="licenseResultsFiltered.length > perPage"
      :pagination-simple="false"
      :per-page="perPage"
      :default-sort-direction="defaultSortDirection"
      default-sort="name"
      :opened-detailed="defaultOpenedLicenseResult"
      :detailed="true"
      detail-key="license_result_id"
    >
      <template slot="empty">
        Pending
      </template>
      <template slot="top-left">
        <div class="buttons">
          <b-field label="With Checked" v-if="checkedLicenseResults.length">
            <b-select class="" id="actions_select" name="actionsSelect" v-model="selectedLicenseAction" @input="onAlertAll()" placeholder="With Selected">
              <option value="ack">{{ labelRead }}</option>
              <option value="unack">{{ labelUnRead }}</option>
            </b-select>
          </b-field>
        </div>
      </template>
      <template slot-scope="props">
        <b-table-column label="Order ID" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.order_id }}</b-table-column>
        <b-table-column label="Results ID" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.license_result_id }}</b-table-column>
        <b-table-column label="Name" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row | fullName }}</b-table-column>
        <b-table-column label="License State" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.license_state }}</b-table-column>
        
        <b-table-column v-if="licenseResults.license_multistate == 0 && (licenseResult.last_check_at || licenseResult.completed_at)" label="Multi-State?" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>NO</b-table-column>
        <b-table-column v-if="licenseResults.license_multistate == 1"label="Multi-State?" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>YES</b-table-column>
        <b-table-column v-if="licenseResults.license_multistate == 2" label="Multi-State?" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>Not Applicable</b-table-column>
        <b-table-column label="License Type" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.license_type }}</b-table-column>
        <b-table-column label="License Number" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.license_number }}</b-table-column>
        <b-table-column label="Found Status" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.found_status }}</b-table-column>
        
        <b-table-column label="Expiration" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <span v-if="props.row.license_status != 'PENDING'">{{ props.row.license_expiration | shortDate }}</span>
        </b-table-column>
        <b-table-column label="Issued" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <span v-if="props.row.license_status != 'PENDING'">{{ props.row.license_issued | shortDate }}</span>
        </b-table-column>
        <b-table-column v-if="licenseResults.license_status != 'PENDING'" label="Status" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.license_status }}</b-table-column>
        <b-table-column v-if="licenseOrder.verifiedResult" label="Verified at" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.verifiedAt | longDate }}</b-table-column>
        <b-table-column v-if="false" label="Verified" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.verified }}</b-table-column>
        <b-table-column field="level" label="Level" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <span :style="{ color: resultColor(props.row) }">{{ props.row | resultWords }}</span>
        </b-table-column>
        <b-table-column field="actions" label="">
          <span class="buttons">
            <b-button v-if="props.row.ack" class="is-default is-rounded" @click="onMarkUnAckLicense(props.row)">{{ labelBtnUnRead }}</b-button>
            <b-button v-if="!props.row.ack" class="is-primary is-rounded" @click="onMarkAckLicense(props.row)">{{ labelBtnRead }}</b-button>
          </span>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <span v-if="props.row.license_status == 'PENDING'">PENDING</span>
        <LicenseResultDetail v-else :isMonitoring="false" :subjectId="+licenseOrder.subject_id" :licenseResultByProp="props.row" :notes="props.row.notes" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { actionResultColor, actionResultLevel } from '@/helpers/provider_helpers'
import LicenseResultDetail from '@/components/bryce/LicenseResultDetail.vue'

export default {
  props: {
    licenseOrder: {
      required: false
    },
    alertLevels: {
      default: () => [],
      required: false
    }
  },
  computed: {
    licenseResultsFiltered() {
      if (this.alertLevels && this.alertLevels.length > 0) return this.licenseResults ? this.licenseResults.filter(result => this.alertLevels.includes(result.verified)) : []
      else return this.licenseResults
    },
    ...mapGetters('auth_user', ['isTDStaff']),
    ...mapGetters('user_profile', ['labelBtnRead', 'labelBtnUnRead', 'labelRead', 'labelUnRead']),
    ...mapState(['user_profile'])
  },
  data() {
    return {
      licenseResults: [],
      checkedLicenseResults: [],
      currentPageLicenseResults: 1,
      defaultOpenedLicenseResult: [],
      defaultOpenedOrder: [],
      defaultSortDirection: 'desc',
      perPage: 20,
      selectedLicenseAction: null
    }
  },
  components: {
    LicenseResultDetail
  },
  created() {
    this.licenseResults = []
    this.$store.dispatch('transactional/fetchLicenseOrders', { orderId: +this.licenseOrder.order_id }).then(licenseResult => {
      licenseResult.verified = actionResultLevel(licenseResult)
      if (licenseResult.verified == 'PENDING' || licenseResult == 'NO_RESULTS_FOUND') licenseResult.ack = true
      else licenseResult.ack = licenseResult.ack_at ? true : false
      this.licenseResults.push(licenseResult)
    })
  },
  methods: {
    onLicenseWithChecked() {
      if (!this.selectedLicenseAction || this.selectedLicenseAction == '') return
      for (let i in this.checkedLicenseResults) {
        let result = this.checkedLicenseResults[i]
        if (this.selectedLicenseAction == 'ack') this.onMarkAckLicense(result)
        else if (this.selectedLicenseAction == 'unack') this.onMarkUnAckLicense(result)
      }
      this.selectedLicenseAction = ''
    },
    onMarkAckLicense(row) {
      if (!row.ack) {
        this.$store.dispatch('action_result/resultMarkAsRead', { licenseResultId: row.license_result_id, subjectId: +this.subjectId, isMonitoring: false }).then(() => {
          row.ack = true
        })
      }
    },
    onMarkUnAckLicense(row) {
      this.$store.dispatch('action_result/resultMarkAsUnRead', { licenseResultId: row.license_result_id, subjectId: +this.subjectId, isMonitoring: false }).then(() => {
        row.ack = false
      })
    },
    resultColor(actionResult) {
      return actionResultColor(actionResult)
    }
  }
}
</script>

<style scoped></style>
