import Vue from 'vue'
import 'babel-polyfill'
// import './plugins/idle-vue'
import './plugins/auto-require'
import './plugins/buefy'
import './plugins/cleave'
import './plugins/nprogress'
import './filters'
import router from './routers/bryce'
import store from './store/bryce'
import axios from 'axios'

Vue.config.productionTip = false

let token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

import Bryce from './Bryce.vue'

new Vue({
  router,
  store,
  render: h => h(Bryce)
}).$mount('#app')

let currentUser = store.getters['auth_user/currentUser']
if (Object.keys(currentUser).length == 0) {
  store.dispatch('auth_user/fetchAuthUser', {})
}

Vue.config.devtools = true
