<template>
  <div>
    <form @submit.prevent="submit">
      <b-field>
        <TdInput label="Username or Email" type="email" :hasIconLeft="true" v-model.trim="credentials.username">
          <span v-if="credentials.username.length > 4" class="icon is-small is-right">
            <b-icon icon="check" />
          </span>
          <span class="icon is-small is-left">
            <b-icon icon="email" />
          </span>
        </TdInput>
      </b-field>
      <TdSubmit label="Send reset link" />
    </form>
    <p>
      <router-link :class="brandName == 'typhoon' ? 'has-text-white' : ''" :to="{ name: 'login' }">Login</router-link>
    </p>
    <b-message v-if="messageDisplay" title="" type="is-warning">
      {{ messageDisplay }}
    </b-message>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      credentials: {
        username: ''
      },
      messageDisplay: ''
    }
  },
  computed: {
    ...mapState('layout', ['brandName'])
  },
  methods: {
    submit() {
      this.$store.dispatch('auth_user/rescuePassword', { username: this.credentials.username }).then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style scoped></style>
