<template>
  <section class="sidebar-layout">
    <b-sidebar v-if="menuPosition == 'both' || menuPosition == 'side'" position="static" fullheight :mobile="mobile" :expand-on-hover="expandOnHover" :reduce="reduce" type="is-light" open>
      <div class="p-1">
        <div class="block" style="padding:0; margin:0">
          <div v-if="brandName != 'typhoon'" class="navbar-brand">
            <figure v-if="brandName == 'fadv'" class="image">
              <img :src="logoImagePath" alt="Brand" style="background-color:black" />
            </figure>
            <figure v-if="brandName == 'iqvia'" class="image">
              <img :src="logoImagePath" alt="Brand" />
            </figure>
            <figure v-if="brandName == 'jdp'" class="image">
              <img :src="logoImagePath" alt="Brand" />
            </figure>
            <figure v-if="brandName == 'sjv'" class="image">
              <img :src="logoImagePath" alt="Brand" />
            </figure>
          </div>
          <div>
            <span class="tag is-warning has-text-white">{{ action_result.alertsCount }} Alerts</span>
          </div>
        </div>
        <b-menu class="is-custom-mobile">
          <b-menu-list label="">
            <b-menu-item icon="home" label="Dashboard" tag="router-link" :to="{ name: 'dashboard' }" :active="menu == 'dashboard'"></b-menu-item>
            <b-menu-item :expanded="true" icon="account" label="Monitoring">
              <b-menu-item icon="infinity" label="Alerts" tag="router-link" :to="{ name: 'monitoring-alerts' }" :active="menu == 'monitoring-alerts'"></b-menu-item>
              <b-menu-item label="Search" icon="account-search" tag="router-link" :to="{ name: 'monitoring-search' }" :active="menu == 'monitoring-search'"></b-menu-item>
              <b-menu-item label="Enrollment" icon="account" tag="router-link" :to="{ name: 'monitoring-form' }" :active="menu == 'monitoring-form'"></b-menu-item>
              <b-menu-item label="Reports" icon="file-chart" tag="router-link" :to="{ name: 'monitoring-reports' }" :active="menu == 'monitoring-reports'"></b-menu-item>
              <b-menu-item label="Batches" icon="folder-upload" tag="router-link" :to="{ name: 'monitoring-batches' }" :active="menu == 'monitoring-batches'"></b-menu-item>
            </b-menu-item>
            <b-menu-item :expanded="true" icon="plus-circle-outline" label="One-time">
              <b-menu-item label="Search" icon="plus-circle-outline" tag="router-link" :to="{ name: 'onetime-form' }" :active="menu == 'onetime-form'"></b-menu-item>
              <b-menu-item label="History" icon="stack-overflow" tag="router-link" :to="{ name: 'onetime-history' }" :active="menu == 'onetime-history'"></b-menu-item>
              <b-menu-item label="Batches" icon="folder-upload" tag="router-link" :to="{ name: 'onetime-batches' }" :active="menu == 'onetime-batches'"></b-menu-item>
            </b-menu-item>
            <b-menu-item label="Providers" icon="account-search" tag="router-link" :to="{ name: 'provider-results' }"> </b-menu-item>
            <b-menu-item label="New" icon="account" tag="router-link" :to="{ name: 'provider-new' }"> </b-menu-item>
            <b-menu-item label="Batches" icon="folder-upload" tag="router-link" :to="{ name: 'batches' }"> </b-menu-item>
            <b-menu-item label="Customers" icon="account-multiple" tag="router-link" :to="{ name: 'customer-search' }" :active="menu == 'customer-search'"></b-menu-item>
          </b-menu-list>
          <b-menu-list label="Actions">
            <b-menu-item icon="chevron-up" tag="a" label="Top Menu" @click="onHideShowMenu()"></b-menu-item>
            <b-menu-item v-if="isProxy" icon="logout" label="Proxy" style="background-color: red;" tag="router-link" :to="{ name: 'proxy-logout' }"></b-menu-item>
            <b-menu-item icon="settings-outline" label="Profile" tag="router-link" :to="{ name: 'settings' }"></b-menu-item>
            <b-menu-item icon="logout" label="Logout" tag="router-link" :to="{ name: 'logout' }"></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
      <div v-if="brandName != 'fadv'" class="block">
        <img src="/assets/images/tblack.png" alt="TyphoonData" />
      </div>
      <!-- <a class="sidebar-btt" style="padding-left:14px" role="button" @click="backToTop()">
        <b-icon icon="arrow-expand-up" size="is-small" />
        <span>To top</span>
      </a> -->
    </b-sidebar>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      isActiveCustomers: true,
      expandOnHover: true,
      mobile: 'reduce',
      reduce: true
    }
  },
  computed: {
    logoImagePath() {
      return '/assets/images/' + this.$store.state.layout.brandName + '/logo_46.png'
    },
    ...mapGetters('auth_user', ['isProxy']),
    ...mapGetters('user_profile', ['menuPosition']),
    ...mapState(['action_result']),
    ...mapState('layout', ['menu', 'brandName', 'intuitiveUI'])
  },
  created() {
    this.$store.dispatch('action_result/fetchAlertsCount')
    // const notif = this.$buefy.notification.open({
    //   // duration: 5000,
    //   message: `Something's not good, also I'm on bottom`,
    //   position: 'is-bottom-left',
    //   type: 'is-danger',
    //   hasIcon: true
    // })
  },
  methods: {
    backToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    onHideShowMenu() {
      this.$store.dispatch('user_profile/setMenuTop')
    }
  }
}
</script>
<style lang="scss">
.p-1 {
  padding: 1em;
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    min-height: 100%;
    // min-height: 100vh;
  }
}
.sidebar-btt {
  position: sticky;
  margin-top: 1.5rem;
  // top: calc(100vh - 3rem);
  font-size: 0.8rem;
  color: black;
  font-weight: bold;
  .icon {
    font-size: 18px;
    margin-right: 0.25rem;
  }
  &:hover {
    color: grey;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
