<template>
  <div>
    <div class="has-text-centered">
      <p class="subtitle has-text-white">Please enter your password below.</p>
    </div>
    <div class="box is-shadowless" :class="brandName">
      <ConfirmForm />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ConfirmForm from '@/components/shared/ConfirmForm.vue'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('layout', ['brandName'])
  },
  components: {
    ConfirmForm
  }
}
</script>

<style scoped>
.hds,
.iqvia,
.sjv {
  background: white;
}
.typhoon {
  background: transparent;
}
</style>
