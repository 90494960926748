<template>
  <div>
    <TdPanel panelId="transactional_view" panelTitle="Customer">
      <table v-if="customer" class="table is-bordered">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{{ customer.id }}</td>
          </tr>
          <tr>
            <th>Reseller ID</th>
            <td>{{ customer.reseller_id }}</td>
          </tr>
          <tr v-if="customer.name">
            <th>Name</th>
            <td>{{ customer.name }}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{{ customer.customer_type }}</td>
          </tr>
          <tr>
            <th>Is Active</th>
            <td>{{ customer.is_active | yesNo }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <UserList :customerId="customerId" />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <CustomerProductList :customerId="customerId" />
            </td>
          </tr>
        </tbody>
      </table>
    </TdPanel>
    <br />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerProductList from '@/components/CustomerProductList.vue'
import UserList from '@/components/UserList.vue'

export default {
  props: {
    customerId: {
      required: true
    },
    customer: {
      required: true
    }
  },
  data() {
    return {
      products: [],
      users: []
    }
  },
  computed: {
    ...mapGetters('auth_user', ['isTDStaff'])
  },
  components: {
    CustomerProductList,
    UserList
  },
  created() {},
  methods: {}
}
</script>

<style scoped></style>
