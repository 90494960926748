import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {
  license_result: null
}

export const mutations = {
  SET_LICENSE_RESULT(state, license_result) {
    state.license_result = license_result
  }
}

export const actions = {
  licenseResultUpdate({}, { licenseResultId, subjectId, notes, monitoring }) {
    BryceService.send({ method: 'licenseResultUpdate', errorMessage: 'updating licenseResult', params: { subject_id: subjectId, license_result_id: licenseResultId, notes: notes } })
  },
  fetchLicenseResult({ commit }, { subjectId, monitoringProductId }) {
    return BryceService.send({ method: 'getMonitoringLicenseResult', errorMessage: 'fetching products', params: { subjectId: subjectId, monitoringProductId: monitoringProductId } }).then(results => {
      if (results) {
        commit('SET_LICENSE_RESULT', results.license_data)
        return results.license_data
      }
    })
  }
}
