import axios from 'axios'
import getEnv from '@/utils/env'

import NProgress from 'nprogress'

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const apiClient = axios.create({
  baseApiUrl: getEnv('VUE_APP_BASE_API_URL'),
  baseURL: getEnv('VUE_APP_BASE_API_URL'),
  withCredentials: false
})

apiClient.interceptors.request.use(config => {
  NProgress.start()
  const token = localStorage.getItem('user-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  return config
})

apiClient.interceptors.response.use(response => {
  NProgress.done()
  if (response.data && response.data.token_id) {
    localStorage.setItem('user-token', response.data.token_id);
  }
  return response
})

export default apiClient
