<template>
  <div>
    <TdPanel panelId="enroll_history" panelTitle="Enroll History" :minivied="minivied">
      <b-table
        :striped="true"
        :data="enrollHistories"
        :current-page.sync="currentPage"
        narrowed
        :paginated="true"
        :pagination-simple="false"
        :per-page="perPage"
        :default-sort-direction="defaultSortDirection"
        default-sort="name"
      >
        <template slot="empty">
          No history
        </template>
        <template slot-scope="props">
          <b-table-column v-if="!hideProviderDetail" field="id" label="ID" sortable numeric> M{{ props.row.monitoring_subject_id }} </b-table-column>
          <b-table-column v-if="!hideProviderDetail" field="first_name" label="Name" sortable>
            {{ props.row.Subject | fullName }}
          </b-table-column>
          <b-table-column field="action" label="Action" sortable>
            {{ props.row.action }}
          </b-table-column>
          <b-table-column field="created_at" label="Date" sortable>
            {{ props.row.created_at | shortNoTz }}
          </b-table-column>
          <b-table-column field="created_at" label="Batch ID" sortable>
            {{ props.row.batch_id }}
          </b-table-column>
          <!-- <b-table-column v-if="false" field="monitoring_disabled_at" label="Disabled Date" sortable>
            {{ props.row.Subject.disabled_at | shortNoTz }}
          </b-table-column> -->
        </template>
      </b-table>
    </TdPanel>
  </div>
</template>

<script>
export default {
  props: {
    hideProviderDetail: {
      default: false,
      required: false
    },
    minivied: {
      default: true,
      required: false
    },
    subjectId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      defaultSortDirection: 'desc',
      enrollHistories: [],
      currentPage: 1,
      perPage: 25
    }
  },
  computed: {},
  components: {},
  created() {},
  mounted() {
    this.$store
      .dispatch('monitoring/fetchSubjectEnrollHistory', {
        searchOptions: { monitoring_subject_id: +this.subjectId, perPage: this.perPage, page: this.currentPage }
      })
      .then(results => {
        this.enrollHistories = []
        for (let i in results) {
          // let provider = {
          //   monitoring_subject_id: results[i].monitoring_subject_id,
          //   first_name: results[i].Subject.first_name,
          //   middle_name: results[i].Subject.middle_name,
          //   middle_name: results[i].Subject.last_name
          // }
          this.enrollHistories.push(results[i])
        }
      })
  }
}
</script>

<style scoped></style>
