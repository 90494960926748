<template>
  <div>
    <TdPanel panelId="transactional_view" :panelTitle="panelLabel()">
      <table v-if="detail" class="table is-bordered">
        <tbody>
          <tr v-if="isMonitoring">
            <th>Actions</th>
            <td>
              <b-button v-if="isMonitoring && !detail.disabled_at" class="is-default is-rounded" tag="router-link" type="is-link" icon-left="account-edit" :to="{ name: 'provider-edit', query: { id: +detail.id } }"
                >Edit
              </b-button>
              <b-button v-if="isMonitoring && !detail.disabled_at" class="is-primary is-rounded" @click="pause()" icon-left="pause">{{ intuitiveUI ? 'Remove' : 'Pause' }}</b-button>
              <b-button v-if="isMonitoring && detail.disabled_at" class="is-success is-rounded" @click="resume()" icon-left="play">{{ intuitiveUI ? 'Enroll' : 'Resume' }}</b-button>
            </td>
          </tr>
          <tr>
            <th>ID</th>
            <td><span v-if="isMonitoring">M</span>{{ detail.id }}</td>
          </tr>
          <tr v-if="isMonitoring">
            <th>Group</th>
            <td>{{ detail.group }}</td>
          </tr>
          <tr v-if="isMonitoring && detail.disabled_at">
            <th>Disabled</th>
            <td>{{ detail.disabled_at | longDate }}</td>
          </tr>
          <tr v-if="detail.organization_name">
            <th>Organization Name</th>
            <td>{{ detail.organization_name }}</td>
          </tr>
          <tr v-if="detail.first_name">
            <th>First Name</th>
            <td>{{ detail.first_name }}</td>
          </tr>
          <tr v-if="detail.last_name">
            <th>Middle Name</th>
            <td>{{ detail.middle_name }}</td>
          </tr>
          <tr v-if="detail.last_name">
            <th>Last Name</th>
            <td>{{ detail.last_name }}</td>
          </tr>
          <tr v-if="detail.last_name">
            <th>Gender</th>
            <td>{{ detail.gender }}</td>
          </tr>
          <tr v-if="detail.customer_id != auth_user.authUser.customer_id">
            <th>Customer ID</th>
            <td>{{ detail.customer_id }}</td>
          </tr>
          <tr>
            <th>Date of Birth</th>
            <td>{{ detail.dob | shortNoTz }}</td>
          </tr>
          <tr>
            <th>Social Security Number</th>
            <td>XXX-XX-{{ detail.ssn_last_4 || detail.ssn_last4 }}</td>
          </tr>
          <tr>
            <th>Reference</th>
            <td>{{ detail.reference }}</td>
          </tr>
          <tr v-if="detail.batch_id">
            <th>Batch ID</th>
            <td>{{ detail.batch_id }}</td>
          </tr>
          <tr>
            <th>
              <span v-if="isMonitoring">Enroll</span>
              <span v-else>Order</span>
              Date
            </th>
            <td>{{ detail.created_at | longDate }}</td>
          </tr>
          <tr v-if="detail.completed_at">
            <th>Completed Date</th>
            <td>{{ detail.completed_at | longDate }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="isMonitoring && detail && detail.id">
        <SubjectEnrollHistory :hideProviderDetail="false" :subjectId="detail.id" />
      </div>
    </TdPanel>
    <slot></slot>
  </div>
</template>

<script>
import fecha from 'fecha'
import SubjectEnrollHistory from '@/components/bryce/SubjectEnrollHistory.vue'
import { ProviderEnrollmentList } from '@/helpers/provider_helpers'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProviderDetail',
  props: {
    isMonitoring: {
      type: Boolean,
      default: false
    },
    subjectId: {
      required: true
    },
    subjectType: {
      default: 'I'
    }
  },
  data() {
    return {
      detail: null
    }
  },
  computed: {
    ...mapGetters('layout', ['intuitiveUI']),
    ...mapState(['auth_user']),
    ...mapGetters('auth_user', ['isTDStaff'])
  },
  components: {
    SubjectEnrollHistory
  },
  created() {
    this.$store.dispatch('subject/fetchSubjectInfo', { subjectId: this.subjectId, monitoring: this.isMonitoring }).then(subject => {
      this.detail = subject
      this.$emit('update:subjectType', subject.subject_type)
      this.detail.reference = ProviderEnrollmentList(this.detail)
      this.detail.reference0 = this.detail.reference
      this.detail.reference1 = this.detail.reference1
      this.detail.reference2 = this.detail.reference2
      this.detail.reference3 = this.detail.reference3
      this.detail.reference4 = this.detail.reference4
      if (this.detail.gender == 'M') this.detail.gender = 'MALE'
      if (this.detail.gender == 'F') this.detail.gender = 'FEMALE'
      if (!this.detail.dob) {
        if (subject.month_of_birth && subject.day_of_birth && subject.year_of_birth) {
          this.detail.dob = fecha.format(fecha.parse(subject.month_of_birth + '-' + subject.day_of_birth + '-' + subject.year_of_birth, 'MM-DD-YYYY'), 'YYYY-MM-DD')
        } else if (subject.Json) {
          let jsonData = JSON.parse(subject.Json)
          if (jsonData.month_of_birth && jsonData.day_of_birth && jsonData.year_of_birth) {
            this.detail.dob = fecha.format(fecha.parse(jsonData.month_of_birth + '-' + jsonData.day_of_birth + '-' + jsonData.year_of_birth, 'MM-DD-YYYY'), 'YYYY-MM-DD')
          }
        }
      }
    })
  },
  methods: {
    resume() {
      this.$store.dispatch('provider_result/resume', { subjectId: +this.detail.id }).then(results => {
        if (results) this.detail.disabled_at = null
      })
    },
    panelLabel() {
      if (this.intuitiveUI) {
        if (this.isMonitoring) {
          return 'Monitored Subject'
        } else {
          return 'Subject'
        }
      } else return 'Provider'
    },
    pause() {
      this.$buefy.dialog.confirm({
        message: 'This will suspend monitoring on this provider. Continue?',
        confirmText: 'Pause',
        onConfirm: () =>
          this.$store.dispatch('provider_result/pause', { subjectId: +this.detail.id }).then(results => {
            if (results) this.detail.disabled_at = new Date()
            // console.log(this.detail.disabled_at)
          })
      })
    }
  }
}
</script>

<style scoped></style>
