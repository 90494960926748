<template>
  <div class>
    <AdverseActionDetail v-if="alertDetail && actionResult" :adverseAction="actionResult" :includeForm="false" :subjectId="subjectId" :isMonitoring="true" />
    <LicenseResultDetail :isMonitoring="true" v-if="alertDetail && licenseResult" :licenseResultByProp="licenseResult" :subjectId="subjectId" :notes="alertDetail.notes" :includeForm="false" />
    <span v-if="alertDetail">
      <b-field label="Notes" :type="alert && alert.notes == notes ? '' : 'is-info'"
        ><span v-if="false">|A1|</span>
        <b-input v-model="alertDetail.notes" :placeholder="notes" maxlength="200" rows="2" type="textarea"></b-input>
      </b-field>
      <button type="submit" class="button is-primary" @click="onSubmitOptions(alertDetail)" :disabled="alertDetail && alertDetail.notes == notes">Update</button>
    </span>
  </div>
</template>

<script>
import AdverseActionDetail from '@/components/bryce/AdverseActionDetail.vue'
import LicenseResultDetail from '@/components/bryce/LicenseResultDetail.vue'

export default {
  props: {
    alert: {
      type: Object,
      default: null
    },
    subjectId: {
      type: Number,
      default: null
    }
  },
  computed: {
    actionResult() {
      return (this.alertDetail && this.alertDetail.search_result) || null
    },
    licenseResult() {
      return (this.alertDetail && this.alertDetail.license_result) || null
    }
  },
  data() {
    return {
      alertDetail: null,
      notes: (this.alert && this.alert.notes) || ''
    }
  },
  components: {
    AdverseActionDetail,
    LicenseResultDetail
  },
  mounted() {
    this.alertDetail = this.alert
  },
  methods: {
    onSubmitOptions(alert) {
      this.$store.dispatch('action_result/alertUpdate', { alertId: alert.id, subjectId: this.subjectId, notes: alert.notes }).then(() => {
        this.notes = alert.notes
      })
    }
  }
}
</script>

<style scoped></style>
