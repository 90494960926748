export const namespaced = true

export const state = {
  brandName: 'typhoon',
  classUrlNode: '',
  frequency: '',
  inputType: '',
  intuitiveUI: true,
  menu: ''
}

export const mutations = {
  SET_BRAND_NAME(state, name) {
    state.brandName = name
  },
  SET_CLASS_NODE(state, urlNode) {
    state.classUrlNode = urlNode
  },
  SET_FREQUENCY(state, frequency) {
    state.frequency = frequency
  },
  SET_INTUITIVE(state, value) {
    state.intuitiveUI = value
  },
  SET_INPUT_TYPE(state, inputType) {
    state.inputType = inputType
  },
  SET_MENU(state, menu) {
    state.menu = menu
  }
}

export const actions = {
  setFrequency({ commit }, { frequency }) {
    commit('SET_FREQUENCY', frequency)
  },
  setIntuitive({ commit }, { value }) {
    commit('SET_INTUITIVE', value)
  },
  setInputType({ commit }, { inputType }) {
    commit('SET_INPUT_TYPE', inputType)
  },
  setBrand({ commit }) {
    let url = window.location.href
    let name = 'typhoon'
    let urlNode = null
    // let name = 'fadv'
    // let name = 'sjv'
    let frequency = 'monitoring'
    let inputType = 'S'
    if (url.indexOf('hds.') > -1) name = 'hds'
    else if (url.indexOf('local') > -1) {
      // name = 'iqvia'
      // frequency = 'one-time'
      // inputType = 'B'
    } else if (url.indexOf('iqvia.') > -1) {
      name = 'iqvia'
      frequency = 'one-time'
      inputType = 'B'
    } else if (url.indexOf('jdp.') > -1) name = 'jdp'
    else if (url.indexOf('sjv.') > -1) name = 'sjv'
    else if (url.indexOf('fadv.') > -1) name = 'fadv'
    
    if (url.indexOf('-dev.') > -1 || url.indexOf('localhost') > -1) urlNode = 'dev'
    else if (url.indexOf('-prep.') > -1) urlNode = 'prep'
    else urlNode = ''
    commit('SET_INPUT_TYPE', inputType)
    commit('SET_FREQUENCY', frequency)
    commit('SET_BRAND_NAME', name)
    commit('SET_CLASS_NODE', urlNode)
  },
  setMenu({ commit }, { menu }) {
    commit('SET_MENU', menu)
  }
}

export const getters = {
  intuitiveUI: state => {
    return state.intuitiveUI
  },
  isIqvia: state => {
    return state.brandName == 'iqvia'
  },
  isFADV: state => {
    return state.brandName == 'fadv'
  },
  isOneTime: (state, getters, rootState) => {
    return state.frequency.toUpperCase() == rootState.td_constant.TYPH_DATA_FREQUENCY_ONE_TIME.toUpperCase()
  },
  isRecurring: (state, getters, rootState) => {
    return state.frequency.toUpperCase() == rootState.td_constant.TYPH_DATA_FREQUENCY_RECURRING.toUpperCase()
  }
}
