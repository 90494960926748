<template>
  <div>
    <b-field grouped>
      <b-field class="file" style="padding-top:0px">
        <b-upload v-model="selectedFile" @input="onFileSelected" accept=".csv" required>
          <a class="button is-primary">
            <b-icon icon="upload"></b-icon>
            <span>Upload file</span>
          </a>
        </b-upload>
        <span class="file-name" v-if="selectedFile">
          {{ selectedFile.name }}
        </span>
        <span class="file-name" v-if="selectedFile"> {{ percentUploaded }}% Uploaded </span>
      </b-field>
    </b-field>
    <div v-if="serverError">
      <div class="text-danger">
        <div>{{ serverError }}. Unable to upload file.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      required: true
    },
    fileValidation: null
  },
  data() {
    return {
      fileToUpload: {
        fileId: null,
        hasIssues: false,
        verified: false
      },
      selectedFile: null,
      serverError: null,
      waitingOnServer: false
    }
  },
  created() {},
  computed: {
    ...mapGetters('file', ['percentUploaded'])
  },
  components: {},
  watch: {
    file(newValue) {
      this.fileToUpload = newValue
    }
  },
  methods: {
    onFileSelected() {
      this.fileToUpload = {
        fileId: null
      }
      this.fileToUploadWarnings = null
      this.emitUpdates()
      // this.onUpload()
    },
    onUpload() {
      const fd = new FormData()
      fd.append('uploadfile', this.selectedFile, this.selectedFile.name)
      this.$store.dispatch('file/' + this.path, { options: { fd: fd } }).then(fileId => {
        // console.log(fileId)
        this.fileToUpload.fileId = fileId
        this.$emit('update:file', this.fileToUpload)
      })
    },
    emitUpdates() {
      this.$emit('update:file', this.fileToUpload)
    }
  }
}
</script>

<style scoped></style>
