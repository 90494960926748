<template>
  <BatchList :getOnetime="true" :getMonitoring="true" />
</template>

<script>
import BatchList from '@/components/bryce/BatchList.vue'

export default {
  computed: {},
  components: {
    BatchList
  },
  created() {}
}
</script>

<style scoped></style>
