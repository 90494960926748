import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {}

export const mutations = {}

export const actions = {
  addSubject({}, { subject, monitoring }) {
    return BryceService.send({ method: 'addSubject', errorMessage: 'adding subject', params: { subject: subject, monitoring: monitoring } }).then(results => {
      if (results) return results.subject_id
    })
  },
  updateSubject({}, { subject, monitoring }) {
    return BryceService.send({ method: 'updateSubject', errorMessage: 'updating subject', params: { subject: subject, monitoring: monitoring } }).then(results => {
      if (results) return subject.id
    })
  },
  fetchSubjectInfo({}, { subjectId, monitoring }) {
    return BryceService.send({ method: 'getSubjectInfo', errorMessage: 'fetching subject', params: { subjectId: subjectId, monitoring: monitoring } }).then(results => {
      if (results) return results.subject_data
    })
  }
}
