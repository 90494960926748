<template>
  <b-field grouped>
    <b-field label="Group" expanded>
      <b-field :type="!groupValue || groupValue == '' ? 'is-danger' : 'is-success'">
        <b-select v-if="!groupIsNew" v-model="groupValue" @input="updateValue">
          <option value="" v-if="includeAll">ALL</option>
          <option v-for="g in groupState.groups" :value="g.name" :key="g.id">{{ g.name }}</option>
        </b-select>
        <b-input v-if="groupIsNew" v-model="groupValue" @input="updateValue" placeholder="Enter new group name" minlength="4" maxlength="75" v-bind="$attrs"></b-input>
        <p class="control" v-if="newButton">
          <button v-if="!groupIsNew" class="button is-primary" @click="toggleIsNew()">New</button>
          <button v-if="groupIsNew" class="button is-primary" @click="toggleIsNew()">Use List</button>
        </p>
      </b-field>
    </b-field>
  </b-field>
</template>

<script>
import { mapState } from 'vuex'

export default {
  inheritAttrs: false,
  props: {
    group: {
      type: String,
      required: true
    },
    newButton: {
      type: Boolean,
      default: true
    },
    includeAll: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      groupIsNew: false,
      groupValue: this.group
    }
  },
  computed: {
    ...mapState({
      groupState: 'group'
    })
  },
  created() {
    this.$store.dispatch('group/fetchGroups', {}).then(() => {
      this.groupValue = this.group
    })
  },
  components: {},
  watch: {
    group(newValue) {
      this.groupValue = newValue
    }
  },
  methods: {
    toggleIsNew() {
      this.groupIsNew = !this.groupIsNew
      if (this.groupIsNew) this.$store.dispatch('group/createNew', {})
    },
    updateValue(value) {
      this.$emit('update:group', value)
      if (this.groupIsNew) this.$store.dispatch('group/updateNew', { name: value })
    }
  }
}
</script>

<style scoped></style>
