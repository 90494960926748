<template>
  <div>
    <ProviderResultsSearchBar :byDate="true" :byId="true" :byName="true" :byUser="true" :includeMonitoring="false" :includeOnetime="true" />
    <section class="box">
      <ProviderRosterList @refresh="onGetResults({})" :showOnetime="true" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProviderRosterList from '@/components/bryce/ProviderRosterList.vue'
import ProviderResultsSearchBar from '@/components/bryce/ProviderResultsSearchBar.vue'

export default {
  components: {
    ProviderRosterList,
    ProviderResultsSearchBar
  },
  created() {
    this.$store.dispatch('user_profile/fetch', {})
  },
  mounted() {
    this.$store.dispatch('provider_result/fetchInit', { options: { fetchAction: 'OnetimeRoster', enableLocalFilters: false, days: 60 } })
  },
  computed: {
    ...mapState(['provider_result'])
  },
  methods: {
    fetchResults() {
      let searchOptions = { includeOnetime: true }
      this.$store.dispatch('provider_result/fetchIt', { options: searchOptions })
    },
    onGetResults() {
      this.fetchResults({})
    }
  }
}
</script>

<style scoped></style>
