import { formatDate } from '@/helpers/format_helpers'
import { getProductOption } from '@/helpers/product_helpers'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

export function actionResultColor(actionResult) {
  let r = actionResultLevelWords(actionResult, null)
  let color = ''
  let by = r[0]
  switch (by) {
    case 'DEFICIENT':
    case 'MISSING_INFO':
    case 'INDETERMINANT':
    case 'ELEVATED':
    case 'REVIEW_REQUIRED':
      color = '#f39c12'
      break
    case 'MATCH':
    case 'SEVERE':
    case 'VERIFIED_IMPAIRMENT':
      color = '#e74c3c'
      break
    default:
      color = ''
  }
  return color
}

export function actionResultLevel(actionResult) {
  let r = actionResultLevelWords(actionResult, null)
  return r[0]
}
export function actionResultWords(actionResult, labels) {
  let r = actionResultLevelWords(actionResult, labels)
  return r[1]
}
export function actionResultLevelWords(actionResult, labels) {
  let level = ''
  let words = ''
  let by = ''
  if (actionResult.detail == 'License expires in 15 days or less!') by = actionResult.level
  else if (actionResult.verified) by = actionResult.verified
  else if (actionResult.license_status) by = actionResult.license_status
  else if (actionResult.search_result_id && actionResult.search_result) by = actionResult.search_result.verified
  else if (actionResult.license_result_id && actionResult.license_result) by = actionResult.license_result.verified
  else if (actionResult.level) by = actionResult.level
  switch (by) {
    //
    // License Checks
    //
    case 'INACTIVE':
    case 'REVOKED':
    case 'SUSPENDED':
    case 'SURRENDERED':
    case 'EXPIRED':
    case 'BOARD PENDING':
    case 'PROBATION':
      level = 'VERIFIED_IMPAIRMENT'
      if (labels != null) words = labels.VerifiedImpairment
      break
    case 'RESTRICTED':
    case 'STIPULATION - YES':
    case 'TEMPORARY':
    case 'NOT FOUND':
      level = 'REVIEW_REQUIRED'
      if (labels != null) words = labels.ReviewRequired
      break
    case 'ACTIVE':
      level = 'NON_ADVERSE'
      if (labels != null) words = labels.NonAdverse
      break
    //
    // Adverse Results
    //
    case 'MATCH':
    case 'VERIFIED_IMPAIRMENT':
      level = 'VERIFIED_IMPAIRMENT'
      if (labels != null) words = labels.VerifiedImpairment
      break
    case 'DEFICIENT':
    case 'INDETERMINANT':
    case 'MISSING INFO':
    case 'UNVERIFIED':
    case 'REVIEW_REQUIRED':
      level = 'REVIEW_REQUIRED'
      if (labels != null) words = labels.ReviewRequired
      break
    case 'INVALID':
    case 'NON_ADVERSE':
      level = 'NON_ADVERSE'
      if (labels != null) words = labels.NonAdverse
      break
    case 'PENDING':
      level = 'PENDING'
      if (labels != null) words = labels.Pending
      break
    //
    // Level
    //
    case 'SEVERE':
      level = 'VERIFIED_IMPAIRMENT'
      if (labels != null) words = labels.VerifiedImpairment
      break
    case 'ELEVATED':
      level = 'REVIEW_REQUIRED'
      if (labels != null) words = labels.ReviewRequired
      break
    case 'LOW':
      level = 'NON_ADVERSE'
      if (labels != null) words = labels.NonAdverse
      break
    default:
      level = 'NO_RESULTS_FOUND'
      if (labels != null) words = labels.NoResultsFound
  }
  return [level, words.toUpperCase()]
}

export function actionLevelWords(level, labels) {
  let r = actionResultLevelWords({ verified: level }, labels)
  return r[1]
}

export function ackUnAckResult(state, options, ackIt) {
  var resultIndex
  if (options.alertId) resultIndex = state.results.findIndex(result => result.alert_id == options.alertId)
  if (options.licenseResultId) resultIndex = state.results.findIndex(result => result.license_result_id == options.licenseResultId)
  if (options.searchResultId) resultIndex = state.results.findIndex(result => result.search_result_id == options.searchResultId)
  if (resultIndex > -1) {
    if (ackIt) state.results[resultIndex].ack_at = new Date()
    else state.results[resultIndex].ack_at = null
    state.results[resultIndex].ack = ackIt
  }
  let found = false
  if (ackIt) found = state.results.find(result => result.subject_id == options.subjectId && !result.ack)
  else found = state.results.find(result => result.subject_id == options.subjectId && result.ack)
  let subjectIndex = state.roster.findIndex(subject => subject.id == options.subjectId && subject.isMonitoring == options.isMonitoring)
  if (subjectIndex > -1) {
    if (found) state.roster[subjectIndex].ack = !ackIt
    else state.roster[subjectIndex].ack = ackIt
  }
}

export function pauseResumeSubject(state, options, pauseIt) {
  let subjectIndex = state.roster.findIndex(subject => subject.id == options.subjectId && subject.isMonitoring == true)
  if (subjectIndex > -1) {
    if (pauseIt) {
      state.roster[subjectIndex].disabled_at = new Date()
      state.roster[subjectIndex].isPaused = true
    } else {
      state.roster[subjectIndex].isPaused = false
      state.roster[subjectIndex].disabled_at = null
    }
  }
}
// We have the following coming down from the server:
// To avoid duplicates...
// Monitoring:
//  1. alerts
//    a. search_result
//    b. license_result
//  2. Subjects (with no alerts only monitoring_product)
// One-time:
//  3. adverse_actions
//    a. search_result
//    b. license_result
//  4. Subjects (with no results only order)
export function resultFirstName(value) {
  if (value.organization_name) return ''
  else if (value.subject_organization_name) return ''
  else if (value.subject_first_name) return value.subject_first_name
  else if (value.first_name) return value.first_name
  else return ''
}
export function resultMiddleName(value) {
  if (value.organization_name) return ''
  else if (value.subject_organization_name) return ''
  else if (value.subject_middle_name) return value.subject_middle_name
  else if (value.middle_name) return value.middle_name
  else return ''
}
export function resultLastName(value) {
  if (value.organization_name) return value.organization_name
  else if (value.subject_organization_name) return value.subject_organization_name
  else if (value.subject_last_name) return value.subject_last_name
  else if (value.last_name) return value.last_name
  else return ''
}

export function appendToResults(state, options) {
  let item = options.item
  let resultIndex = -1
  item.checked = false
  item.detailHidden = true
  item.license_result = null
  item.search_result = null
  item.ack = false
  if (item.ack_at) item.ack = true
  let isMonitoring = false
  if (options.listType == 'alerts') {
    item.order_id = options.item.monitoring_product_id
    if (!item.id) {
      item.id = item.order_id
      item.key = 'MO' + item.id
    } else {
      item.key = 'MA' + item.id
    }
    // item.id === item_alert_id because we use it in ProviderResultList
    item.alert_id = item.id
    // Todo: Is it possible to get a duplicate result? I don't know. This has to be slowing things down.
    resultIndex = state.results.findIndex(result => result.key == item.key)
    item.subject_last_name = item.last_name
    item.subject_first_name = item.first_name
    item.subject_middle_name = item.middle_name
    item.subject_created_at = item.monitoring_subject_created_at
    item.subject_ssn = item.ssn_last_4
    item.subject_dob = item.dob
    state.hasMonitoring = true
    isMonitoring = true
    item.source_name = item.source
    item.subject_id = item.monitoring_subject_id
    item.product_id = options.item.product_id
    // console.log(options.search_result)
    if (options.search_result && options.search_result.record_id) {
      // Used to lookup alert in AdverseActionDetail
      item.search_result_id = item.id
      item.completed_at = options.search_result.created_at
      if (options.search_result.first_name) item.first_name = options.search_result.first_name
      if (options.search_result.last_name) item.last_name = options.search_result.last_name
      item.license_number = options.search_result.license_number
      item.match = options.search_result.match
      item.middle_name = options.search_result.middle_name
      item.provider_type = options.search_result.provider_type
      item.action_code = options.search_result.action_code
      item.action_date = options.search_result.action_text
      item.action_text = options.search_result.action_text
      item.action_type = options.search_result.action_type
      item.original_document_url = options.search_result.original_document_url
      item.record_id = options.search_result.record_id
      item.source_id = options.search_result.source_id
      item.source_jurisdiction = options.search_result.source_jurisdiction
      item.source_name = options.search_result.source_name
      item.source_type = options.search_result.source_type
      item.subject_type = options.search_result.subject_type
      item.tier = options.search_result.tier
      item.verified_at = options.verified_at
      item.verify = options.verify
    } else if (options.license_result && options.license_result.id) {
      // Used to lookup alert in AdverseActionDetail
      item.license_result_id = item.alert_id
      item.completed_at = options.license_result.completed_at
      if (options.license_result.first_name) item.first_name = options.license_result.first_name
      if (options.license_result.last_name) item.last_name = options.license_result.last_name
      item.last_check_at = options.license_result.last_check_at
      item.middle_name = item.subject_middle_name
      item.source_id = options.license_result.source_id
      item.source_name = options.license_result.source_name
      item.verified = options.license_result.license_status
      item.subject_type = options.license_result.subject_type
      if (item.detail == 'License expires in 15 days or less!') item.verified = item.level
      // if (item.alert_id == 7318803) {
      //   console.log(options)
      //   console.log(item)
      //   console.log(item.verified)
      // }
      // if (item.level == 'ELEVATED' && item.detail == 'License expires in 15 days or less!') item.verified = item.level
    }
    if (!item.created_at) {
      item.created_at = item.subject_created_at
    }
  } else if (options.listType == 'adverse_actions') {
    if (item.verified == 'DUPLICATE') return
    if (item.search_result_id) {
      item.id = item.search_result_id
      item.key = '1S' + item.id
    } else if (item.license_result_id) {
      item.id = item.license_result_id
      item.key = '1L' + item.id
    }
    if (!item.id) {
      item.id = item.order_id
      item.key = '1O' + item.id
    }
    // item.id === item_alert_id because we use it in ProviderResultList
    item.alert_id = item.id
    // Todo: Is it possible to get a duplicate result? I don't know. This has to be slowing things down.
    resultIndex = state.results.findIndex(result => result.key == item.key)
    state.hasOneTime = true
    isMonitoring = false
    item.dob = item.subject_dob
    item.organization_name = item.subject_organization_name
    item.first_name = item.subject_first_name
    item.last_name = item.subject_last_name
    item.middle_name = item.subject_middle_name
    item.reference = item.subject_reference
    item.reference1 = item.subject_reference1
    item.reference2 = item.subject_reference2
    item.reference3 = item.subject_reference3
    item.reference4 = item.subject_reference4
    item.subject_ssn = item.subject_ssn_last_4
  }
  if (item.verified_at) item.subject_created_at = item.verified_at
  else if (item.created_at) item.subject_created_at = item.created_at
  else if (item.completed_at) item.subject_created_at = item.completed_at
  // if (!item.id) {
  //   // if (item.subject_id == 11738081)
  //   console.log(options)
  // }
  if (resultIndex > -1) {
    // console.log('duplicate')
  } else if (resultIndex == -1) {
    item.alertLevel = actionResultLevel(item)
    if (item.alertLevel == 'NO_RESULTS_FOUND' || item.alertLevel == 'PENDING') item.ack = true
    let subjectIndex = state.roster.findIndex(subject => subject.id == item.subject_id && subject.isMonitoring == isMonitoring)
    if (subjectIndex == -1) {
      // console.log(subjectIndex)
      let subject = {}
      subject.id = item.subject_id
      subject.customer_id = item.customer_id
      subject.subject_id = item.subject_id
      subject.subject_type = item.subject_type
      subject.ack = true
      subject.disabled_at = false
      subject.checked = false
      subject.detailHidden = true
      subject.isMonitoring = isMonitoring
      subject.isPaused = false
      subject.alertLevels = [item.alertLevel]
      subject.alertLevelCounts = {}
      subject.sources = []
      subject.results = []
      state.roster.push(subject)
      subjectIndex = state.roster.length - 1
      state.roster[subjectIndex].index = subjectIndex
      var compareDate = new Date(item.subject_created_at)
      if (!state.resultsDateRangeBegin || compareDate < state.resultsDateRangeBegin) state.resultsDateRangeBegin = new Date(compareDate)
      if (!state.resultsDateRangeEnd || compareDate > state.resultsDateRangeEnd) state.resultsDateRangeEnd = new Date(compareDate)
      if (!state.listOfCustomerIds.includes(item.customer_id)) {
        state.listOfCustomerIds.push(item.customer_id)
      }
    }
    if (!item.ack) state.roster[subjectIndex].ack = false
    if (item.monitoring_subject_disabled_at) {
      state.roster[subjectIndex].isPaused = true
      state.roster[subjectIndex].disabled_at = item.monitoring_subject_disabled_at
    }
    if (item.group) state.roster[subjectIndex].group = item.group
    state.roster[subjectIndex].reference = ProviderEnrollmentList(item)
    state.roster[subjectIndex].reference0 = item.reference
    state.roster[subjectIndex].reference1 = item.reference1
    state.roster[subjectIndex].reference2 = item.reference2
    state.roster[subjectIndex].reference3 = item.reference3
    state.roster[subjectIndex].reference4 = item.reference4
    if (!item.subject_last_name) item.subject_last_name = item.last_name
    if (!item.subject_first_name) item.subject_first_name = item.first_name
    if (!item.subject_middle_name) item.subject_middle_name = item.middle_name
    if (!item.subject_organization_name) item.subject_organization_name = item.organization_name
    state.roster[subjectIndex].subject_last_name = item.subject_last_name
    state.roster[subjectIndex].subject_first_name = item.subject_first_name
    state.roster[subjectIndex].subject_middle_name = item.subject_middle_name
    state.roster[subjectIndex].subject_organization_name = item.subject_organization_name
    if (item.subject_created_at) state.roster[subjectIndex].created_at = item.subject_created_at
    state.roster[subjectIndex].subject_created_at = item.subject_created_at
    state.roster[subjectIndex].subject_ssn = item.subject_ssn
    if (item.subject_dob) {
      state.roster[subjectIndex].subject_dob = formatDate(item.subject_dob, 'MM-DD-YYYY')
    } else {
      if (item.year_of_birth) item.dob = item.year_of_birth
      if (item.month_of_birth) item.dob += '-' + item.month_of_birth
      if (item.day_of_birth) item.dob += '-' + item.day_of_birth
    }
    if (!state.roster[subjectIndex].created_at) state.roster[subjectIndex].created_at = item.created_at
    if (!state.roster[subjectIndex].completed_at) state.roster[subjectIndex].completed_at = item.completed_at
    if (item.year_of_action) item.action_date = item.year_of_action
    if (item.month_of_action) item.action_date += '-' + item.month_of_action
    if (item.day_of_action) item.action_date += '-' + item.day_of_action
    if (!state.roster[subjectIndex].alertLevels.includes(item.alertLevel)) state.roster[subjectIndex].alertLevels.push(item.alertLevel)
    if (!state.roster[subjectIndex].alertLevelCounts[item.alertLevel]) state.roster[subjectIndex].alertLevelCounts[item.alertLevel] = 0
    state.roster[subjectIndex].alertLevelCounts[item.alertLevel] += 1
    if (!state.roster[subjectIndex].sources.includes(item.source_name)) state.roster[subjectIndex].sources.push(item.source_name)
    var compareDate = new Date(item.created_at)
    if (!state.resultsDateRangeBegin || compareDate < state.resultsDateRangeBegin) state.resultsDateRangeBegin = new Date(compareDate)
    if (!state.resultsDateRangeEnd || compareDate > state.resultsDateRangeEnd) state.resultsDateRangeEnd = new Date(compareDate)
    // console.log(item)
    state.results.push({
      ack: item.ack,
      ack_at: item.ack_at,
      action_code: item.action_code,
      action_date: item.action_date,
      action_text: item.action_text,
      action_type: item.action_type,
      // address_street: item.address_street,
      alertLevel: item.alertLevel,
      alert_id: item.alert_id,
      created_at: item.created_at,
      completed_at: item.completed_at,
      customer_id: item.customer_id,
      dob: item.dob,
      detail: item.detail,
      first_name: item.first_name,
      group: item.group,
      id: item.id,
      isMonitoring: isMonitoring,
      last_name: item.last_name,
      level: item.level,
      license_number: item.license_number,
      license_result_id: item.license_result_id,
      // match: item.match,
      middle_name: item.middle_name,
      npi: item.npi,
      order_id: item.order_id,
      original_document_url: item.original_document_url,
      product_id: item.product_id,
      product_name: getProductOption(options.layout, item.product_id, item.product_name).name,
      provider_type: item.provider_type,
      record_id: item.record_id,
      reference: item.reference,
      reference1: item.reference1,
      reference2: item.reference2,
      reference3: item.reference3,
      reference4: item.reference4,
      search_result_id: item.search_result_id,
      source_jurisdiction: item.source_jurisdiction,
      source_id: item.source_id,
      source_name: item.source_name,
      source_type: item.source_type,
      subject_id: item.subject_id,
      // state_filter: item.state_filter,
      subject_type: item.subject_type,
      verified_category: item.verified_category,
      verified_result: item.verified_result,
      verify: item.verify,
      verified_method: item.verified_method
    })
  }
  return resultIndex == -1
}

export function filterResults(results, options) {
  if (options.alertLevels && options.alertLevels.length == 0) return []
  return results.filter(
    result =>
      (options.name == '' ||
        (result.subject_first_name && result.subject_first_name.toUpperCase().includes(options.name.toUpperCase())) ||
        (result.subject_last_name && result.subject_last_name.toUpperCase().includes(options.name.toUpperCase())) ||
        (result.subject_organization_name && result.subject_organization_name.toUpperCase().includes(options.name.toUpperCase()))) &&
      (options.id == '' || (result.id && result.id.toString().includes(options.id))) &&
      (!options.subjectId || options.subjectId == '' || !result.subject_id || (result.subject_id && result.subject_id.toString().includes(options.subjectId))) &&
      (!options.customerId || options.customerId == '' || !result.customer_id || (result.customer_id && result.customer_id.toString().includes(options.customerId))) &&
      (options.group == null || options.group == '' || (result.group && result.group.includes(options.group))) &&
      (options.reference == '' || (result.reference && result.reference.toUpperCase().includes(options.reference.toUpperCase()))) &&
      options.alertLevels.includes(result.alertLevel) == true &&
      (!(options.ack == true || options.ack == false) || result.ack == options.ack) &&
      (!options.groups || options.groups.length == 0 || options.groups.includes(result.group))
  )
}

export function filterSubjects(subjects, options) {
  if (options.alertLevels && options.alertLevels.length == 0) return []
  return subjects.filter(
    result =>
      (options.name == '' ||
        (result.subject_first_name && result.subject_first_name.toUpperCase().includes(options.name.toUpperCase())) ||
        (result.subject_last_name && result.subject_last_name.toUpperCase().includes(options.name.toUpperCase())) ||
        (result.subject_organization_name && result.subject_organization_name.toUpperCase().includes(options.name.toUpperCase()))) &&
      (!options.subjectId || options.subjectId == '' || (result.subject_id && result.subject_id.toString().includes(options.subjectId))) &&
      (!options.customerId || options.customerId == '' || (result.customer_id && result.customer_id.toString().includes(options.customerId))) &&
      (options.group == null || options.group == '' || (result.group && result.group.includes(options.group))) &&
      (options.reference == '' || (result.reference && result.reference.toUpperCase().includes(options.reference.toUpperCase()))) &&
      (!options.alertLevels || options.alertLevels.length == 0 || result.alertLevels.filter(value => options.alertLevels.includes(value)).length > 0) &&
      (!(options.ack == true || options.ack == false) || result.ack == options.ack) &&
      (!(options.monitored == true) || (result.isMonitoring == true && result.isPaused == false)) &&
      (!(options.paused == true) || (result.isMonitoring == true && result.isPaused == true)) &&
      (!(options.onetime == true) || result.isMonitoring == false) &&
      (!options.groups || options.groups.length == 0 || options.groups.includes(result.group))
  )
}

export function exportResults(fileFormat, alertLevels, filteredRows, providerResults, layout, levels) {
  let resultColumns = {
    completed_at: 'completed',
    product_name: 'product',
    state_filter: 'state_filter',
    source_jurisdiction: 'jurisdiction',
    source_name: 'source_name',
    source_type: 'source_type',
    search_result_id: 'unique_record_id',
    first_name: 'first_name',
    middle_name: 'middle_name',
    last_name: 'last_name',
    dob: 'dob',
    npi: 'npi',
    provider_type: 'provider_type',
    license_number: 'license_number',
    action_type: 'action_type',
    action_date: 'action_date',
    action_text: 'original_record',
    original_document_url: 'original_document',
    // verified_result: 'verification_result',
    alertLevel: 'alertLevel',
    verified_method: 'verification_method'
  }
  let batchResults = []
  for (let sIndex in filteredRows) {
    let item = filteredRows[sIndex]
    let submitted_first_name = resultFirstName(item)
    let submitted_middle_name = resultMiddleName(item)
    let submitted_last_name = resultLastName(item)
    let newSubject = {
      reference: item.reference,
      reference1: item.reference1,
      reference2: item.reference2,
      reference3: item.reference3,
      reference4: item.reference4,
      group: item.group,
      order_id: item.order_id,
      subject_id: item.id,
      submitted_first_name: submitted_first_name,
      submitted_middle_name: submitted_middle_name,
      submitted_last_name: submitted_last_name,
      submitted_ssn: item.subject_ssn,
      submitted_dob: item.subject_dob
    }
    let foundItems = []
    if (providerResults == null) foundItems = [item]
    else foundItems = providerResults.filter(result => result.subject_id == item.id && result.isMonitoring == item.isMonitoring)
    for (let rIndex in foundItems) {
      // console.log(foundItems[rIndex])
      if (alertLevels.includes(foundItems[rIndex].alertLevel)) {
        let newItem = {
          result: 'RESULTS'
        }
        Object.keys(resultColumns).forEach(key => {
          newItem[resultColumns[key]] = ''
          if (foundItems[rIndex][key]) {
            newItem[resultColumns[key]] = ('' + foundItems[rIndex][key]).replace('"', '""').replace('\n', '')
          }
        })
        newItem['alertLevel'] = actionLevelWords(newItem['alertLevel'], levels)
        batchResults.push(Object.assign({}, newSubject, newItem))
      }
    }
    if (foundItems.length == 0) {
      let newItem = {}
      newItem['result'] = 'NO RESULTS'
      Object.keys(resultColumns).forEach(key => {
        newItem[resultColumns[key]] = ''
      })
      newItem['alertLevel'] = actionLevelWords(newItem['alertLevel'], levels)
      newItem['completed'] = item.completed_at
      newItem['product'] = getProductOption(layout, item.product_id, '').name
      batchResults.push(Object.assign({}, newSubject, newItem))
    }
  }
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet('Batch Results');
  worksheet.columns = [
    { header: 'completed', key: 'completed_at' },
    { header: 'product', key: 'product_name' },
    { header: 'state_filter', key: 'state_filter' },
    { header: 'jurisdiction', key: 'source_jurisdiction' },
    { header: 'source_name', key: 'source_name' },
    { header: 'source_type', key: 'source_type' },
    { header: 'unique_record_id', key: 'search_result_id' },
    { header: 'first_name', key: 'first_name' },
    { header: 'middle_name', key: 'middle_name' },
    { header: 'last_name', key: 'last_name' },
    { header: 'dob', key: 'dob' },
    { header: 'npi', key: 'npi' },
    { header: 'provider_type', key: 'provider_type' },
    { header: 'license_number', key: 'license_number' },
    { header: 'action_type', key: 'action_type' },
    { header: 'action_date', key: 'action_date' },
    { header: 'original_record', key: 'action_text' },
    { header: 'original_document', key: 'original_document_url' },
    { header: 'alertLevel', key: 'alertLevel' },
    { header: 'verification_method', key: 'verified_method' }
  ];

  batchResults.forEach(result => {
    let newItem = {};
    Object.keys(result).forEach(key => {
      newItem[resultColumns[key]] = result[key] ? ('' + result[key]).replace('"', '""').replace('\n', '') : '';
    });
    newItem['alertLevel'] = actionLevelWords(newItem['alertLevel'], levels);
    worksheet.addRow(newItem);
  });
  let fileName = 'td_export.';
  switch (fileFormat) {
    case 'csv':
      workbook.csv.writeBuffer()
        .then(buffer => FileSaver.saveAs(new Blob([buffer]), fileName + 'csv'))
        .catch(err => console.log('Error writing csv export', err));
      break;
    case 'xlsx':
      workbook.xlsx.writeBuffer()
        .then(buffer => FileSaver.saveAs(new Blob([buffer]), fileName + 'xlsx'))
        .catch(err => console.log('Error writing xlsx export', err));
      break;
    default:
      workbook.xlsx.writeBuffer()
        .then(buffer => FileSaver.saveAs(new Blob([buffer]), fileName + 'xls'))
        .catch(err => console.log('Error writing xls export', err));
      break;
  }
}

export function ProviderEnrollmentList(item) {
  let references = []
  if (item.reference && item.reference.length > 0) references.push(item.reference)
  if (item.reference1 && item.reference1.length > 0) references.push(item.reference1)
  if (item.reference2 && item.reference2.length > 0) references.push(item.reference2)
  if (item.reference3 && item.reference3.length > 0) references.push(item.reference3)
    if (item.reference4 && item.reference4.length > 0) references.push(item.reference4)
  return references.join(' | ')
}
