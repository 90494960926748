<template>
  <section class="section">
    <ProviderDetail v-if="isMonitoring" :subjectId="id" :isMonitoring="isMonitoring" :subjectType.sync="subjectType">
      <ProductOrderList :subjectId="id" :subjectType="subjectType" />
      <TdPanel panelId="alert_list" :panelTitle="intuitiveUI ? 'Alerts' : 'Results'">
        <ProviderMonitoringResultsList :subjectId="id" />
      </TdPanel>
    </ProviderDetail>
    <ProviderDetail v-else :subjectId="id" :isMonitoring="isMonitoring">
      <ProviderOneTimeResultsList :subjectId="id" />
    </ProviderDetail>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ProviderMonitoringResultsList from '@/components/bryce/ProviderMonitoringResultsList.vue'
import ProviderOneTimeResultsList from '@/components/bryce/ProviderOneTimeResultsList.vue'
import ProductOrderList from '@/components/bryce/ProductOrderList.vue'
import ProviderDetail from '@/components/bryce/ProviderDetail.vue'

export default {
  props: {
    id: {
      required: true
    },
    isMonitoring: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      subjectType: ''
    }
  },
  computed: {
    ...mapGetters('layout', ['intuitiveUI'])
  },
  components: {
    ProductOrderList,
    ProviderMonitoringResultsList,
    ProviderOneTimeResultsList,
    ProviderDetail
  }
}
</script>

<style scoped></style>
