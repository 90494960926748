<template>
  <div :class="classUrlNode">
    <section >
      <NavBar />
      <b-message :icon="announcements.icon" v-for="message in announcements.messages" :key="message.id" :title="message.title" :type="message.type" has-icon aria-close-label="Close message" @close="markAsRead(message.id)">
        {{ message.body }}
      </b-message>

      <div class="sidebar-page">
        <section class="main-content columns is-fullheight">
          <SideBar v-if="false" />
          <section style="padding-left:34px;padding-right:34px">
            <router-view :key="$route.path" />
          </section>
        </section>
        <NotificationContainer />
        <footer :class="['footer', classUrlNode]">
          <div v-if="brandName != 'fadv'" class="content has-text-centered">
            <p>
              <strong>Typhoon Data {{ version }}.</strong>
            </p>
            <h4 class="subtitle is-6">
              Powered by Triton
            </h4>
            <p>
              Copyright &copy; 2016-2020 Typhoon Data. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
import { version } from '@/../package.json'
import { mapState, mapGetters } from 'vuex'
import SideBar from '@/components/bryce/SideBar.vue'
import NavBar from '@/components/bryce/NavBar.vue'
import NotificationContainer from '@/components/shared/NotificationContainer.vue'

export default {
  components: {
    NavBar,
    NotificationContainer,
    SideBar
  },
  data() {
    return {
      showSideBar: true,
      version: version
    }
  },
  computed: {
    ...mapGetters('auth_user', ['currentUser', 'isTDStaff', 'isAuthenticated']),
    ...mapState(['auth_user']),
    ...mapState('layout', ['brandName', 'classUrlNode', 'pageTitle', 'intuitiveUI']),
    ...mapState('layout', []),
    ...mapState('user_profile', ['announcements'])
  },
  created() {},
  methods: {
    logout: function() {
      this.$store.dispatch('auth_user/authLogout', {}).then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    markAsRead(id) {
      this.$store.dispatch('user_profile/markAnnouncementAsRead', { options: { id: id } }).then(() => {
        this.$store.dispatch('user_profile/save', {})
      })
    }
  },
  // onIdle() {
  //   // console.log('idle too long...')
  //   localStorage.setItem('last-route-name', this.$route.path)
  //   localStorage.setItem('last-route-query', JSON.stringify(this.$route.query))
  //   this.logout()
  // }
}
</script>

<style lang="scss">
.dev {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'><text transform='translate(20, 100) rotate(-45)' fill='grey' font-size='20'>dev</text></svg>");
}
.prep {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'><text transform='translate(20, 100) rotate(-45)' fill='grey' font-size='20'>prep</text></svg>");
}
</style>
