<template>
  <div>
    <nav v-if="menuPosition == 'both' || menuPosition == 'top'" class="navbar is-black" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <figure v-if="brandName == 'fadv'" class="image" style="padding-bottom:2px">
          <img :src="logoImagePath" alt="Brand" style="" />
        </figure>
        <figure v-if="brandName == 'iqvia'" class="image">
          <img :src="logoImagePath" alt="Brand" />
        </figure>
        <figure v-if="brandName == 'jdp'" class="image">
          <img :src="logoImagePath" alt="Brand" />
        </figure>
        <figure v-if="brandName == 'sjv'" class="image">
          <img :src="logoImagePath" alt="Brand" />
        </figure>
        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="forceMenu = !forceMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" :class="{ 'is-active': forceMenu }">
        <div class="navbar-start">
          <router-link v-if="intuitiveUI" class="navbar-item is-tab" :class="{ 'is-active': menu == 'dashboard' }" :to="{ name: 'dashboard' }">
            <b-icon icon="home" />
            <span>Dashboard</span>
            <span style="margin-left:4px" class="tag is-warning has-text-white">{{ action_result.alertsCount }}</span>
          </router-link>
          <b-navbar-dropdown v-if="intuitiveUI" label="Monitoring" boxed hoverable close-on-click>
            <b-navbar-item tag="router-link" :to="{ name: 'monitoring-alerts' }" :active="menu == 'monitoring-alerts'">
              <b-icon icon="infinity" />
              <span>Alerts</span>
              <span style="margin-left:4px" class="tag is-warning has-text-white">{{ action_result.alertsCount }}</span>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ name: 'monitoring-search' }" :active="menu == 'monitoring-search'">
              <b-icon icon="account-search" />
              <span>Search</span>
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item v-if="intuitiveUI" tag="router-link" :to="{ name: 'onetime-history' }" :active="menu == 'onetime-history'">
            <b-icon icon="stack-overflow" />
            <span>One-time</span>
          </b-navbar-item>
          <router-link v-if="!isIqvia" class="navbar-item is-tab" :class="{ 'is-active': menu == 'provider-results' }" :to="{ name: 'provider-results' }">
            <b-icon icon="account-search" />
            <span>Providers</span>
          </router-link>
          <router-link class="navbar-item is-tab" :class="{ 'is-active': menu == 'provider-new' }" :to="{ name: 'provider-new' }">
            <b-icon icon="account" />
            <span>New</span>
          </router-link>
          <router-link class="navbar-item is-tab" :class="{ 'is-active': menu == 'batches' }" :to="{ name: 'batches' }">
            <b-icon icon="folder-upload" />
            <span>Batches</span>
          </router-link>
          <router-link v-if="false" class="navbar-item is-tab" :class="{ 'is-active': menu == 'groups' }" :to="{ name: 'groups' }">
            <b-icon icon="account-multiple" />
            <span>Groups</span>
          </router-link>
          <router-link class="navbar-item is-tab" :class="{ 'is-active': menu == 'reports' }" :to="{ name: 'reports' }">
            <b-icon icon="file-chart" />
            <span>Reports</span>
          </router-link>
          <router-link class="navbar-item is-tab" :class="{ 'is-active': menu == 'customer-search' }" :to="{ name: 'customer-search' }">
            <b-icon icon="account-multiple" />
            <span>Customers</span>
          </router-link>
          <b-navbar-item v-if="false && intuitiveUI"  tag="a" @click="onHideShowMenu()">
            <b-icon icon="chevron-left" />
          </b-navbar-item>
          <figure v-if="brandName != 'fadv'" class="image">
            <img src="/assets/images/twhite.png" alt="Powered By TyphoonData" />
          </figure>
        </div>
        <div class="navbar-end">
          <router-link v-if="isProxy" class="navbar-item" :to="{ name: 'proxy-logout' }" style="background-color: red;">
            <b-icon icon="exit-to-app" />
            <span>Proxy Logout</span>
          </router-link>
          <router-link class="navbar-item is-tab" :class="{ 'is-active': menu == 'settings' }" :to="{ name: 'settings' }">
            <b-icon icon="settings-outline" />
            <span>Profile</span>
          </router-link>
          <router-link class="navbar-item" :to="{ name: 'logout' }">
            <b-icon icon="exit-to-app" />
            <span>Exit</span>
          </router-link>
        </div>
      </div>
    </nav>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li v-for="(breadCrumb, idx) in breadCrumbList" :key="idx" :class="{ 'is-active': !breadCrumb.link }">
          <router-link tag="li" :to="breadCrumb.link ? breadCrumb.link : '#'">
            <a aria-current="page">{{ breadCrumb.name }}</a>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      appVersion: 0,
      breadCrumbList: [],
      forceMenu: false,
      navigation: 'home'
    }
  },
  computed: {
    logoImagePath() {
      return '/assets/images/' + this.$store.state.layout.brandName + '/logo_46.png'
    },
    frequency: {
      get() {
        return this.$store.state.layout.frequency
      },
      set(value) {
        this.$store.dispatch('layout/setFrequency', { frequency: value })
      }
    },
    ...mapGetters('auth_user', ['isProxy', 'isTDStaff']),
    ...mapGetters('layout', ['intuitiveUI', 'isIqvia']),
    ...mapGetters('user_profile', ['menuPosition']),
    ...mapState(['action_result']),
    ...mapState(['auth_user']),
    ...mapState('layout', ['menu', 'brandName']),
    ...mapState(['product_order', 'td_constant'])
  },
  created() {
    this.appVersion = require('@/../package.json').name + ' ' + require('@/../package.json').version
    this.$store.dispatch('user_profile/fetch', {})
  },
  mounted() {
    this.updateBreadCrumbs()
  },
  watch: {
    $route() {
      this.updateBreadCrumbs()
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth_user/authLogout', {}).then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    onHideShowMenu() {
      this.$store.dispatch('user_profile/setMenuSide')
    },
    routeTo(pRouteTo) {
      if (this.breadCrumbList[pRouteTo].link) this.$router.push(this.breadCrumbList[pRouteTo].link)
    },
    routeIfNeeded() {
      // if (this.$route.path.indexOf('action-results')) {
      //   this.$router.push({ name: 'providers' })
      // }
    },
    updateBreadCrumbs() {
      if (this.$route.meta) {
        this.breadCrumbList = this.$route.meta.breadCrumbList
      }
    }
  }
}
</script>

<style>
.icon {
  margin-right: 4px;
}
.navbar-link {
  color: white;
}
.navbar-brand {
  padding-right: 40px;
}
.breadcrumb li {
  margin-left: 10px;
}
</style>
