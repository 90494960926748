<template>
  <div>
    <form @submit.prevent="submit">
      <b-field>
        <TdInput label="Username or Email" type="email" :hasIconLeft="true" v-model.trim="credentials.username">
          <span v-if="credentials.username.length > 4" class="icon is-small is-right">
            <b-icon icon="check" />
          </span>
          <span class="icon is-small is-left">
            <b-icon icon="email" />
          </span>
        </TdInput>
      </b-field>
      <b-field>
        <TdInput label="Password" type="password" :hasIconLeft="true" v-model.trim="credentials.password">
          <span class="icon is-small is-left">
            <b-icon icon="lock" />
          </span>
        </TdInput>
      </b-field>
      <TdSubmit label="Login" />
    </form>
    <p>
      <router-link :class="brandName == 'typhoon' ? 'has-text-white' : ''" :to="{ name: 'recover' }">Forgot Password</router-link>
    </p>
    <b-message v-if="false" title="" type="is-warning">
      {{ url }}
    </b-message>
    <b-message v-if="errorMessage" title="" type="is-warning">
      {{ errorMessage }}
    </b-message>
  </div>
</template>

<script>
import getEnv from '@/utils/env'
import { mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      password: '',
      messageDisplay: '',
      url: getEnv('VUE_APP_BASE_API_URL')
    }
  },
  computed: {
    ...mapGetters('auth_user', ['errorMessage']),
    ...mapState('layout', ['brandName'])
  },
  methods: {
    submit() {
      if (this.credentials.username.length > 0 && this.credentials.password.length > 0) {
        this.$store.dispatch('auth_user/authLogin', { username: this.credentials.username, password: this.credentials.password }).then(() => {
          if (this.$store.getters['auth_user/isAuthenticated']) {
            this.$store.dispatch('user_profile/fetch', {})
            let lastName = localStorage.getItem('last-route-name')
            let lastQuery = localStorage.getItem('last-route-query')
            localStorage.removeItem('last-route-name')
            localStorage.removeItem('last-route-query')
            if (lastQuery) lastQuery = JSON.parse(lastQuery)
            if (lastName) lastName = lastName.replace('/', '')
            if (lastName) this.$router.push({ name: lastName, query: lastQuery })
            else this.$router.push({ name: 'dashboard' })
          }
        })
      }
    }
  }
}
</script>

<style scoped></style>
