import BryceService from '@/services/BryceService.js'
import { getProductOption } from '@/helpers/product_helpers'
export const namespaced = true

export const state = {
  items: [],
  map: []
}

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.items = products
  },
  SET_PRODUCT_MAP(state, productMap) {
    state.map = productMap
  }
}

export const actions = {
  fetchProducts({ commit }, {}) {
    return BryceService.send({ method: 'getProducts', errorMessage: 'fetching results', params: {} }).then(results => {
      if (results) commit('SET_PRODUCTS', results.data)
    })
  },
  setProductMap({ commit, state, rootState }, { inMonitoring, isOrganization }) {
    let productMap = {
      exclusions: [],
      licenses: [],
      add_ons: []
    }
    for (var i = 0; i < state.items.length; i++) {
      let includeIt = false
      if (state.items[i].is_enabled) {
        if (inMonitoring) {
          if (state.items[i].is_monitoring) {
            if (isOrganization) {
              // SPECIAL
              if (state.items[i].id == 44) {
                includeIt = true
              } else if (state.items[i].id == 28) {
                includeIt = false
              } else {
                includeIt = true
              }
            } else {
              // if (state.items[i].id == 44) {
              //   includeIt = false;
              // } else if (state.items[i].id == 28) {
              //   includeIt = true;
              // } else {
              includeIt = true
              // }
            }
          }
        } else if (!state.items[i].is_monitoring) {
          if (isOrganization) {
            // STANDARDNOPRESS is OK for organizations
            if (state.items[i].id == 46 || state.items[i].id == 42) {
              includeIt = true
            } else if (state.items[i].id == 26 || state.items[i].id == 28 || state.items[i].id == 29 || state.items[i].id == 30) {
              // STANDARDPLUS is NOT OK for organizations
              includeIt = false
            } else {
              includeIt = true
            }
          } else {
            // STANDARDNOPRESS is NOT OK for individuals
            if (state.items[i].id == 46 || state.items[i].id == 42) {
              includeIt = false
            } else if (state.items[i].id == 26 || state.items[i].id == 28 || state.items[i].id == 29 || state.items[i].id == 30) {
              // STANDARDPLUS is OK for individuals
              includeIt = true
            } else {
              includeIt = true
            }
          }
        }
        // console.log(state.items[i].id, state.items[i].is_enabled, state.items[i].code, includeIt)
      }
      if (includeIt) {
        let prodOption = {}
        prodOption.id = state.items[i].id
        prodOption.tier = state.items[i].tier.toUpperCase().replace(' ', '')
        if (inMonitoring) {
          prodOption.tier_with_verify = prodOption.tier + 'VERIFIED'
        }
        prodOption.info = state.items[i].info
        let option = getProductOption(rootState.layout, prodOption.id, '')
        prodOption.tier = option.tier
        prodOption.name = option.name
        prodOption.canOrg = option.canOrg
        prodOption.category = option.category
        // Skip it if we already have it

        if (prodOption.id >= 56 && prodOption.id <= 63) {
          // console.log(prodOption)
          let fOpt = productMap.add_ons.find(row => row.tier == prodOption.tier)
          if (!fOpt) productMap.add_ons.push(prodOption)
        } else if (!prodOption.name || prodOption.id == 34 || prodOption.id == 35 || prodOption.id == 55) {
          // Ignore LICENSESEARCH and LICENSELOOKUP
          // SKIP
        } else if (prodOption.id == 11 || prodOption.id == 13 || prodOption.id == 115 ||  prodOption.id == 116) {
          let fOpt = productMap.licenses.find(row => row.tier == prodOption.tier)
          if (!fOpt) productMap.licenses.push(prodOption)
        } else {
          // if (prodOption.id > 30) {
          //   prodOption.name += prodOption.id
          // }
          let fOpt = productMap.exclusions.find(row => row.tier == prodOption.tier)
          if (!fOpt) productMap.exclusions.push(prodOption)
        }
      }
    }
    commit('SET_PRODUCT_MAP', productMap)
  }
}
