<template>
  <b-field :label="label" :type="fieldType">
    <div class="control has-icons-right is-clearfix" style="margin:0px;padding:0px">
      <cleave v-model="ssnValue" placeholder="nnn-nn-nnnn" :class="inputClass" :options="optionsSsn" v-bind="$attrs"></cleave>
      <span v-if="required">
        <span v-if="isValidSsn()" class="icon is-right has-text-success"><i class="mdi mdi-check mdi-24px"></i></span>
        <span v-else class="icon is-right has-text-danger"><i class="mdi mdi-alert-circle mdi-24px"></i></span>
      </span>
    </div>
  </b-field>
</template>

<script>
import Cleave from 'vue-cleave-component'

export default {
  inheritAttrs: false,
  props: {
    isRequired: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    includeClass: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      optionsSsn: {
        delimiters: ['-', '-'],
        numericOnly: true,
        uppercase: true,
        blocks: [3, 2, 4]
      }
    }
  },
  components: {
    Cleave
  },
  computed: {
    ssnValue: {
      get() {
        return this.value
      },
      set(ssn) {
        this.$emit('input', ssn)
      }
    },
    fieldType() {
      if (this.includeClass && (this.isRequired || this.ssnValue.length > 0)) {
        return this.isValidSsn() ? 'is-success' : 'is-danger'
      } else {
        return ''
      }
    },
    inputClass() {
      let inputClass = ''
      if (this.includeClass && (this.isRequired || this.ssnValue.length > 0)) {
        inputClass = this.isValidSsn() ? 'input is-success' : 'input is-danger'
      } else {
        inputClass = 'input'
      }
      if (this.size) inputClass += ' ' + this.size
      return inputClass
    },
    required() {
      if (this.includeClass && (this.isRequired || this.ssnValue.length > 0)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    isValidSsn() {
      let ssn = this.ssnValue
      if (ssn == '' || ssn.length != 9) {
        return false
      }
      const ssnPattern = /([0-9]{3})([0-9]{2})([0-9]{4})/
      ssn = ssn.replace(/[^0-9]+/g, '')
      // console.log("ssn:" + JSON.stringify(ssn, null, 2));
      let result = ssnPattern.test(ssn)
      // console.log('result:' + JSON.stringify(result, null, 2))
      if (result && ssn.length == 9) {
        let ssnNum012 = parseInt(ssn.substring(0, 3), 10)
        // console.log('ssnNum012:' + JSON.stringify(ssnNum012, null, 2))
        let ssnNum = parseInt(ssn, 10)
        // console.log('ssnNum:' + JSON.stringify(ssnNum, null, 2))
        if ((ssnNum < 987654320 || ssnNum > 987654329) && ssnNum != 78051120 && ssnNum012 != 666 && ssnNum012 < 900) {
          this.$emit('update:ssn', ssn)
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped></style>
