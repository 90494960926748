import CoreService from '@/services/shared/CoreService.js'

export const namespaced = true

export const state = {
  users: []
}

export const mutations = {
  SET_USERS(state, users) {
    state.users = users
  }
}

export const actions = {
  fetchUsers({ commit, state }) {
    if (state.users.length == 0) {
      CoreService.send({ method: 'getUsers', errorMessage: 'getting users', params: {} }).then(results => {
        if (results) {
          commit('SET_USERS', results.data)
        }
      })
    }
  }
}
