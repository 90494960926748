import fecha from 'fecha'

export function formatDate(value, format) {
  var v
  if (!value || value === null) return ''
  else if (value instanceof Date) v = value
  else v = fecha.parse(value, 'YYYY-MM-DD THH:mm:ssZ')
  return value ? fecha.format(v, format) : ''
}

export function formatNumber(num) {
  if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else return 0
}
