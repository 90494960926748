<template>
  <div>
    <BatchList :getOnetime="false" :getMonitoring="true" />
  </div>
</template>

<script>
import BatchList from '@/components/bryce/BatchList.vue'

export default {
  computed: {},
  components: {
    BatchList
  },
  created() {
    this.$store.dispatch('batch/clearList')
  }
}
</script>

<style scoped></style>
