<template>
  <div>
    <section v-if="userProfile" class="box">
      <table class="table table-bordered">
        <tr>
          <th>Username:</th>
          <td>{{ auth_user.authUser.username }}</td>
        </tr>
        <tr>
          <th>Customer ID:</th>
          <td>{{ auth_user.authUser.customer_id }}</td>
        </tr>
        <tr>
          <th>Documentation:</th>
          <td> <a  href="/assets/files/Portal_User_Guide.pdf">User Guide</a></td>
        </tr>
      </table>

      <b-field grouped>
        <b-field label="Digest Frequency" type="is-success">
          <b-select v-model="userProfile.digest_type">
            <option v-for="(digest_type, key, index) in td_constant.TYPH_DATA_DIGEST_TYPES" :key="index" :value="key">{{ digest_type }}</option>
          </b-select>
        </b-field>
        <b-field label="Digest Level" type="is-success">
          <b-select v-model="userProfile.digest_level">
            <option value="LOW">ALL</option>
            <option value="ELEVATED">{{ user_profile.labels.ReviewRequired.toUpperCase() }}</option>
            <option value="SEVERE">{{ user_profile.labels.VerifiedImpairment.toUpperCase() }}</option>
          </b-select>
        </b-field>
        <b-field v-if="true" label="Notification Level" type="is-success">
          <b-select v-model="userProfile.notify_level">
            <option v-for="(notification_level, key, index) in td_constant.TYPH_DATA_NOTIFICATION_LEVELS" :key="index" :value="key">{{ notification_level }}</option>
          </b-select>
        </b-field>
        <b-field label="Menu" type="is-success">
          <b-select v-model="menuSettings.position">
            <option value="top">Top</option>
            <option value="side">Side</option>
            <option value="both">Both</option>
          </b-select>
        </b-field>
        <b-field label=" " expanded>
          <button type="submit" class="button is-primary" @click="onSubmit()" :disabled="sendingToServer">Update</button>
        </b-field>
      </b-field>
    </section>
    <div v-if="false" class="columns">
      <div class="column">
        <TdPanel panelId="order_log_list" panelTitle="30 Day - Order Activity">
          <order-log-list />
        </TdPanel>
      </div>
      <div class="column">
        <TdPanel panelId="user_log_list" panelTitle="Recent Account History">
          <p style="color: red;margin-left:10px">Keeping your account secure is essential! If you see suspicious activity on your account, please contact our customer service team immediately.</p>
          <user-log-list />
        </TdPanel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import OrderLogList from '@/components/bryce/OrderLogList.vue'
import UserLogList from '@/components/bryce/UserLogList.vue'

export default {
  data() {
    return {
      sendingToServer: false
    }
  },
  components: {
    OrderLogList,
    UserLogList
  },
  computed: {
    ...mapState(['auth_user', 'user_profile', 'td_constant']),
    ...mapState('user_profile', ['userProfile', 'menuSettings'])
  },
  created() {
    this.$store.dispatch('user_profile/fetch', {})
  },
  methods: {
    onSubmit() {
      this.sendingToServer = true
      this.$store.dispatch('user_profile/save', {}).then(() => {
        this.sendingToServer = false
      })
    }
  }
}
</script>

<style></style>
