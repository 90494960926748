<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {},
  components: {},
  created() {
    this.$store.dispatch('layout/setBrand', {}, { root: true })
    // colors.setBrand('primary', '#007D62')
  },
  metaInfo() {
    let headerOptions = {
      title: 'Typhoon Data',
      link: [
        {
          rel: 'icon',
          href: '/assets/images/' + this.$store.state.layout.brandName + '/favicon.ico'
        },
        {
          rel: 'stylesheet',
          href: '/assets/css/' + this.$store.state.layout.brandName + '/styles.css'
        }
      ]
    }
    headerOptions.link.push({
      href: '//cdn.materialdesignicons.com/2.5.94/css/materialdesignicons.min.css',
      rel: 'stylesheet',
      type: 'text/css'
    })
    return headerOptions
  }
}
</script>

<style lang="scss">
$input-placeholder-color: black;
@import '~bulma/sass/utilities/_all';
@import 'assets/main.scss';
</style>
