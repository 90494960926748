<template>
  <div>
    <ProviderResultsSearchBar :byDate="true" :byGroups="true" :includeMonitoring="true" :includeOnetime="false" />
    <section class="box">
      <ProviderResultsList @refresh="onGetResults({})" :showMonitoring="true" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProviderResultsList from '@/components/bryce/ProviderResultsList.vue'
import ProviderResultsSearchBar from '@/components/bryce/ProviderResultsSearchBar.vue'

export default {
  components: {
    ProviderResultsList,
    ProviderResultsSearchBar
  },
  creatted() {
    this.$store.dispatch('user_profile/fetch', {})
  },
  mounted() {
    this.$store.dispatch('provider_result/fetchInit', { options: { fetchAction: 'MonitoringAlerts', enableLocalFilters: true, days: 60 } }).then(() => {
      this.fetchResults()
    })
  },
  computed: {
    ...mapState(['provider_result'])
  },
  methods: {
    fetchResults() {
      let searchOptions = {
        includeMonitoring: true,
        includeOnetime: false
      }
      this.$store.dispatch('provider_result/fetchIt', { options: searchOptions }).then(() => {})
    },
    onGetResults() {
      this.fetchResults({})
    }
  }
}
</script>

<style scoped></style>
