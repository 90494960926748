<template>
  <b-collapse class="card" :aria-id="panelId" :open="!isMinivied" style="margin-bottom:10px">
    <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="contentIdForA11y3">
      <p class="card-header-title">
        {{ panelTitle }}
        <span v-if="panelSubTitle">: {{ panelSubTitle }}</span>
      </p>
      <a class="card-header-icon">
        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
      </a>
    </div>
    <div class="card-content">
      <div class="content">
        <slot />
      </div>
    </div>
  </b-collapse>
</template>

<script>
export default {
  props: {
    minivied: {
      type: Boolean,
      default: false
    },
    panelId: {
      type: String,
      required: true
    },
    panelTitle: {
      type: String,
      required: true
    },
    panelSubTitle: {
      type: String
    }
  },
  data() {
    return {
      isMinivied: this.minivied
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.theader {
  font-weight: bold;
}
.box {
  padding: 0.5em;
}
.card-content {
  padding: 0.75em;
}
</style>
