<template>
  <section>
    <b-field grouped group-multiline>
      <b-field label="" v-if="checkedResults.length">
        <b-select name="actionsSelect" v-model="withAction" @input="onWithChecked()" placeholder="With Selected">
          <option value="ack">{{ labelRead }}</option>
          <option value="unack">{{ labelUnRead }}</option>
        </b-select>
      </b-field>
    </b-field>
    <b-table
      :striped="true"
      :data="resultsListFiltered"
      :checked-rows.sync="checkedResults"
      :checkable="true"
      :current-page.sync="currentPage"
      narrowed
      :paginated="resultsListFiltered.length > perPage"
      :pagination-simple="false"
      :per-page="perPage"
      :default-sort-direction="defaultSortDirection"
      default-sort="id"
      :detailed="true"
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
    >
      <template slot="empty">
        No alerts
      </template>
      <template slot-scope="props">
        <b-table-column field="id" label="Alert ID" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable numeric>
          {{ props.row.id }}
          <div v-if="false && isTDStaff">Alert ID: {{ props.row.id }}</div>
          <div v-if="false && isTDStaff">Product ID: {{ props.row.monitoring_product_id }}</div>
          <div v-if="isTDStaff">Record ID: {{ props.row.record_id }}</div>
        </b-table-column>
        <b-table-column field="created_at" label="Date" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          {{ props.row.created_at | shortDate }}
        </b-table-column>
        <b-table-column v-if="false" field="group" label="Group" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          {{ props.row.group }}
        </b-table-column>
        <b-table-column v-if="false" field="reference" label="Reference" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          {{ props.row.reference }}
          <router-link :to="{ name: 'provider-view', query: { id: props.row.subject_id } }">{{ props.row | fullName }}</router-link>
        </b-table-column>
        <b-table-column field="source" label="Source" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          {{ props.row.source }}
        </b-table-column>
        <b-table-column v-if="false" label="Verified" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          {{ props.row.oldVerified }}
        </b-table-column>
        <b-table-column field="level" label="Level" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <span :style="{ color: alertColor(props.row) }">{{ props.row | resultWords(user_profile.labels) }}</span>
        </b-table-column>
        <b-table-column field="detail" label="Short Description" :class="{ 'has-text-weight-bold': !props.row.ack }">
          {{ props.row.detail }}
        </b-table-column>
        <b-table-column field="ack_at" label="Ack Date" :class="{ 'has-text-weight-bold': !props.row.ack }">
          {{ props.row.ack_at | shortDate }}
        </b-table-column>
        <b-table-column field="actions" label="">
          <span class="buttons">
            <b-button v-if="props.row.ack" class="is-default is-rounded" @click="onMarkAsUnAck(props.row)">{{ labelBtnUnRead }}</b-button>
            <b-button v-if="!props.row.ack" class="is-primary is-rounded" @click="onMarkAsAck(props.row)">{{ labelBtnRead }}</b-button>
          </span>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <MonitoringAlertDetail :alert="props.row" :subjectId="props.row.subject_id" />
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { actionResultColor, actionResultLevel } from '@/helpers/provider_helpers'
import MonitoringAlertDetail from '@/components/bryce/MonitoringAlertDetail.vue'
import BryceService from '@/services/BryceService.js'

export default {
  name: 'ProviderMonitoringResultsList',
  props: {
    subjectId: {
      required: false
    },
    alertLevels: {
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      alertList: [],

      withAction: null,
      checkedResults: [],
      defaultOpenedDetails: [],
      defaultSortDirection: 'desc',
      currentPage: 1,
      perPage: 20
    }
  },
  components: {
    MonitoringAlertDetail
  },
  created() {
    if (this.subjectId) {
      this.fetchActionResults({
        subject_id: +this.subjectId,
        include_low: true
      })
    }
  },
  computed: {
    resultsListFiltered() {
      if (this.alertLevels && this.alertLevels.length > 0) return this.alertList ? this.alertList.filter(result => this.alertLevels.includes(result.verified)) : []
      else return this.alertList
    },
    fullname(alert) {
      return alert.first_name
    },
    ...mapState(['alert', 'user_profile']),
    ...mapGetters('user_profile', ['labelBtnRead', 'labelBtnUnRead', 'labelRead', 'labelUnRead']),
    ...mapGetters('auth_user', ['isTDStaff'])
  },
  methods: {
    alertColor(alert) {
      return actionResultColor(alert)
    },
    fetchActionResults(options) {
      this.alertList = []
      BryceService.send({
        method: 'getMonitoringAlerts',
        errorMessage: 'fetching alerts',
        params: options
      }).then(results => {
        // console.log(results)
        if (results && results.data) {
          for (let i in results.data) {
            if (results.data[i].license_result) {
              results.data[i].verified = results.data[i].license_result.license_status
            }
            results.data[i].oldVerified = results.data[i].verified
            results.data[i].verified = actionResultLevel(results.data[i])
            if (results.data[i].verified == 'NO_RESULTS_FOUND' || results.data[i].verified == 'PENDING') results.data[i].ack = true
            else results.data[i].ack = results.data[i].ack_at ? true : false
            this.alertList.push(results.data[i])
          }
        }
      })
    },
    onWithChecked() {
      if (!this.withAction || this.withAction == '') return
      for (let i in this.checkedResults) {
        let alert = this.checkedResults[i]
        if (this.withAction == 'ack') this.onMarkAsAck(alert)
        else if (this.withAction == 'unack') this.onMarkAsUnAck(alert)
      }
      this.withAction = ''
    },
    onMarkAsAck(alert) {
      if (!alert.ack) {
        this.$store.dispatch('action_result/resultMarkAsRead', { alertId: alert.id, subjectId: alert.subject_id, isMonitoring: true }).then(() => {
          alert.ack = true
        })
      }
    },
    onMarkAsUnAck(alert) {
      this.$store.dispatch('action_result/resultMarkAsUnRead', { alertId: alert.id, subjectId: alert.subject_id, isMonitoring: true }).then(() => {
        alert.ack = false
      })
    },
    selectAll(allSelected) {
      for (let i in this.alertList) {
        this.alertList[i].checked = allSelected
      }
    }
  }
}
</script>

<style scoped>
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #3498db;
  border-color: #3498db;
}
</style>
