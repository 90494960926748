<template>
  <b-table
    :striped="true"
    :data="order_log.orderLogs"
    :columns="columns"
    :paginated="true"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="false"
    :default-sort-direction="defaultSortDirection"
    default-sort="name"
  >
    <template slot="empty">
      No activity
    </template>
  </b-table>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 25,
      columns: [
        {
          field: 'name',
          label: 'Items',
          sortable: true
        },
        {
          field: 'count',
          label: 'Count',
          sortable: true
        }
      ]
    }
  },
  created() {
    this.perPage = 3
    this.$store.dispatch('order_log/fetchOrderLogs', {
      perPage: this.perPage,
      page: this.page
    })
  },
  computed: {
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    hasNextPage() {
      return this.order_log.orderLogsTotal > this.page * this.perPage
    },
    ...mapState(['order_log'])
  },
  components: {}
}
</script>

<style></style>
