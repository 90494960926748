<template>
  <section class="section">
    <CustomerDetail :customerId="id" :customer="customer" />
  </section>
</template>

<script>
import CustomerDetail from '@/components/CustomerDetail.vue'

export default {
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      customer: {}
    }
  },
  computed: {},
  components: {
    CustomerDetail
  },
  created() {
    this.$store.dispatch('customer/fetchCustomer', { customer_id: this.id }).then(customer => {
      this.customer = customer
    })
  },
  methods: {}
}
</script>

<style scoped></style>
