<template>

 <div>
   <div>
          <ul :style = "{'color':'red'}">
              <li :style="passwordLen && credentials.password != '' ? { 'color': 'white'} : ''">Passwords must be at least 12 characters long</li>
              <li :style="passwordLower && credentials.password != '' ? { 'color': 'white'}:  ''">Contain a lowercase letter</li>
              <li :style="passwordUpper && credentials.password != '' ? { 'color': 'white'}:  ''">Contain a uppercase letter </li>
              <li :style="passwordNumber && credentials.password != '' ? { 'color': 'white'}:  ''">Contain a number</li>
              <li :style="passwordSymbol && credentials.password != '' ? { 'color': 'white'}:  ''">Contain a special character: !@#$%^&*()+_.,|~ </li>
              <li :style="{ 'color': 'yellow'}">You can not use your last 10 previous passwords </li>
              <li :style="{ 'color': 'yellow'}">Your password will expire in 180 days. </li>
          </ul> 
      </div>
      <div class ="spcae"></div>
    <form @submit.prevent="submit">
      <b-field :type="inputPasswordClass()" message="">
        <b-input type="password" v-model="credentials.password" placeholder="Password" password-reveal> </b-input>
      </b-field>
      
      <b-field :type="inputPasswordConfirmationClass()" message="">
        <b-input type="password" v-model="credentials.passwordConfirmation" placeholder="Password confirmation" password-reveal> </b-input>
      </b-field>
      <b-message v-if="confirmationError" title="" type="is-danger">
        {{ confirmationError }}
      </b-message>
      <button type="submit" :class="brandName" class="button is-block is-medium is-fullwidth is-primary" :disabled="confirmationError !== '' || passwordError !== '' ||  credentials.password === '' || credentials.passwordConfirmation == '' "  label="Change Password"> Change Password </button>
    </form>
    <p>
      <router-link :class="brandName == 'typhoon' ? 'has-text-white' : ''" :to="{ name: 'login' }">Login</router-link> |
      <router-link :class="brandName == 'typhoon' ? 'has-text-white' : ''" :to="{ name: 'recover' }">Forgot Password</router-link>
    </p>
    <b-message v-if="messageDisplay" title="" type="is-warning">
      {{ messageDisplay }}
    </b-message>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {},
  data() {
    return {
      credentials: {
        password: '',
        passwordConfirmation: ''
      },
      passwordError: '',
      confirmationError: '',
      passwordResetToken: '',
      messageDisplay: '',
      passwordLen: false,
      passwordLower: false,
      passwordUpper: false,
      passwordSymbol: false,
      passwordNumber: false,
    }
  },
  computed: {
    ...mapState('layout', ['brandName'])
  },
  created() {
    this.passwordResetToken = this.$route.query['password_reset_token']
  },
  methods: {
    submit() {
      this.$store
        .dispatch('auth_user/confirmPassword', {
          passwordResetToken: this.passwordResetToken,
          password: this.credentials.password
        })
        .then(result => {
          // console.log(result.status)
          if (result.status == 'OK') {
            this.$router.push({ name: 'login' })
          } else {
            // console.log(result.message)
            this.messageDisplay = result.message
          }
        })
    },
    inputPasswordClass() {
      if (this.credentials.password.length > 0) {
        if (this.isValidPassword()) {
          this.passwordError = ''
          return 'is-success'
        } else {
          this.passwordError = 'Must be a minimum 12 characters and contain at least one uppercase letter, one lowercase letter, one special character, and one number.'
          return 'is-danger'
        }
      } else {
        return ''
      }
    },
    inputPasswordConfirmationClass() {
      if (this.credentials.passwordConfirmation.length > 0) {
        if (this.isValidPasswordConfirmation()) {
          this.confirmationError = ''
          return 'is-success'
        } else {
          this.confirmationError = 'Confirmation password does not match.'
          return 'is-danger'
        }
      } else {
        return ''
      }
    },
    isValidPassword() {
      let password = this.credentials.password
      
      if (password === '') {
        this.passwordLen, this.passwordLower, this.passwordUpper, this.passwordSymbol, this.passwordNumber = false
        return false
      }
      
      if (this.credentials.password.length >= 11) {
        this.passwordLen = true
      }
      else {
        this.passwordLen = false
      }

      const lowercasePattern = /^(?=.*[a-z])/
      if (lowercasePattern.test(password)){
        this.passwordLower = true
      }
      else {
        this.passwordLower = false
      }

     const upercasePattern = /^(?=.*[A-Z])/
      if (upercasePattern.test(password)){
        this.passwordUpper = true
      }
      else {
        this.passwordUpper = false
      }

      const numberPattern = /^(?=.*\d)/
      if (numberPattern.test(password)){
        this.passwordNumber = true
      }
      else {
        this.passwordNumber = false
      }
      
      

      const symbolPattern = /^(?=.*[@$!%*?&#^()-+_.,|~'])[A-Za-z\d@$!%*?&#^()-+_.,|~' ]/
      if (symbolPattern.test(password)){
        this.passwordSymbol = true
      }
      else {
        this.passwordSymbol = false
      }
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()-+_.,|~'])[A-Za-z\d@$!%*?&#^()-+_.,|~' ]{12,}$/
      let result = passwordPattern.test(password)
      if (!result) {
        return false
      }
      return true
    },
    isValidPasswordConfirmation() {
      if (this.credentials.passwordConfirmation.length == 0) return true
      if (this.credentials.password != this.credentials.passwordConfirmation) {
        return false
      }
      return true
    }
  }
}
</script>
<style scoped>  ul {
                    padding-bottom: 5%;}
div.space {
  width: 10%;
 
}
</style>
