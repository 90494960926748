<template>
  <div>
    <div class="columns">
      <div class="column">
        <TdPanel panelId="order_log_list" panelTitle="30 Day - Order Activity">
          <order-log-list />
        </TdPanel>
      </div>
      <div class="column">
        <TdPanel panelId="user_log_list" panelTitle="Recent Account History">
          <p style="color: red;margin-left:10px">Keeping your account secure is essential! If you see suspicious activity on your account, please contact our customer service team immediately.</p>
          <user-log-list />
        </TdPanel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import OrderLogList from '@/components/bryce/OrderLogList.vue'
import UserLogList from '@/components/bryce/UserLogList.vue'

export default {
  data() {
    return {
      sendingToServer: false
    }
  },
  components: {
    OrderLogList,
    UserLogList
  },
  computed: {
    ...mapState(['auth_user', 'user_profile', 'td_constant']),
    ...mapState('user_profile', ['userProfile'])
  },
  created() {
    this.$store.dispatch('user_profile/fetch', {})
  },
  methods: {
    onSubmit() {
      this.sendingToServer = true
      this.$store.dispatch('user_profile/save', {}).then(() => {
        this.sendingToServer = false
      })
    }
  }
}
</script>

<style></style>
