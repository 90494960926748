import { render, staticRenderFns } from "./MonitoringAlerts.vue?vue&type=template&id=5f477d20&scoped=true"
import script from "./MonitoringAlerts.vue?vue&type=script&lang=js"
export * from "./MonitoringAlerts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f477d20",
  null
  
)

export default component.exports