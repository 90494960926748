import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {
  alertsCount: null
}

export const mutations = {}

export const actions = {
  resultMarkAsRead({ dispatch }, { subjectId, isMonitoring, alertId, licenseResultId, searchResultId }) {
    BryceService.send({
      method: 'resultMarkAsRead',
      errorMessage: 'updating alert',
      params: { subject_id: subjectId, alert_id: alertId, license_result_id: licenseResultId, action_result_id: searchResultId, isMonitoring: isMonitoring }
    }).then(() => {
      dispatch('provider_result/rollUpAck', { subjectId: subjectId, isMonitoring: isMonitoring, alertId: alertId, licenseResultId: licenseResultId, searchResultId: searchResultId }, { root: true })
    })
  },
  resultMarkAsUnRead({ dispatch }, { subjectId, isMonitoring, alertId, licenseResultId, searchResultId }) {
    BryceService.send({
      method: 'resultMarkAsUnRead',
      errorMessage: 'updating alert',
      params: { subject_id: subjectId, alert_id: alertId, license_result_id: licenseResultId, action_result_id: searchResultId, isMonitoring: isMonitoring }
    }).then(() => {
      dispatch('provider_result/rollUpUnAck', { subjectId: subjectId, isMonitoring: isMonitoring, alertId: alertId, licenseResultId: licenseResultId, searchResultId: searchResultId }, { root: true })
    })
  },
  alertUpdate({}, { alertId, subjectId, notes }) {
    BryceService.send({ method: 'alertUpdate', errorMessage: 'updating alert', params: { subject_id: subjectId, alert_id: alertId, notes: notes } })
  },
  searchResultUpdate({}, { adverseActionId, notes }) {
    BryceService.send({ method: 'searchResultUpdate', errorMessage: 'updating actionResult', params: { search_result_id: adverseActionId, notes: notes } })
  },
  fetchAlertsCount({}) {
    return BryceService.send({ method: 'getAlertCount', errorMessage: 'fetching alert count', params: {} }).then(results => {
      // console.log(results)
      state.alerts_count = null
      if (results) state.alertsCount = results.total_count
    })
  },
  fetchAlertInfo({}, { alertId, subjectId }) {
    return BryceService.send({ method: 'getAlertInfo', errorMessage: 'fetching alert', params: { subject_id: subjectId, alert_id: alertId } }).then(results => {
      if (results) return results.alert_data
    })
  }
}

export const getters = {
  getAlertCount: state => index => {
    return state.alertsCount
  }
}
