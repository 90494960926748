import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {
  groups: [],
  newIndex: 0
}

export const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups
  },
  CREATE_NEW(state) {
    if (state.newIndex == state.groups.length && state.groups[state.newIndex] == '') {
      return
    } else {
      state.newIndex = state.groups.length
      state.groups.push({ name: '', id: state.newIndex })
    }
  },
  UPDATE_NEW(state, name) {
    state.groups[state.newIndex].name = name
  }
}

export const actions = {
  createNew({ commit }, { name }) {
    commit('CREATE_NEW', name)
  },
  updateNew({ commit }, { name }) {
    commit('UPDATE_NEW', name)
  },
  fetchGroups({ commit }) {
    if (state.groups.length == 0) {
      return BryceService.send({ method: 'getGroups', errorMessage: 'fetching groups', params: {} }).then(results => {
        if (results) {
          let groups = []
          for (let i in results.groups) {
            groups.push({ name: results.groups[i], id: i, total_pending: null })
          }
          // for (let i in results.groups_data) {
          //   // console.log(results.groups_data[i].group, results.groups_data[i].total_pending)
          //   let fRow = groups.find(row => row.name == results.groups_data[i].group)
          //   fRow['total_pending'] = +results.groups_data[i].total_pending
          // }
          commit('SET_GROUPS', groups)
        }
      })
    }
  }
}

export const getters = {
  sortedGroups: state => {
    return state.groups.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    })
  }
}
