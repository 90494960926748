import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {}

export const mutations = {}

export const actions = {
  fetchProductEnrollHistory({}, { searchOptions }) {
    return BryceService.send({ method: 'getMonitoringProductEnrollHistory', errorMessage: 'fetching enroll history', params: searchOptions }).then(results => {
      if (results) return results.data
    })
  },
  fetchSubjectEnrollHistory({}, { searchOptions }) {
    return BryceService.send({ method: 'getMonitoringSubjectEnrollHistory', errorMessage: 'fetching enroll history', params: searchOptions }).then(results => {
      if (results) return results.data
    })
  }
}
export const getters = {}
