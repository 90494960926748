<template>
  <div class>
    <AdverseActionDetail v-if="hasActionResult" :includeForm="true" :subjectId="subjectId" :adverseActionId="alertId" :isMonitoring="isMonitoring" />
    <LicenseResultDetail :isMonitoring="isMonitoring" v-if="hasLicenseResult" :subjectId="subjectId" :includeForm="true" />
  </div>
</template>

<script>
import AdverseActionDetail from '@/components/bryce/AdverseActionDetail.vue'
import LicenseResultDetail from '@/components/bryce/LicenseResultDetail.vue'

export default {
  props: {
    alertId: {
      type: Number,
      default: null
    },
    isMonitoring: {
      default: false
    },
    licenseResultId: {
      type: Number,
      default: null
    },
    subjectId: {
      type: Number,
      default: null
    }
  },
  computed: {
    hasActionResult() {
      return !this.licenseResultId || null
    },
    hasLicenseResult() {
      return this.licenseResultId || null
    }
  },
  data() {
    return {
      alertDetail: null
    }
  },
  components: {
    AdverseActionDetail,
    LicenseResultDetail
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped></style>
