<template>
  <div>
    <section class="box">
      <form class @submit="onSubmitReportOptions">
        <div class="field is-grouped">
          <b-field label="Report Type" :type="!reportType || reportType == '' ? 'is-danger' : 'is-success'">
            <b-select v-model="reportType">
              <option value="alert">Alerts</option>
              <option value="license_expiration">Provider License Expiration</option>
              <option value="license_next">Provider License Next Check</option>
              <option value="license">Provider License</option>
              <option value="enrollment">Provider Enrollment</option>
              <option value="removal">Provider Removal</option>
              <option value="batch">Batch Enrollment History</option>
            </b-select>
          </b-field>
          <TdInputGroup :group.sync="group" :newButton="false" :includeAll="true" />
          <b-field v-if="reportType == 'alert' || reportType == 'license_expiration' || reportType == 'license_next' ||  reportType == 'license'" label="With Images?" :type="!withImages || withImages == '' ? 'is-danger' : 'is-success'">
              <b-select v-model="withImages">
                <option value="True">Yes</option>
                <option value="False">No</option>
              </b-select>
            </b-field>
          <b-field v-if="reportType == 'batch' || reportType == 'removal'" label="First Name">
            <b-input v-model.trim="firstName"></b-input>
          </b-field>
          <b-field v-if="reportType == 'batch' || reportType == 'removal'" label="Last Name">
            <b-input v-model.trim="lastName"></b-input>
          </b-field>
          <b-field v-if="reportType == 'batch' || reportType == 'removal'" label="Organization Name">
            <b-input v-model.trim="organizationName"></b-input>
          </b-field>
          <div v-if="reportType == 'alert' || reportType == 'enrollment'">
            <p class="heading">Time Span</p>
            <TdDatePickerRange v-model="dateRange" />
          </div>
          <div v-if="reportType == 'license' || reportType == 'license_next' || reportType == 'license_expiration'">
            <b-field label="Time Span" :type="!timeSpan || timeSpan == '' ? 'is-danger' : 'is-success'">
              <b-select v-model="timeSpan">
                <option value="span_7">7 Days</option>
                <option value="span_15">15 Days</option>
                <option value="span_30">30 Days</option>
                <option value="span_60">60 Days</option>
              </b-select>
            </b-field>
          </div>
          <div class="buttons" style="padding-left:10px">
            <button type="submit" class="button is-primary" id="next_button" @click="onSubmitReportOptions">Download</button>
            <button type="reset" class="button is-default" id="next_button" :disabled="waitingOnServer" @click="formReset()">Clear</button>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatDate } from '@/helpers/format_helpers'
export default {
  data() {
    return {
      dateRange: this.initDateRange(),
      firstName: null,
      group: '',
      lastName: null,
      organizationName: null,
      reportType: null,
      timeSpan: null,
      withImages: "True",
      waitingOnServer: false
    }
  },
  computed: {
    ...mapState(['td_constant'])
  },
  components: {},
  created() {},
  methods: {
    formReset() {
      this.dateRange = this.initDateRange()
      this.firstName = null
      this.group = ''
      this.lastName = null
      this.organizationName = null
      this.reportType = null
      this.timeSpan = null
      this.withImages = null
      this.waitingOnServer = false
    },
    initDateRange() {
      let today = new Date()
      today.setDate(today.getDate() + 1)
      let start60Date = new Date(today)
      start60Date.setDate(today.getDate() - 59)
      return {
        start: start60Date,
        end: today
      }
    },
    onSubmitReportOptions(e) {
      e.preventDefault()
      this.waitingOnServer = true
      let startDate = formatDate(this.dateRange.start, 'YYYY-MM-DD')
      let endDate = formatDate(this.dateRange.end, 'YYYY-MM-DD')
      if (this.reportType == 'license_expiration') {
        if (this.timeSpan && this.timeSpan != '') {
          let today = new Date()
          let nextDate = new Date(today)
          let offset = 0
          startDate = formatDate(today, 'YYYY-MM-DD')
          switch (this.timeSpan) {
            case 'span_7':
              offset = 7
              break
            case 'span_15':
              offset = 15
              break
            case 'span_30':
              offset = 30
              break
            case 'span_60':
              offset = 60
              break
            default:
              break
          }
          nextDate.setDate(today.getDate() + offset)
          endDate = formatDate(nextDate, 'YYYY-MM-DD')
        }
      }
      if (startDate.indexOf(':') == -1) startDate = startDate + 'T00:00:00.000Z'
      if (endDate.indexOf(':') == -1) endDate = endDate + 'T23:59:59.000Z'
      this.$store
        .dispatch('file/getReport', {
          reportOptions: {
            from_date: startDate,
            to_date: endDate,
            first_name: this.firstName,
            group: this.group,
            last_name: this.lastName,
            organization_name: this.organizationName,
            report_type: this.reportType,
            with_images: this.withImages,
            time_span: this.timeSpan,
            
          }
        })
        .then(() => {
          this.waitingOnServer = false
        })
    }
  }
}
</script>

<style scoped></style>
