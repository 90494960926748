import Vue from 'vue'
import fecha from 'fecha'
import { formatDate } from '@/helpers/format_helpers'
import { actionLevelWords, actionResultColor, actionResultWords } from '@/helpers/provider_helpers'

const booleanFilter = function(value, trueText, falseText) {
  return value ? trueText || 'Yes' : falseText || 'No'
}
const dateNoTz = function(value) {
  if (value) {
    let date = value.substring(0, 10).split('-')
    date = date[1] + '-' + date[2] + '-' + date[0]
    return value ? fecha.format(new Date(date), 'MMM D, YYYY') : ''
  }
}
const jsonFilter = function(value) {
  return value ? JSON.stringify(value, null, 2) : ''
}
const maskSsn = function(value) {
  let last4 = 'XXXX'
  if (value) last4 = value.substr(value.length - 4)
  return 'XXX-XX-' + last4
}
const numberWithCommas = function(num) {
  if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else return ''
}
const shortDate = function(value) {
  return formatDate(value, 'MMM D, YYYY')
}
const longDate = function(value) {
  return formatDate(value, 'MMM D, YYYY, h:mm:ss a')
}
const fullName = function(value) {
  if (value.organization_name) return value.organization_name
  else if (value.subject_organization_name) return value.subject_organization_name
  else if (value.subject_last_name) return value.subject_first_name + ' ' + value.subject_last_name
  else if (value.last_name) return value.first_name + ' ' + value.last_name
  else return ''
}
const alertLevelWords = function(alertLevel, labels) {
  return actionLevelWords(alertLevel, labels)
}
const resultWords = function(actionResult, labels) {
  return actionResultWords(actionResult, labels, null)
}

Vue.filter('actionResultColor', actionResultColor)
Vue.filter('alertLevelWords', alertLevelWords)
Vue.filter('resultWords', resultWords)
Vue.filter('json', jsonFilter)
Vue.filter('yesNo', booleanFilter)
Vue.filter('addCommas', numberWithCommas)
Vue.filter('fullName', fullName)
Vue.filter('longDate', longDate)
Vue.filter('maskSsn', maskSsn)
Vue.filter('shortNoTz', dateNoTz)
Vue.filter('shortDate', shortDate)
