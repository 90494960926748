import Vue from 'vue'
import Vuex from 'vuex'
import * as action_result from '@/store/modules/action_result.js'
import * as auth_user from '@/store/modules/shared/auth_user.js'
import * as batch from '@/store/modules/batch.js'
import * as customer from '@/store/modules/customer.js'
import * as file from '@/store/modules/file.js'
import * as group from '@/store/modules/group.js'
import * as layout from '@/store/modules/layout.js'
import * as license from '@/store/modules/license.js'
import * as monitoring from '@/store/modules/monitoring.js'
import * as transactional from '@/store/modules/transactional.js'
import * as notification from '@/store/modules/shared/notification.js'
import * as order_log from '@/store/modules/order_log.js'
import * as product from '@/store/modules/product.js'
import * as product_order from '@/store/modules/product_order.js'
import * as provider_result from '@/store/modules/provider_result.js'
import * as subject from '@/store/modules/subject.js'
import * as td_constant from '@/store/modules/shared/td_constant.js'
import * as user from '@/store/modules/shared/user.js'
import * as user_log from '@/store/modules/user_log.js'
import * as user_profile from '@/store/modules/user_profile.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    action_result,
    auth_user,
    batch,
    customer,
    file,
    group,
    layout,
    license,
    monitoring,
    notification,
    order_log,
    product,
    product_order,
    provider_result,
    subject,
    td_constant,
    transactional,
    user,
    user_log,
    user_profile
  },
  state: {},
  mutations: {},
  actions: {}
})
