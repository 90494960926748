<template>
  <div>
    <div>
      <b-field grouped>
        <b-field class="file" style="padding-top:20px">
          <b-upload v-model="selectedFile" @input="onFileSelected" accept=".csv" required>
            <a class="button is-primary">
              <b-icon icon="upload"></b-icon>
              <span>Upload file</span>
            </a>
          </b-upload>
          <span class="file-name" v-if="selectedFile">
            {{ selectedFile.name }}
          </span>
          <span class="file-name" v-if="selectedFile"> {{ batchResultState.percentCompleted }}% Uploaded </span>
        </b-field>
      </b-field>
    </div>
    <br />
    <div>
      <b-field grouped>
        <button type="submit" :disabled="batchFile.verified && !batchIssues" class="button is-primary" @click="onValidateBatch" v-if="selectedFile">Verify File</button>
      </b-field>
      <div v-if="serverError" class="text-danger">
        <div>{{ serverError }}. Unable to parse file.</div>
      </div>
      <div v-if="batchFileWarnings" class="text-warning">
        <div>
          {{ batchFileWarnings }}
        </div>
      </div>
      <div v-if="batchFile.verified == true && !batchIssues">
        <br />
        <div>
          <h5>Batch Verified:</h5>
          <ul style="list-style:disc;padding-inline-start:40px">
            <li v-if="monitoring">Review changes that will be made by this file.</li>
            <li>Click Place Order when you are ready to submit the file.</li>
          </ul>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Count</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ batchValidation.total_rows }}</td>
                <td colspan="10">Total Rows in File</td>
              </tr>
            </tbody>
          </table>
          <table v-if="monitoring" class="table table-bordered">
            <thead>
              <tr>
                <th>Count</th>
                <th></th>
                <th>Names (a sampling)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="batchValidation.to_add">
                <td>{{ batchValidation.to_add }}</td>
                <td>Added</td>
                <td>
                  <span>{{ batchValidation.subjects_to_add.substr(0, 20) }}</span
                  ><span v-if="!showMoreAdded">... <a @click="showMoreAdded = true">More</a></span
                  ><span v-if="showMoreAdded">{{ batchValidation.subjects_to_add.substr(21) }}<a @click="showMoreAdded = false">Less</a></span>
                </td>
              </tr>
              <tr v-if="batchValidation.to_enable">
                <td>{{ batchValidation.to_enable }}</td>
                <td>Enabled</td>
                <td>
                  <span>{{ batchValidation.subjects_to_enable.substr(0, 20) }}</span
                  ><span v-if="!showMoreEnabled">... <a @click="showMoreEnabled = true">More</a></span
                  ><span v-if="showMoreEnabled">{{ batchValidation.subjects_to_enable.substr(21) }}<a @click="showMoreEnabled = false">Less</a></span>
                </td>
              </tr>
              <tr v-if="batchValidation.to_remove">
                <td>{{ batchValidation.to_remove }}</td>
                <td>Removed</td>
                <td>
                  <span>{{ batchValidation.subjects_to_remove.substr(0, 20) }}</span
                  ><span v-if="!showMoreRemoved">... <a @click="showMoreRemoved = true">More</a></span
                  ><span v-if="showMoreRemoved">{{ batchValidation.subjects_to_remove.substr(21) }}<a @click="showMoreRemoved = false">Less</a></span>
                </td>
              </tr>
              <!-- <tr v-if="batchValidation.totalUnchanged">
              <td>{{ batchValidation.batchTotalUnchanged }}</td>
              <td colspan=10>No change in enrollment status</td></tr> -->
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="batchIssues">
        <h5>Review the file validation results:</h5>
        <ul class="" style="list-style:disc;padding-inline-start:40px">
          <li>Fix the file and click Change to select the newly modified file.</li>
          <li>Upload/Verify the file.</li>
          <li>Click Submit Batch when you are ready to submit the file.</li>
        </ul>
        <div class="form-group">
          <label class="col-sm-2 control-label" for="validationIssues">Issues</label>
          <div class="col-sm-10">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Issue</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="batchIssue in batchIssues" :key="batchIssue.row">
                  <th>{{ batchIssue.row }}</th>
                  <td>{{ batchIssue.issue }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  inheritAttrs: false,
  props: {
    file: {
      type: Object,
      required: true
    },
    batchProduct: {
      type: Object,
      required: true
    },
    monitoring: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      batchFile: {
        fileId: null,
        hasIssues: false,
        verified: false
      },
      batchIssues: null,
      batchFileWarnings: null,
      batchValidation: null,
      selectedFile: null,
      serverError: null,
      showMoreAdded: false,
      showMoreEnabled: false,
      showMoreRemoved: false,
      waitingOnServer: false
    }
  },
  created() {},
  computed: {
    ...mapState({
      batchResultState: state => state.batch
    })
  },
  components: {},
  watch: {
    file(newValue) {
      this.batchFile = newValue
    }
  },
  methods: {
    onFileSelected() {
      this.batchIssues = null
      this.batchFile = {
        fileId: null,
        hasIssues: false,
        verified: false
      }
      this.batchFileWarnings = null
      this.emitUpdates()
      this.onUpload()
      // console.log(this.selectedFile)
      // this.selectedFile = file
      // console.log(this.selectedFile)
    },
    onUpload() {
      const fd = new FormData()
      // must be 'uploadfile' exactly
      fd.append('uploadfile', this.selectedFile, this.selectedFile.name)
      this.$store.dispatch('batch/stageFile', { fd: fd }).then(fileId => {
        // console.log(fileId)
        this.batchFile.fileId = fileId
        this.$emit('update:file', this.batchFile)
      })
    },
    onValidateBatch(event) {
      event.preventDefault()
      this.waitingOnServer = true

      this.batchProduct.fileId = this.batchFile.fileId
      // console.log("this:" + JSON.stringify(this.subjectOfInterest, null, 2));
      this.$store
        .dispatch('batch/validateBatchFile', {
          batchProduct: this.batchProduct,
          monitoring: this.monitoring
        })
        .then(batchValidation => {
          if (batchValidation.error) {
            this.serverError = batchValidation.error
          } else {
            this.batchValidation = batchValidation
            if (!batchValidation.issues || batchValidation.issues.length == 0) {
              this.batchIssues = null
              this.batchFile.hasIssues = false
            } else {
              this.batchIssues = batchValidation.issues.sort(function(a, b) {
                return +a.row - +b.row
              })
              this.batchFile.hasIssues = true
              this.selectedFile = null
            }
            this.batchFile.verified = true
            this.emitUpdates()
            this.waitingOnServer = false
          }
        })
    },
    emitUpdates() {
      this.$emit('update:file', this.batchFile)
    }
  }
}
</script>

<style scoped></style>
