
<template>
  <div class>
    <form v-if="adverseActionDetail" class="form-horizontal" @submit="onSubmitOptions($event)">
      <pre style="white-space: pre-wrap">{{ adverseActionDetail.action_text }}</pre>
      <div v-if="adverseActionDetail.original_document_url != ''">
        <span style>Original Document:</span>
        <span>
          <embed 
            v-if="pdfUrl"
            :src="pdfUrl" 
            type="application/pdf"
            style="width: 100%; height: 500px;"  
          />
          <div v-else>Loading PDF...</div>
        </span>
      </div>
      <div>* The data in this table was provided by the primary source. Additional details may be found in the "Original Source Document" if available.</div>
      <div v-if="includeForm">
        <b-field label="Notes" :type="adverseActionDetail && adverseActionDetail.notes == notes ? '' : 'is-info'"
          ><span v-if="false">|A2|</span>
          <b-input v-model="adverseActionDetail.notes" :placeholder="notes" maxlength="200" rows="2" type="textarea"></b-input>
        </b-field>
        <button type="submit" class="button is-primary" @click="onSubmitOptions(adverseActionDetail)" :disabled="adverseActionDetail && adverseActionDetail.notes == notes">Update</button>
      </div>
    </form>
    <div v-else class="box">No Results</div>
  </div>
</template>

<script>
import getEnv from '@/utils/env'
export default {
  props: {
    adverseAction: {
      type: Object,
      required: false
    },
    adverseActionId: {
      type: [Number, String],
      required: false
    },
    subjectId: {
      type: [Number, String],
      required: false
    },
    isMonitoring: {
      type: Boolean,
      default: false
    },
    includeForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      adverseActionDetail: {},
      notes: null,
      pdfUrl: null,
      baseApiUrl: getEnv('VUE_APP_BASE_API_URL'),
    }
  },
  computed: {},
  watch: {
    'adverseActionDetail.original_document_url': {
      handler(newValue) {
        if (newValue) {
          this.fetchPdf();
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.adverseActionId) {
      if (this.isMonitoring) {
        this.$store.dispatch('action_result/fetchAlertInfo', { alertId: this.adverseActionId, subjectId: this.subjectId }).then(result => {
          this.adverseActionDetail = result.search_result
          this.notes = result.notes
          if (this.adverseActionDetail.verified == 'PENDING') {
            this.adverseActionDetail = {}
          }
        })
      } else {
        this.$store.dispatch('transactional/fetchAdverseAction', { searchResultId: this.adverseActionId }).then(adverseActionDetail => {
          this.adverseActionDetail = adverseActionDetail
          this.notes = adverseActionDetail.notes
          if (this.adverseActionDetail.verified == 'PENDING') {
            this.adverseActionDetail = {}
          }
        })
      }
    } else {
      this.adverseActionDetail = this.adverseAction
      this.notes = this.adverseAction.notes
    }
  },
  methods: {
    async fetchPdf() {
      // Remove the specified text from the URL
      const cleanedUrl = this.adverseActionDetail.original_document_url.replace('https://tbone.typhoonapp.com/core/get_original_pdf/', '');

      try {
        const response = await fetch(this.baseApiUrl + '/transactional/get_action_doc/' + cleanedUrl );
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        this.pdfUrl = URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error fetching PDF:', error);
        // Handle the error appropriately (e.g., display an error message to the user)
      }
    },
    onSubmitOptions(adverseAction) {
      if (this.isMonitoring) {
        this.$store.dispatch('action_result/alertUpdate', { alertId: this.adverseActionId, subjectId: this.subjectId, notes: adverseAction.notes }).then(() => {
          this.notes = adverseAction.notes
        })
      } else {
        this.$store.dispatch('action_result/searchResultUpdate', { adverseActionId: adverseAction.search_result_id, notes: adverseAction.notes }).then(() => {
          this.notes = adverseAction.notes
        })
      }
    }
  }
}
</script>

<style scoped>
pre {
  white-space: pre-wrap;
}
</style>