<template>
  <section v-if="rosterIsEmpty && !isBusy && !isBatch">
    <div style="padding:25px"></div>
    <b-message type="is-info">
      No results to display. To update results, specify search criteria and click Search above.
    </b-message>
    <div style="padding:25px"></div>
  </section>
  <section v-else>
    <div v-if="!showIntuitive" class="buttons">
      <button class="button field is-default" style="height:2.57em" @click="showSettings = !showSettings">
        <b-icon icon="settings-outline"></b-icon>
      </button>
      <span v-if="user_profile.levels.Pending && resultsCountPending > 0" class="button is-typhd-grey is-outlined">
        <div class="card-content" style="padding:2.5px">
          <p class="heading">{{ user_profile.labels.Pending }} ({{ resultsCountPending }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isPendingLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-grey">{{
              provider_result.isPendingLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-else-if="user_profile.levels.VerifiedImpairment && resultsCountVerifiedImpairment > 0" class="button is-typhd-critical is-outlined">
        <div class="card-content" style="padding:2px">
          <p class="heading">{{ user_profile.labels.VerifiedImpairment }} ({{ resultsCountVerifiedImpairment }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isVerifiedImpairmentLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-critical">{{
              provider_result.isVerifiedImpairmentLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-else-if="user_profile.levels.ReviewRequired && resultsCountReviewRequired > 0" class="button is-typhd-warning is-outlined">
        <div class="card-content" style="padding:2px">
          <p class="heading">{{ user_profile.labels.ReviewRequired }} ({{ resultsCountReviewRequired }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isReviewRequiredLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-warning">{{
              provider_result.isReviewRequiredLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-else-if="user_profile.levels.NonAdverse && resultsCountNonAdverse > 0" class="button is-typhd-grey is-outlined">
        <div class="card-content" style="padding:2.5px">
          <p class="heading">{{ user_profile.labels.NonAdverse }} ({{ resultsCountNonAdverse }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isNonAdverseLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-grey">{{
              provider_result.isNonAdverseLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-else-if="user_profile.levels.NoResultsFound && rosterCountNoResultsFound > 0" class="button is-typhd-grey is-outlined">
        <div class="card-content" style="padding:2.5px">
          <p class="heading">{{ user_profile.labels.NoResultsFound }} ({{ rosterCountNoResultsFound }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isNoResultsFoundLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-grey">{{
              provider_result.isNoResultsFoundLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span class="button is-typhd-info is-outlined">
        <div class="card-content" style="padding:6px">
          <p class="heading">{{ labelUnRead }} ({{ resultsCountUnRead }})</p>
          <b-select v-model="provider_result.isRead">
            <option value>Both</option>
            <option value="ack">{{ labelRead }}</option>
            <option value="unack">{{ labelUnRead }}</option>
          </b-select>
        </div>
      </span>
    </div>
    <div v-if="profileReady" v-show="showSettings" class="buttons">
      <span class="button is-outlined">
        <div class="buttons">
          <span class="buttons">
            <span class="button is-outlined">
              <div class="card-content" style="padding:7px">
                <label class="label">Columns</label>
                <b-field grouped group-multiline>
                  <div v-for="(column, index) in rosterColumns" :key="index" class="control">
                    <b-checkbox v-model="column.display">
                      {{ column.label }}
                    </b-checkbox>
                  </div>
                </b-field>
              </div>
            </span>
            <span class="button is-outlined">
              <div class="card-content" style="padding-top:0;padding-bottom:6px">
                <label class="label">Other</label>
                <b-field v-if="user_profile.rosterListOptions" grouped group-multiline>
                  <b-select v-model="user_profile.rosterListOptions.frequency" placeholder="Frequency">
                    <option value=""></option>
                    <option value="monitored">Monitored</option>
                    <option value="paused">Paused</option>
                    <option value="one-time">One-time</option>
                  </b-select>
                  <b-checkbox v-model="rosterOptions.quickSearch">
                    Quick Filter
                  </b-checkbox>
                </b-field>
              </div>
            </span>
          </span>
          <span class="button is-outlined">
            <div class="card-content" style="padding-top:0;padding-bottom:12px">
              <label class="label">Levels and Labels</label>
              <b-field grouped group-multiline>
                <label data-v-6b2bf7e4="" class="b-checkbox checkbox" style="padding-bottom: 8px;"
                  ><input type="checkbox" v-model="user_profile.levels.VerifiedImpairment" @input="provider_result.isVerifiedImpairmentLabel = 'Not-included'"/> <span class="check"></span>
                  <span class="control-label"></span
                ></label>
                <b-field label="Verified Impairment" :label-position="'on-border'">
                  <TdInput v-model.trim="user_profile.labels.VerifiedImpairment" :minlength="1" :maxlength="35" :isRequired="true" placeholder="Verified Impairment"></TdInput>
                </b-field>
                <label data-v-6b2bf7e4="" class="b-checkbox checkbox" style="padding-bottom: 8px;"
                  ><input type="checkbox" v-model="user_profile.levels.ReviewRequired" @input="provider_result.isReviewRequiredLabel = 'Not-included'"/> <span class="check"></span> <span class="control-label"></span
                ></label>
                <b-field label="Review Required" :label-position="'on-border'">
                  <TdInput v-model.trim="user_profile.labels.ReviewRequired" :minlength="1" :maxlength="35" :isRequired="true"></TdInput>
                </b-field>
                <label data-v-6b2bf7e4="" class="b-checkbox checkbox" style="padding-bottom: 8px;"
                  ><input type="checkbox" v-model="user_profile.levels.NonAdverse" @input="provider_result.isNonAdverseLabel = 'Not-included'"/> <span class="check"></span> <span class="control-label"></span
                ></label>
                <b-field label="Non-Adverse" :label-position="'on-border'">
                  <TdInput v-model.trim="user_profile.labels.NonAdverse" :minlength="1" :maxlength="35" :isRequired="true"></TdInput>
                </b-field>
                <label data-v-6b2bf7e4="" class="b-checkbox checkbox" style="padding-bottom: 8px;"
                  ><input type="checkbox" v-model="user_profile.levels.NoResultsFound" @input="provider_result.isNoResultsFoundLabel = 'Not-included'"/> <span class="check"></span> <span class="control-label"></span
                ></label>
                <b-field label="No Results Found" :label-position="'on-border'">
                  <TdInput v-model.trim="user_profile.labels.NoResultsFound" :minlength="1" :maxlength="35" :isRequired="true"></TdInput>
                </b-field>
                <label data-v-6b2bf7e4="" class="b-checkbox checkbox" style="padding-bottom: 8px;"
                  ><input type="checkbox" v-model="user_profile.levels.Pending" @input="provider_result.isPendingLabel = 'Not-included'"/> <span class="check"></span> <span class="control-label"></span
                ></label>
                <b-field label="Pending" :label-position="'on-border'">
                  <TdInput v-model.trim="user_profile.labels.Pending" :minlength="1" :maxlength="35" :isRequired="true"></TdInput>
                </b-field>
                <label data-v-6b2bf7e4="" class="b-checkbox checkbox" style="padding-bottom: 8px;"
                  ><input type="checkbox" v-model="user_profile.levels.ReadUnRead"/> <span class="check"></span> <span class="control-label"></span
                ></label>
                <b-field label="Read/Unread" :label-position="'on-border'">
                  <b-select v-model="user_profile.labels.ReadUnRead" placeholder="">
                    <option value="">Read/Un-Read</option>
                    <option value="ack">Acknowledge/Un-Acknowledge</option>
                  </b-select>
                </b-field>
              </b-field>
            </div>
          </span>
          <button class="button is-primary" @click="SaveSettings()">Remember</button>
          <button class="button is-default" @click="ResetSettings()">Reset</button>
        </div>
      </span>
    </div>
    <div v-show="showIntuitive" class="columns">
      <div class="column">
        {{ pagingLabel() }}
      </div>
      <div class="column">
        <button :disabled="pageCurrent <= 1" class="button is-default" v-if="showMonitoring" @click="onSubmitSearchOptions('previous', true)">Previous</button>
        <button :disabled="pageCurrent <= 1" class="button is-default" v-if="!showMonitoring" @click="onSubmitSearchOptions('previous', false)">Previous</button>
        <button class="button is-default" v-if="showMonitoring" @click="onSubmitSearchOptions('next', true)">Next</button>
        <button class="button is-default" v-if="!showMonitoring" @click="onSubmitSearchOptions('next', false)">Next</button>
      </div>
    </div>
    <b-table
      :data="filteredRoster"
      :checked-rows.sync="checkedProviders"
      :checkable="true"
      :current-page.sync="currentPage"
      :default-sort-direction="defaultSortDirection"
      default-sort="subject_created_at"
      :detailed="true"
      detail-key="id"
      :loading="isBusy"
      narrowed
      :opened-detailed="defaultOpenedDetails"
      :paginated="!showIntuitive"
      :pagination-position="paginationPosition"
      :pagination-simple="false"
      :per-page="perPage"
      :striped="true"
    >
      <template v-if="!showIntuitive" slot="top-left">
        <b-field grouped group-multiline>
          <button class="button field is-default" style="height:2.57em" @click="onRefesh()">
            <b-icon icon="refresh"></b-icon>
          </button>
          <b-select v-if="checkedProviders.length > 0" id="actions_select" name="actionsSelect" v-model="selectedAction" @input="onMarkedAll()" placeholder="With Selected" class="field is-default">
            <option value="ack">{{ labelRead }}</option>
            <option value="unack">{{ labelUnRead }}</option>
          </b-select>
          <b-select id="actions_export" name="actionsExport" :disabled="exportInprocess" v-model="exportFormat" @input="exportToFile()" placeholder="Export" class="field is-default" icon="download">
            <option value="csv">.csv</option>
            <option value="biff2">.xls</option>
            <option value="xlsx">.xlsx</option>
          </b-select>
          <b-field v-if="rosterOptions.quickSearch" grouped group-multiline>
            <b-input v-if="rosterColumns[colId].display" v-model.trim="provider_result.filterSubjectId" placeholder="Provider ID" />
            <b-select v-if="rosterColumns[colCustId].display && hasMoreThanOneCustomer" name="companySelect" v-model="provider_result.filterCustomerId" placeholder="Customer ID">
              <option value=""></option>
              <option v-for="option in listOfCustomerIdsSorted" :value="option" :key="option">
                {{ option }}
              </option>
            </b-select>
            <b-input v-model.trim="provider_result.filterName" placeholder="Name" />
            <b-select v-if="rosterColumns[colGroup].display" id="group_select" name="groupSelect" v-model="provider_result.filterGroup" placeholder="Group">
              <option value=""></option>
              <option v-for="g in sortedGroups" :value="g.name" :key="g.id">{{ g.name }}</option>
            </b-select>
            <b-input v-if="rosterColumns[colRef].display" v-model.trim="provider_result.filterReference" placeholder="Reference" />
            <button type="reset" class="button is-default" style="height:2.57em" id="next_button" @click="formReset()">Clear</button>
          </b-field>
        </b-field>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p v-if="isBusy">Getting results.</p>
            <p v-else>No results</p>
          </div>
        </section>
      </template>
      <template slot-scope="props">
        <b-table-column :visible="rosterColumns[colId].display" :field="rosterColumns[colId].field" :label="rosterColumns[colId].label" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <router-link :to="{ name: 'provider-view', query: { id: props.row.id, monitoring: props.row.isMonitoring } }"
            ><span v-if="props.row.isMonitoring">M{{ props.row.id }}</span> <span v-else>{{ props.row.id }}</span></router-link
          >
        </b-table-column>
        <b-table-column :visible="showIntuitive && !showMonitoring" field="customer_user_name" label="User" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable
          >{{ props.row.customer_user_name }}
        </b-table-column>
        <b-table-column :visible="rosterColumns[colCustId].display" :field="rosterColumns[colCustId].field" :label="rosterColumns[colCustId].label" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <span>{{ props.row.customer_id }} </span>
        </b-table-column>
        <b-table-column :visible="showIntuitive && !showMonitoring" field="subject_created_at" label="Run Date" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{
          props.row.subject_created_at | shortDate
        }}</b-table-column>
        <b-table-column
          :visible="!showIntuitive && rosterColumns[colSubCrAt].display"
          :field="rosterColumns[colSubCrAt].field"
          :label="rosterColumns[colSubCrAt].label"
          :class="{ 'has-text-weight-bold': !props.row.ack }"
          sortable
          >{{ props.row.subject_created_at | shortDate }}</b-table-column
        >
        <b-table-column :visible="rosterColumns[colName].display" :field="rosterColumns[colName].field" :label="rosterColumns[colName].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <router-link :to="{ name: 'provider-view', query: { id: props.row.id, monitoring: props.row.isMonitoring } }">{{ props.row | fullName }}</router-link>
        </b-table-column>
        <b-table-column :visible="showIntuitive && showMonitoring && !isFADV" field="dob" label="DOB" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.dob | shortNoTz }}</b-table-column>
        <b-table-column :visible="rosterColumns[colRef].display" :field="rosterColumns[colRef].field" :label="rosterColumns[colRef].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable
          >{{ props.row.reference }}
        </b-table-column>
        <b-table-column :visible="showIntuitive && showMonitoring" field="products" label="Products" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable
          >{{ prodDescriptions(props.row.productIds) }}
        </b-table-column>
        <b-table-column :visible="showIntuitive && showMonitoring" field="period" label="Period" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.period }} </b-table-column>
        <b-table-column
          :visible="rosterColumns[colGroup].display && showMonitoring"
          :field="rosterColumns[colGroup].field"
          :label="rosterColumns[colGroup].label"
          :class="{ 'has-text-weight-bold': !props.row.ack }"
          sortable
          >{{ props.row.group }}
        </b-table-column>
        <b-table-column :visible="showIntuitive" field="mon_status" label="Status" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.status }} </b-table-column>
        <b-table-column :visible="showIntuitive && !showMonitoring" field="batch_id" label="Batch" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <router-link :to="{ name: 'batch-view', query: { id: props.row.batch_id, monitoring: props.row.isMonitoring } }">{{ props.row.batch_id }}</router-link>
        </b-table-column>
        <b-table-column :visible="rosterColumns[colSource].display" :field="rosterColumns[colSource].field" :label="rosterColumns[colSource].label" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <span v-if="props.row.sources && props.row.sources.length > 0">{{ props.row.sources.join('; ') }}</span>
        </b-table-column>
        <b-table-column :visible="rosterColumns[colCrAt].display" :field="rosterColumns[colCrAt].field" :label="rosterColumns[colCrAt].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{
          props.row.created_at | shortDate
        }}</b-table-column>
        <b-table-column :visible="!showIntuitive && rosterColumns[colLevel].display" :field="rosterColumns[colLevel].field" :label="rosterColumns[colLevel].label" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <span v-for="(alertLevel, key) in props.row.alertLevels" :style="{ color: resultColor(alertLevel) }" :key="key">
            <div v-if="filteredLevels.includes(alertLevel)">{{ alertLevel | alertLevelWords(user_profile.labels) }}{{ resultCount(alertLevel, props.row) }}</div>
          </span>
        </b-table-column>
        <b-table-column :visible="rosterColumns[colAction].display" :field="rosterColumns[colAction].field" label="">
          <span class="buttons">
            <b-button
              v-if="props.row.isMonitoring && !props.row.isPaused"
              class="is-default is-rounded"
              tag="router-link"
              type="is-link"
              icon-left="account-edit"
              :to="{ name: 'provider-edit', query: { id: +props.row.id } }"
              >Edit
            </b-button>
            <b-button v-if="!showIntuitive && useAckButtons && props.row.ack" class="is-default is-rounded" @click="onSubjectMarkAsUnRead(props.row)">{{ labelBtnUnRead }}</b-button>
            <b-button v-if="!showIntuitive && useAckButtons && !props.row.ack" class="is-primary is-rounded" @click="onSubjectMarkAsRead(props.row, true)">{{ labelBtnRead }}</b-button>
            <b-button v-if="props.row.isMonitoring && !props.row.isPaused" class="is-primary is-rounded" @click="pause(props.row)" icon-left="pause">{{ showIntuitive ? 'Remove' : 'Pause' }}</b-button>
            <b-button v-if="props.row.isMonitoring && props.row.isPaused" class="is-success is-rounded" @click="resume(props.row)" icon-left="play">{{ showIntuitive ? 'Enroll' : 'Resume' }}</b-button>
            <b-button v-if="false && !props.row.isMonitoring" class="is-primary is-rounded" @click="enrollOneTime(props.row)" icon-left="redo">
              {{ td_constant.TYPH_DATA_MAKE_RECURRING }}
            </b-button></span
          >
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <ProviderDetail v-if="props.row.isMonitoring" :subjectId="props.row.id" :isMonitoring="true">
          <ProductOrderList :subjectId="props.row.id" :subjectType="props.row.subject_type" />
          <TdPanel panelId="alert_list" panelTitle="Results">
            <ProviderMonitoringResultsList :subjectId="props.row.id" />
          </TdPanel>
        </ProviderDetail>
        <ProviderDetail v-else :subjectId="props.row.id" :isMonitoring="false">
          <ProviderOneTimeResultsList :subjectId="props.row.id" />
        </ProviderDetail>
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { actionResultColor, actionLevelWords, exportResults, formatDob, resultFirstName, resultMiddleName, resultLastName } from '@/helpers/provider_helpers'
import { getProductOption } from '@/helpers/product_helpers'
import ProviderMonitoringResultsList from '@/components/bryce/ProviderMonitoringResultsList.vue'
import ProviderOneTimeResultsList from '@/components/bryce/ProviderOneTimeResultsList.vue'
import ProductOrderList from '@/components/bryce/ProductOrderList.vue'
import ProviderDetail from '@/components/bryce/ProviderDetail.vue'

export default {
  props: {
    isBatch: {
      type: Boolean,
      default: false
    },
    overrideIntuitive: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    showMonitoring: {
      type: Boolean,
      default: false
    },
    showOnetime: {
      type: Boolean,
      default: false
    },
    showRoster: {
      type: Boolean,
      default: false
    },
    showSummary: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      allSelected: null,
      selectedAction: null,
      countPerQuery: 500,
      exportInprocess: false,
      exportFormat: null,

      colId: 0,
      colCustId: 1,
      colSubCrAt: 2,
      colName: 3,
      colRef: 4,
      colGroup: 5,
      colSource: 6,
      colCrAt: 7,
      colLevel: 8,
      colAction: 9,
      defaultOpenedDetails: [],
      defaultSortDirection: 'desc',
      paginationPosition: 'both',
      currentPage: 1,
      perPage: 125,
      checkedProviders: [],
      showSettings: false,

      filteredTags: this.$store.state.group.groups,
      clearKey: 0
    }
  },
  components: {
    ProviderMonitoringResultsList,
    ProviderOneTimeResultsList,
    ProductOrderList,
    ProviderDetail
  },
  created() {
    this.$store.dispatch('group/fetchGroups', {})
  },
  computed: {
    showIntuitive() {
      if (this.overrideIntuitive) return false
      else return this.intuitiveUI
    },
    ...mapGetters('group', ['sortedGroups']),
    ...mapGetters('provider_result', [
      'filteredLevels',
      'filteredRoster',
      'pageCurrent',
      'resultsCountUnRead',
      'resultsCountRead',
      'rosterCountNoResultsFound',
      'resultsCountVerifiedImpairment',
      'resultsCountNonAdverse',
      'rosterCountMonitored',
      'resultsCountMonitoring',
      'rosterCountPaused',
      'rosterCountOnetime',
      'resulosterCountOnetime',
      'resultsCountPending',
      'resultsCountReviewRequired',
      'resultsLevelLabel',
      'subjectsCountTotal'
    ]),
    ...mapGetters('layout', ['intuitiveUI', 'isIqvia', 'isFADV']),
    ...mapGetters('provider_result', ['isBusy', 'hasMoreThanOneCustomer', 'listOfCustomerIdsSorted', 'rosterIsEmpty', 'isBusy']),
    ...mapGetters('user_profile', ['useAckButtons', 'labelBtnRead', 'labelBtnUnRead', 'labelRead', 'labelUnRead', 'profileReady', 'rosterColumns', 'rosterOptions']),
    ...mapState(['batch', 'auth_user', 'group', 'provider_result', 'user_profile']),
    ...mapState(['td_constant'])
  },

  methods: {
    onSubmitSearchOptions(direction, sMon) {
      if (sMon) {
        var include = true
        var fetchingName = 'MonitoringRoster'
      } else {
        var include = false
        var fetchingName = 'OnetimeRoster'
      }
      this.$store.dispatch('provider_result/fetchIt', { options: { pageTo: direction, includeMonitoring: include, fetchName: fetchingName } }).then(() => {})
    },
    prodDescriptions(prodIds) {
      let desc = ''
      for (let index in prodIds) {
        const productId = prodIds[index]
        if (index > 0) desc += ' | '
        desc += getProductOption(this.$store.state.layout, productId, 'bryce FADV').name
      }
      return desc
    },
    pagingLabel() {
      let label = 'Showing Page '
      label += this.$store.state.provider_result.pageCurrent + ' of ' + this.$store.state.provider_result.numPages
      return label
    },
    ResetSettings() {
      this.$store.dispatch('user_profile/resetSettings', {})
    },
    SaveSettings() {
      this.$store.dispatch('user_profile/save', {})
    },
    formReset() {
      this.$store.dispatch('provider_result/clearLocalFilters', {})
    },
    getFilteredGroups(text) {
      this.filteredTags = this.$store.state.group.groups.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    resultColor(alertLevel) {
      return actionResultColor({ verified: alertLevel })
    },
    resultCount(alertLevel, actionResult) {
      if (actionResult.alertLevelCounts && actionResult.alertLevelCounts[alertLevel] > 1) return ' (' + actionResult.alertLevelCounts[alertLevel] + ')'
    },
    exportToFile() {
      if (this.exportFormat != '') {
        this.exportInprocess = true
        exportResults(
          this.exportFormat,
          this.filteredLevels,
          this.$store.getters['provider_result/filteredRoster'],
          this.$store.state.provider_result.results,
          this.$store.state.layout,
          this.$store.state.user_profile.labels
        )
        this.exportInprocess = false
      }
    },
    onMarkedAll() {
      if (!this.selectedAction || this.selectedAction == '') return
      for (let i in this.checkedProviders) {
        let subject = this.checkedProviders[i]
        let foundItems = this.$store.state.provider_result.results.filter(result => result.subject_id == subject.id && result.isMonitoring == subject.isMonitoring)
        for (let rIndex in foundItems) {
          if (this.selectedAction == 'ack') this.onMarkAsAck(foundItems[rIndex])
          else if (this.selectedAction == 'unack') this.onMarkAsUnAck(foundItems[rIndex])
        }
      }
      this.selectedAction = ''
    },
    onSubjectMarkAsRead(subject, doIt) {
      if (doIt || !this.$store.getters['provider_result/useAckButtons']) {
        let foundItems = this.$store.state.provider_result.results.filter(result => result.subject_id == subject.id && result.isMonitoring == subject.isMonitoring)
        for (let rIndex in foundItems) {
          this.onMarkAsAck(foundItems[rIndex])
        }
      }
    },
    onSubjectMarkAsUnRead(subject) {
      let foundItems = this.$store.state.provider_result.results.filter(result => result.subject_id == subject.id && result.isMonitoring == subject.isMonitoring)
      for (let rIndex in foundItems) {
        this.onMarkAsUnAck(foundItems[rIndex])
      }
    },
    onMarkAsAck(result) {
      this.$store.dispatch('action_result/resultMarkAsRead', {
        alertId: result.alert_id,
        licenseResultId: result.license_result_id,
        searchResultId: result.search_result_id,
        subjectId: result.subject_id,
        isMonitoring: result.isMonitoring
      })
    },
    onMarkAsUnAck(result) {
      this.$store.dispatch('action_result/resultMarkAsUnRead', {
        alertId: result.alert_id,
        licenseResultId: result.license_result_id,
        searchResultId: result.search_result_id,
        subjectId: result.subject_id,
        isMonitoring: result.isMonitoring
      })
    },
    onRefesh: function() {
      this.$emit('refresh')
    },
    resume(row) {
      this.$buefy.dialog.confirm({
        message: 'This will resume monitoring on this provider. Continue?',
        confirmText: this.showIntuitive ? 'Enroll' : 'Resume',
        onConfirm: () => this.$store.dispatch('provider_result/resume', { subjectId: +row.id })
      })
    },
    pause(row) {
      this.$buefy.dialog.confirm({
        message: 'This will suspend monitoring on this provider. Continue?',
        confirmText: this.showIntuitive ? 'Remove' : 'Pause',
        onConfirm: () => this.$store.dispatch('provider_result/pause', { subjectId: +row.id })
      })
    }
  }
}
</script>

<style scoped>
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #3498db;
  border-color: #3498db;
}
/* buefy BEGIN */
.button {
  white-space: normal;
  height: auto;
}
/* buefy END */

.btn-primary {
  border-color: #3498db;
}

.btn-primary:hover {
  background-color: #369ade;
  border-color: #3498db;
}

.btn-primary:active {
  background-color: #3498db;
  border-color: #3498db;
}

.btn-primary:visited {
  background-color: #3498db;
  border-color: #3498db;
}

/* switcher-ion BEGIN */
.switcher-ion {
  width: 104px; /* Add to this */
}
.switcher-ion-inner {
  width: 200%;
  margin-left: -100%;

  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.switcher-ion-inner:before,
.switcher-ion-inner:after {
  float: left;
  width: 50%;
  height: 26px;
  padding: 0;
  line-height: 26px;
  font-size: 14px;
  color: white;
  font-weight: normal;
}
.switcher-ion-inner-warning:before {
  content: 'Included';
  color: #f39c12; /* Text color of On */
}
.switcher-ion-inner-critical:before {
  content: 'Included';
  color: #e74c3c; /* Text color of On */
}
.switcher-ion-inner-warning:after {
  content: 'Not Inc.';
}
.switcher-ion-inner-critical:after {
  content: 'Not Inc.';
}
.switcher-ion-switch {
  width: 30px;
  height: 30px;
  margin: 0px;
  background: #808080; /* button color of Off */
  border: 2px solid #fff;
  border-radius: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 74px; /* Add to this */

  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-inner {
  margin-left: 0;
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-switch {
  right: 0px;
  background-color: #3498db; /* button color of On 3498db */
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-switch-warning {
  right: 0px;
  background-color: #f39c12; /* button color of On 3498db */
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-switch-critical {
  right: 0px;
  background-color: #e74c3c; /* button color of On 3498db */
}
/* switcher-ion END */
</style>
