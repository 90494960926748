<template>
  <div>
    <BatchList :getOnetime="false" :getMonitoring="true" :groupName="group_info.name" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BatchList from '@/components/bryce/BatchList.vue'

export default {
  props: {
    id: {
      required: true
    },
    group_info: {
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('layout', ['intuitiveUI', 'isIqvia'])
  },
  components: {
    BatchList
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped></style>
