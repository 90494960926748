<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('auth_user/authLogout', {}).then(() => {
      this.$router.push({ name: 'login' })
    })
  }
}
</script>

<style scoped></style>
