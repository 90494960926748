<template>
  <div>
    <div class="has-text-centered">
      <p class="subtitle has-text-white">Please enter your name and password.</p>
    </div>
    <div class="box is-shadowless" :class="brandName">
      <LoginForm />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LoginForm from '@/components/shared/LoginForm.vue'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('layout', ['brandName'])
  },
  components: {
    LoginForm
  }
}
</script>

<style scoped>
.hds,
.iqvia,
.sjv {
  background: white;
}
.typhoon {
  background: transparent;
}
</style>
