<template>
  <div>
    <section class="section">
      <b-table :striped="true" :data="[subjectOrder]" narrowed :opened-detailed="defaultOpenedOrder" detail-key="id">
        <template slot="empty"></template>
        <template slot-scope="props">
          <b-table-column label="Order Id">{{ props.row.order_id }}</b-table-column>
          <b-table-column label="Product">{{ props.row.product_option.name }}</b-table-column>
          <b-table-column label="Completed">{{ props.row.completed_at | longDate }}</b-table-column>
          <b-table-column label="Verify">{{ props.row.verify | yesNo }}</b-table-column>
        </template>
      </b-table>
    </section>
    <div class="buttons">
      <b-field label="" v-if="checkedAdverseResults.length">
        <b-select class="" id="actions_adverse_select" name="actionsAdverseSelect" v-model="selectedAdverseAction" @input="onAdverseWithChecked()" placeholder="With Selected">
          <option value="ack">{{ labelRead }}</option>
          <option value="unack">{{ labelUnRead }}</option>
        </b-select>
      </b-field>
    </div>
    <b-table
      :striped="true"
      :data="adverseActionsFiltered"
      :checked-rows.sync="checkedAdverseResults"
      :checkable="true"
      :current-page.sync="currentPageAdverseResults"
      narrowed
      :paginated="adverseActionsFiltered.length > perPage"
      :pagination-simple="false"
      :per-page="perPage"
      :default-sort-direction="defaultSortDirection"
      default-sort="name"
      :opened-detailed="defaultOpenedAdverseResult"
      :detailed="true"
      detail-key="search_result_id"
    >
      <template slot="empty">
        No results
      </template>
      <template slot-scope="props">
        <b-table-column label="ID" :class="{ 'has-text-weight-bold': !props.row.ack }">
          {{ props.row.search_result_id }}
          <div v-if="isTDStaff">Record ID: {{ props.row.record_id }}</div>
          <div v-if="false && isTDStaff">Order ID: {{ props.row.order_id }}</div>
        </b-table-column>
        <b-table-column label="Name" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row | fullName }}</b-table-column>
        <b-table-column label="Source" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.source_name }}</b-table-column>
        <b-table-column label="Type" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.source_type }}</b-table-column>
        <b-table-column label="Year" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.year_of_action }}</b-table-column>
        <b-table-column v-if="isTDStaff" label="Match" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.match }}</b-table-column>
        <b-table-column v-if="subjectOrder.verify" label="Verify" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.verify | yesNo }}</b-table-column>
        <b-table-column v-if="false && isTDStaff" label="Verified" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{ props.row.oldVerified }}</b-table-column>
        <b-table-column field="level" label="Level" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <span :style="{ color: resultColor(props.row) }">{{ props.row | resultWords(user_profile.labels) }}</span>
        </b-table-column>
        <b-table-column field="actions" label="">
          <span class="buttons" v-if="props.row.verified != 'PENDING'">
            <b-button v-if="props.row.ack" class="is-default is-rounded" @click="onMarkUnAckAdverseAction(props.row)">{{ labelBtnUnRead }}</b-button>
            <b-button v-if="!props.row.ack" class="is-primary is-rounded" @click="onMarkAckAdverseAction(props.row)">{{ labelBtnRead }}</b-button>
          </span>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <AdverseActionDetail v-if="props.row.verified != 'PENDING'" :adverseAction="props.row" :subjectId="+subjectOrder.subject_id" :isMonitoring="false" :includeForm="true" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { actionResultColor, actionResultLevel } from '@/helpers/provider_helpers'
import AdverseActionDetail from '@/components/bryce/AdverseActionDetail.vue'

export default {
  props: {
    subjectOrder: {
      required: false
    },
    alertLevels: {
      default: () => [],
      required: false
    }
  },
  computed: {
    adverseActionsFiltered() {
      if (this.alertLevels && this.alertLevels.length > 0)
        return this.adverseActions ? this.adverseActions.filter(result => this.alertLevels.includes(result.verified) && result.order_id == this.subjectOrder.order_id) : []
      else return this.adverseActions
    },
    ...mapGetters('auth_user', ['isTDStaff']),
    ...mapGetters('user_profile', ['labelBtnRead', 'labelBtnUnRead', 'labelRead', 'labelUnRead']),
    ...mapState(['user_profile'])
  },
  data() {
    return {
      adverseActions: [],
      checkedAdverseResults: [],
      currentPageAdverseResults: 1,
      defaultOpenedAdverseResult: [],
      defaultOpenedOrder: [],
      defaultSortDirection: 'desc',
      perPage: 20,
      selectedAdverseAction: null
    }
  },
  components: {
    AdverseActionDetail
  },
  created() {
    this.adverseActions = []
    this.$store.dispatch('transactional/fetchAdverseActions', { orderId: +this.subjectOrder.order_id }).then(adverseActions => {
      for (let i in adverseActions) {
        adverseActions[i].oldVerified = adverseActions[i].verified
        adverseActions[i].verified = actionResultLevel(adverseActions[i])
        if (adverseActions[i].verified == 'NO_RESULTS_FOUND' || adverseActions[i].verified == 'PENDING') adverseActions[i].ack = true
        else adverseActions[i].ack = adverseActions[i].ack_at ? true : false
        this.adverseActions.push(adverseActions[i])
        // this.defaultOpenedAdverseResult.push(adverseActions[i].search_result_id)
      }
    })
  },
  methods: {
    onAdverseWithChecked() {
      if (!this.selectedAdverseAction || this.selectedAdverseAction == '') return
      for (let i in this.checkedAdverseResults) {
        let result = this.checkedAdverseResults[i]
        if (this.selectedAdverseAction == 'ack') this.onMarkAckAdverseAction(result)
        else if (this.selectedAdverseAction == 'unack') this.onMarkUnAckAdverseAction(result)
      }
      this.selectedAdverseAction = ''
    },
    onMarkAckAdverseAction(row) {
      if (!row.ack) {
        this.$store.dispatch('action_result/resultMarkAsRead', { searchResultId: row.search_result_id, subjectId: +this.subjectId, isMonitoring: false }).then(() => {
          row.ack = true
        })
      }
    },
    onMarkUnAckAdverseAction(row) {
      this.$store.dispatch('action_result/resultMarkAsUnRead', { searchResultId: row.search_result_id, subjectId: +this.subjectId, isMonitoring: false }).then(() => {
        row.ack = false
      })
    },
    resultColor(actionResult) {
      return actionResultColor(actionResult)
    }
  }
}
</script>

<style scoped></style>
