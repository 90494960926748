import BryceService from '@/services/BryceService.js'
import { formatDate } from '@/helpers/format_helpers'
import { ackUnAckResult, actionResultLevel, appendToResults, filterResults, filterSubjects, pauseResumeSubject } from '@/helpers/provider_helpers'

export const namespaced = true

export const state = {
  enableLocalFilters: false,
  fetchAction: '',
  filterId: '',
  filterBatchGroup: null,
  filterBatchId: null,
  filterCustomerId: null,
  filterName: '',
  filterGroups: [],
  filterGroup: null,
  filterReference: '',
  filterSubjectId: '',
  hasMonitoring: null,
  hasOneTime: null,
  isRead: 'unack',
  isLoading: null,
  isNetworking: 0,
  isNoResultsFoundLabel: 'Not-included',
  isVerifiedImpairmentLabel: 'Included',
  isNonAdverseLabel: 'Not-included',
  isPendingLabel: 'Not-included',
  isReviewRequiredLabel: 'Included',
  listOfCustomerIds: [],
  pageCurrent: 1,
  numPages: 0,
  results: [],
  resultsDateRangeBegin: null,
  resultsDateRangeEnd: null,
  roster: [],
  resultsOneTimeTotalCountOnServer: 0,
  resultsMonitoringTotalCountOnServer: 0,
  rosterOneTimeTotalCountOnServer: 0,
  searchByCountLimit: 5000,
  searchByFirstName: '',
  searchByGroups: [],
  searchByLastName: '',
  searchByOrganizationName: '',
  searchByReferenceNumber: '',
  searchByStatus: '',
  searchByDateRange: []
}

export const mutations = {
  APPEND_TO_RESULTS(state, options) {
    let added = appendToResults(state, options)
    if (!added) {
      if (options.oneTime) {
        state.resultsOneTimeTotalCountOnServer -= 1
      } else {
        state.resultsMonitoringTotalCountOnServer -= 1
      }
    }
  },
  APPEND_TO_RESULTS_COUNT(state, options) {
    if (options.oneTime) {
      state.resultsOneTimeTotalCountOnServer += options.count
    } else {
      state.resultsMonitoringTotalCountOnServer += options.count
    }
  },
  CLEAR_RESULTS(state) {
    state.listOfCustomerIds = []
    state.results = []
    state.resultsOneTimeTotalCountOnServer = 0
    state.resultsMonitoringTotalCountOnServer = 0
  },
  CLEAR_ROSTER(state) {
    state.listOfCustomerIds = []
    state.roster = []
    state.rosterOneTimeTotalCountOnServer = 0
  },
  CLEAR_FILTERS(state) {
    state.filterId = ''
    state.filterCustomerId = null
    state.filterBatchGroup = null
    state.filterBatchId = null
    state.filterSubjectId = ''
    state.filterName = ''
    state.filterReference = ''
    state.filterGroup = null
    state.filterGroups = []
    state.hasMonitoring = null
    state.hasOneTime = null
    state.isNoResultsFoundLabel = 'Not-included'
    state.isNonAdverseLabel = 'Not-included'
    state.isPendingLabel = 'Not-included'
    state.isReviewRequiredLabel = 'Included'
    state.isRead = 'unack'
    state.isVerifiedImpairmentLabel = 'Included'
    state.searchByCountLimit = 5000
    state.searchByFirstName = ''
    state.searchByGroups = []
    state.searchByLastName = ''
    state.searchByOrganizationName = ''
    state.searchByReferenceNumber = ''
    state.searchByStatus = ''
    state.searchByDateRange = []
  },
  CLEAR_LOCAL_FILTERS(state) {
    state.filterId = ''
    state.filterCustomerId = null
    state.filterSubjectId = ''
    state.filterName = ''
    state.filterReference = ''
    state.filterGroup = null
    state.filterGroups = []
  },
  PAUSE(state, options) {
    pauseResumeSubject(state, options, true)
  },
  RESUME(state, options) {
    pauseResumeSubject(state, options, false)
  },
  ROLLUP_ACK(state, options) {
    ackUnAckResult(state, options, true)
  },
  ROLLUP_UNACK(state, options) {
    ackUnAckResult(state, options, false)
  },
  NETWORKING_STARTED(state) {
    state.isNetworking += 1
  },
  NETWORKING_FINISHED(state) {
    state.isNetworking -= 1
  },
  SET_SEARCH_DATE_RANGE(state, days) {
    if (days) {
      const n = new Date()
      const start = new Date(n.getFullYear(), n.getMonth(), n.getDate() - days - 1)
      const end = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1)
      state.searchByDateRange = [start, end]
    }
  }
}

export const actions = {
  clearFilters({ commit }) {
    commit('CLEAR_FILTERS')
  },
  clearLocalFilters({ commit }) {
    commit('CLEAR_LOCAL_FILTERS')
  },
  clearResults({ commit }) {
    commit('CLEAR_RESULTS')
    commit('CLEAR_ROSTER')
  },
  rollUpAck({ commit }, { subjectId, isMonitoring, alertId, licenseResultId, searchResultId }) {
    commit('ROLLUP_ACK', { subjectId: subjectId, isMonitoring: isMonitoring, alertId: alertId, licenseResultId: licenseResultId, searchResultId: searchResultId })
  },
  rollUpUnAck({ commit }, { subjectId, isMonitoring, alertId, licenseResultId, searchResultId }) {
    commit('ROLLUP_UNACK', { subjectId: subjectId, isMonitoring: isMonitoring, alertId: alertId, licenseResultId: licenseResultId, searchResultId: searchResultId })
  },
  resume({ commit }, { subjectId }) {
    return BryceService.send({ method: 'resumeSubject', errorMessage: 'resuming provider', params: { subjectId: subjectId } }).then(() => {
      commit('RESUME', { subjectId: subjectId })
      return true
    })
  },
  setSearchByDateRange({ commit, state }, { days }) {
    commit('SET_SEARCH_DATE_RANGE', days)
    // console.log(state.searchByDateRange)
  },
  pause({ commit }, { subjectId }) {
    return BryceService.send({ method: 'pauseSubject', errorMessage: 'pausing provider', params: { subjectId: subjectId } }).then(() => {
      commit('PAUSE', { subjectId: subjectId })
      return true
    })
  },
  fetchInit({ commit, state, dispatch }, { options }) {
    dispatch('clearFilters').then(() => {
      commit('SET_SEARCH_DATE_RANGE', options.days)
      dispatch('clearResults').then(() => {
        state.fetchAction = options.fetchAction
        state.enableLocalFilters = options.enableLocalFilters
      })
    })
  },
  fetchIt({ state, dispatch }, { options }) {
    dispatch('clearResults').then(() => {
      switch (state.fetchAction) {
        case 'MonitoringAlerts':
          dispatch('fetchMonitoringAlerts', { options: options })
          break
        case 'MonitoringRoster':
          dispatch('fetchMonitoringRoster', { options: options })
          break
        case 'OnetimeRoster':
          dispatch('fetchOnetimeRoster', { options: options })
          break
        case 'MonitoringResults':
          dispatch('fetchMonitoringResults', { options: options })
          break
        case 'OnetimeResults':
          dispatch('fetchOnetimeResults', { options: options })
          break
        case 'BothResults':
          dispatch('fetchOnetimeResults', { options: options })
          dispatch('fetchMonitoringResults', { options: options })
          break
      }
    })
  },
  fetchMonitoringAlerts({ state, commit, rootState }, { options }) {
    if (!options.includeMonitoring) return
    commit('NETWORKING_STARTED')
    let pageCount = 2000
    if (options.batch_group) {
      pageCount = 0
      state.filterBatchGroup = options.batch_group
      options.group = options.batch_group
    } else {
      state.filterBatchGroup = null
      options.group = state.searchByGroup
    }
    let params = {
      include_low: false,
      group: options.group,
      enabled_only: true,
      page: 1,
      page_count: pageCount
    }
    // console.log(pageCount)
    if (state.searchByGroups.length > 0) {
      params.groups = []
      for (let i in state.searchByGroups) {
        params.groups.push(state.searchByGroups[i].name)
      }
    }
    params.start_date = null
    params.end_date = null
    params.ms_start_date = null
    params.ms_end_date = null
    this.resultsDateRangeBegin = null
    this.resultsDateRangeEnd = null
    if (state.searchByDateRange[0]) {
      params.start_date = state.searchByDateRange[0]
      params.end_date = state.searchByDateRange[1]
      this.resultsDateRangeBegin = params.start_date
      this.resultsDateRangeEnd = params.end_date
    } else {
      // If we only pass in start_date it will order by id desc
      params.ms_start_date = new Date()
      this.resultsDateRangeBegin = params.ms_start_date
    }
    return BryceService.send({
      method: 'getMonitoringAlerts',
      errorMessage: 'fetching alerts',
      params: params
    }).then(results => {
      if (results) {
        for (let i in results.data) {
          let item = {}
          item.id = results.data[i].id
          item.alert_id = results.data[i].id
          item.customer_id = results.data[i].customer_id
          item.created_at = results.data[i].created_at
          item.subject_id = results.data[i].subject_id
          item.group = results.data[i].group
          item.first_name = results.data[i].first_name
          item.middle_name = results.data[i].middle_name
          item.last_name = results.data[i].last_name
          item.reference = results.data[i].reference
          if (results.data[i].license_result && results.data[i].license_result.id > 0) {
            item.source = results.data[i].license_result.source_name
            item.license_result = results.data[i].license_result
            item.license_result_id = results.data[i].license_result.id
          } else if (results.data[i].search_result) {
            item.source = results.data[i].search_result.source_name
            item.search_result = results.data[i].search_result
            item.search_result_id = results.data[i].search_result.id
          }
          item.source_name = item.source
          item.detail = results.data[i].detail
          item.organization_name = results.data[i].organization_name
          item.alertLevel = actionResultLevel(results.data[i])
          item.ack_at = results.data[i].ack_at
          item.ack = false
          item.isMonitoring = true
          if (item.ack_at) item.ack = true
          if (item.alertLevel == 'NO_RESULTS_FOUND' || item.alertLevel == 'PENDING') item.ack = true
          state.results.push(item)
        }
        commit('APPEND_TO_RESULTS_COUNT', { oneTime: false, count: results.total_count })
        commit('NETWORKING_FINISHED')
      } else {
        commit('NETWORKING_FINISHED')
      }
    })
  },
  fetchMonitoringRoster({ state, commit, rootState }, { options }) {
    if (!options.includeMonitoring) return
    commit('NETWORKING_STARTED')
    let pageCount = 50
    if (options.pageTo == 'previous') state.pageCurrent -= 1
    else if (options.pageTo == 'next') state.pageCurrent += 1
    if (options.batch_group) {
      pageCount = 0
      state.filterBatchGroup = options.batch_group
      options.group = options.batch_group
    } else {
      options.group = state.searchByGroup
      state.filterBatchGroup = null
    }
    if (options.batch_id) {
      pageCount = 0
      state.filterBatchId = options.batch_id
    } else {
      state.filterBatchId = null
    }
    let params = {
      include_low: false,
      group: options.group,
      page: state.pageCurrent,
      page_count: pageCount,
      first_name: state.searchByFirstName,
      last_name: state.searchByLastName,
      organization_name: state.searchByOrganizationName,
      reference: state.searchByReferenceNumber,
      batch_id: state.filterBatchId
    }
    if (state.searchByStatus != '') {
      params.status = state.searchByStatus
    }
    if (state.searchByGroups.length > 0) {
      params.groups = []
      for (let i in state.searchByGroups) {
        params.groups.push(state.searchByGroups[i].name)
      }
    }
    return BryceService.send({
      method: 'getMonitoringRoster',
      errorMessage: 'fetching roster',
      params: params
    }).then(results => {
      if (results) {
        for (let i in results.data) {
          let item = {}
          item.id = results.data[i].id
          item.subject_id = results.data[i].id
          item.customer_id = results.data[i].customer_id
          item.subject_created_at = results.data[i].created_at
          item.group = results.data[i].group
          item.first_name = results.data[i].first_name
          item.middle_name = results.data[i].middle_name
          item.last_name = results.data[i].last_name
          item.organization_name = results.data[i].organization_name
          item.reference = results.data[i].reference
          item.dob = results.data[i].dob
          item.isMonitoring = true
          item.ack = true
          item.productIds = []
          for (let j in results.data[i].products) {
            item.productIds.push(results.data[i].products[j].product_id)
          }
          item.period = formatDate(results.data[i].created_at, 'MMM D, YYYY')
          item.period += ' - '
          if (results.data[i].disabled_at) item.period += formatDate(results.data[i].disabled_at, 'MMM D, YYYY')
          else item.period += 'PRESENT'
          if (results.data[i].disabled_at) {
            item.isPaused = true
            item.status = 'REMOVED'
          } else {
            item.isPaused = false
            item.status = 'ENROLLED'
          }
          state.roster.push(item)
        }
        state.numPages = 1
        if (pageCount > 0) state.numPages = Math.ceil(results.total_count / pageCount)
        commit('NETWORKING_FINISHED')
      } else {
        commit('NETWORKING_FINISHED')
      }
    })
  },
  fetchMonitoringResults({ state, commit, rootState }, { options }) {
    if (!options.includeMonitoring) return
    commit('NETWORKING_STARTED')
    let pageCount = +state.searchByCountLimit
    if (options.batch_group) {
      pageCount = 0
      state.filterBatchGroup = options.batch_group
      options.group = options.batch_group
    } else {
      state.filterBatchGroup = null
      options.group = state.searchByGroup
    }
    let params = {
      include_low: true,
      first_name: state.searchByFirstName,
      last_name: state.searchByLastName,
      organization_name: state.searchByOrganizationName,
      reference: state.searchByReferenceNumber,
      group: options.group,
      enabled_only: false,
      page: 1,
      page_count: pageCount
    }
    if (state.searchByGroups.length > 0) {
      params.groups = []
      for (let i in state.searchByGroups) {
        params.groups.push(state.searchByGroups[i].name)
      }
    }
    params.start_date = null
    params.end_date = null
    params.ms_start_date = null
    params.ms_end_date = null
    this.resultsDateRangeBegin = null
    this.resultsDateRangeEnd = null
    if (state.searchByDateRange[0]) {
      params.ms_start_date = state.searchByDateRange[0]
      params.ms_end_date = state.searchByDateRange[1]
      this.resultsDateRangeBegin = params.ms_start_date
      this.resultsDateRangeEnd = params.ms_end_date
    } else {
      // If we only pass in start_date it will order by id desc
      params.ms_start_date = new Date()
      this.resultsDateRangeBegin = params.ms_start_date
    }
    return BryceService.send({
      method: 'getMonitoringAlerts',
      errorMessage: 'fetching alerts',
      params: params
    }).then(results => {
      if (results) {
        for (let i in results.data) {
          commit('APPEND_TO_RESULTS', { oneTime: false, layout: rootState.layout, item: results.data[i], search_result: results.data[i].search_result, license_result: results.data[i].license_result, found_status: results.data[i].found_status, listType: 'alerts' })
        }
        commit('APPEND_TO_RESULTS_COUNT', { oneTime: false, count: results.total_count })
        commit('NETWORKING_FINISHED')
      } else {
        commit('NETWORKING_FINISHED')
      }
    })
  },
  fetchOnetimeResults({ state, commit, rootState }, { options }) {
    if (!options.includeOnetime) return
    let pageCount = +state.searchByCountLimit
    if (options.batch_id) {
      pageCount = 0
      state.filterBatchId = options.batch_id
    } else {
      state.filterBatchId = null
    }
    if (state.searchByGroups.length == 0) {
      commit('NETWORKING_STARTED')
      let params = {
        // order_id: options.order_id,
        subject_id: options.subject_id,
        batch_id: options.batch_id,
        first_name: state.searchByFirstName,
        last_name: state.searchByLastName,
        organization_name: state.searchByOrganizationName,
        reference: state.searchByReferenceNumber,
        customer_user_id: options.customer_user_id,
        page: 1,
        page_count: pageCount
      }
      if (state.searchByDateRange[0]) {
        params.start_date = state.searchByDateRange[0]
        params.end_date = state.searchByDateRange[1]
      } else {
        params.start_date = new Date()
      }
      return BryceService.send({
        method: 'getTransactionalAdverseActions',
        errorMessage: 'fetching adverse actions',
        params: params
      }).then(results => {
        if (results) {
          for (let i in results.data) {
            commit('APPEND_TO_RESULTS', { oneTime: true, layout: rootState.layout, item: results.data[i], listType: 'adverse_actions' })
          }
          commit('APPEND_TO_RESULTS_COUNT', { oneTime: true, count: results.total_count })
          commit('NETWORKING_FINISHED')
        } else {
          commit('NETWORKING_FINISHED')
        }
      })
    }
  },
  fetchOnetimeRoster({ state, commit, rootState }, { options }) {
    if (options.includeMonitoring) return
    commit('NETWORKING_STARTED')
    let pageCount = 50
    if (options.pageTo == 'previous') state.pageCurrent -= 1
    else if (options.pageTo == 'next') state.pageCurrent += 1
    if (options.batch_id) {
      pageCount = 0
      state.filterBatchId = options.batch_id
    } else {
      state.filterBatchId = null
    }
    let params = {
      page: state.pageCurrent,
      page_count: pageCount,
      first_name: state.searchByFirstName,
      last_name: state.searchByLastName,
      organization_name: state.searchByOrganizationName,
      reference: state.searchByReferenceNumber,
      batch_id: state.filterBatchId
    }
    if (state.searchByDateRange[0]) {
      let diff = state.searchByDateRange[1] - state.searchByDateRange[0]
      params.days_back = Math.ceil(diff / (1000 * 60 * 60 * 24))
    }
    return BryceService.send({
      method: 'getOnetimeRoster',
      errorMessage: 'fetching roster',
      params: params
    }).then(results => {
      if (results) {
        for (let i in results.data) {
          let item = {}
          item.id = results.data[i].id
          item.subject_id = results.data[i].id
          item.customer_id = results.data[i].customer_id
          item.subject_created_at = results.data[i].created_at
          item.first_name = results.data[i].first_name
          item.middle_name = results.data[i].middle_name
          item.last_name = results.data[i].last_name
          item.reference = results.data[i].reference
          item.dob = results.data[i].dob
          item.customer_user_name = results.data[i].customer_user_name
          item.batch_id = results.data[i].batch_id
          if (results.data[i].completed_at) {
            item.status = 'COMPLETED'
          } else {
            item.status = 'PENDING'
          }
          item.isMonitoring = false
          state.roster.push(item)
        }
        state.numPages = 1
        if (pageCount > 0) state.numPages = Math.ceil(results.total_count / pageCount)
        commit('NETWORKING_FINISHED')
      } else {
        commit('NETWORKING_FINISHED')
      }
    })
  }
}

export const getters = {
  hasMoreThanOneCustomer: state => {
    return state.listOfCustomerIds.length > 1
  },
  pageCurrent: state => {
    return state.pageCurrent
  },
  listOfCustomerIdsSorted: state => {
    return state.listOfCustomerIds.sort()
  },
  isByBatch: state => {
    return state.filterBatchGroup != null || state.filterBatchId != null
  },
  isBusy: state => {
    return state.isLoading || state.isNetworking > 0
  },
  resultsCountTotal: state => {
    return state.results.length
  },
  resultsCountRead: state => {
    return state.results ? state.results.filter(result => result.ack_at != null).length : 0
  },
  resultsCountUnRead: state => {
    return state.results ? state.results.filter(result => result.ack_at == null).length : 0
  },
  resultsCountVerifiedImpairment: state => {
    return state.results ? state.results.filter(result => result.alertLevel == 'VERIFIED_IMPAIRMENT').length : 0
  },
  resultsCountNonAdverse: state => {
    return state.results ? state.results.filter(result => result.alertLevel == 'NON_ADVERSE').length : 0
  },
  resultsCountPending: state => {
    return state.results ? state.results.filter(result => result.alertLevel == 'PENDING').length : 0
  },
  resultsCountMonitoring: state => {
    return state.results ? state.results.filter(result => result.isMonitoring == true).length : 0
  },
  resultsCountOnetime: state => {
    return state.results ? state.results.filter(result => result.isMonitoring == false).length : 0
  },
  resultsCountReviewRequired: state => {
    return state.results ? state.results.filter(result => result.alertLevel == 'REVIEW_REQUIRED').length : 0
  },
  resultsLevelLabel: state => {
    let label = ''
    label += formatDate(state.resultsDateRangeBegin, 'MM-DD-YY') + ' to ' + formatDate(state.resultsDateRangeEnd, 'MM-DD-YY')
    return label
  },
  rosterCountNoResultsFound: state => {
    return state.roster ? state.roster.filter(result => result.alertLevels && result.alertLevels.includes('NO_RESULTS_FOUND')).length : 0
  },
  rosterCountTotal: state => {
    return state.roster.length
  },
  rosterOneTimeCountTotal: state => {
    return state.roster.filter(result => !result.isMonitoring).length
  },
  rosterMonitoringCountTotal: state => {
    return state.roster.filter(result => result.isMonitoring).length
  },
  rosterCountOnetime: state => {
    return state.roster.filter(result => result.isMonitoring == false).length
  },
  rosterCountMonitored: state => {
    return state.roster.filter(result => result.isMonitoring == true && result.isPaused == false).length
  },
  rosterCountPaused: state => {
    return state.roster.filter(result => result.isMonitoring == true && result.isPaused == true).length
  },
  rosterCountCompletedTotal: state => {
    return state.roster ? state.roster.filter(result => result.completed_at || result.isMonitoring).length : 0
  },
  filteredLevels: state => {
    let levels = []
    if (state.isNoResultsFoundLabel == 'Included') {
      levels.push('NO_RESULTS_FOUND')
    }
    if (state.isNonAdverseLabel == 'Included') {
      levels.push('NON_ADVERSE')
    }
    if (state.isPendingLabel == 'Included') {
      levels.push('PENDING')
    }
    if (state.isVerifiedImpairmentLabel == 'Included') {
      levels.push('VERIFIED_IMPAIRMENT')
    }
    if (state.isReviewRequiredLabel == 'Included') {
      levels.push('REVIEW_REQUIRED')
    }
    return levels
  },
  filteredResults: (state, getters) => {
    let options = {
      alertLevels: getters.filteredLevels,
      id: state.filterId,
      subjectId: state.filterSubjectId,
      customerId: state.filterCustomerId,
      group: state.filterGroup,
      name: state.filterName,
      reference: state.filterReference
    }
    if (state.filterGroups.length > 0) {
      options['groups'] = state.filterGroups.map(function(item) {
        return item['name']
      })
    }
    if (state.isRead == 'ack') {
      options['ack'] = true
    } else if (state.isRead == 'unack') {
      options['ack'] = false
    }
    return filterResults(state.results, options)
  },
  filteredRoster: (state, getters, rootState) => {
    if (!state.enableLocalFilters) return state.roster
    let options = {
      alertLevels: getters.filteredLevels,
      subjectId: state.filterSubjectId,
      customerId: state.filterCustomerId,
      group: state.filterGroup,
      name: state.filterName,
      reference: state.filterReference
    }
    if (state.filterGroups.length > 0) {
      options['groups'] = state.filterGroups.map(function(item) {
        return item['name']
      })
    }
    if (state.isRead == 'ack') {
      options['ack'] = true
    } else if (state.isRead == 'unack') {
      options['ack'] = false
    }
    if (rootState.user_profile.rosterListOptions.frequency == 'monitored') {
      options['monitored'] = true
    } else if (rootState.user_profile.rosterListOptions.frequency == 'paused') {
      options['paused'] = true
    } else if (rootState.user_profile.rosterListOptions.frequency == 'one-time') {
      options['onetime'] = true
    }
    return filterSubjects(state.roster, options)
  },
  resultsIsEmpty: state => {
    return state.results.length == 0
  },
  rosterIsEmpty: state => {
    return state.roster.length == 0
  },
  searchByFilters: state => {
    return {
      searchByCountLimit: state.searchByCountLimit,
      searchByFirstName: state.searchByFirstName,
      searchByGroups: state.searchByGroups,
      searchByLastName: state.searchByLastName,
      searchByOrganizationName: state.searchByOrganizationName,
      searchByReferenceNumber: state.searchByReferenceNumber,
      searchByDateRange: state.searchByDateRange,
      searchByStatus: state.searchByStatus
    }
  }
}
