<template>
  <section>
    <b-notification v-if="false" auto-close type="is-danger" :active.sync="isActive">
      {{ notification.message }}
    </b-notification>
  </section>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.$buefy.notification.open({
      duration: 5000,
      message: this.notification.message,
      position: 'is-bottom-right',
      type: 'is-danger',
      hasIcon: true
    })
  },
  computed: {},
  methods: {}
}
</script>

<style scoped></style>
