export const namespaced = true

export const state = {
  TYPH_DATA_MAKE_RECURRING: 'Enroll',
  TYPH_DATA_FREQUENCY_RECURRING: 'Monitoring',
  TYPH_DATA_FREQUENCY_ONE_TIME: 'One-time',
  TYPH_DATA_LICENSE_TYPES: {
    Acupuncturist: 'Acupuncturist',
    'Anesthesiologist Assistants': 'Anesthesiologist Assistants',
    'Apprentice Optician': 'Apprentice Optician',
    'Athletic Trainer': 'Athletic Trainer',
    Audiologist: 'Audiologist',
    'Audiology Assistant': 'Audiology Assistant',
    'Chiropractic Assistant': 'Chiropractic Assistant',
    'Chiropractic Physician': 'Chiropractic Physician',
    'Clinical Laboratory Personnel': 'Clinical Laboratory Personnel',
    'Clinical Laboratory Trainee': 'Clinical Laboratory Trainee',
    'Clinical Social Worker Intern': 'Clinical Social Worker Intern',
    Cosmetologist: 'Cosmetologist',
    'DEA Registration': 'DEA Registration',
    'Dental Hygienist': 'Dental Hygienist',
    'Dental Radiographer': 'Dental Radiographer',
    Dentist: 'Dentist',
    'Diagnostic Radiological Physicist': 'Diagnostic Radiological Physicist',
    'Dietetics/Nutritionist': 'Dietetics/Nutritionist',
    Electrologist: 'Electrologist',
    'Emergency Medical Technician': 'Emergency Medical Technician',
    Esthetician: 'Esthetician',
    'Hearing Aid Specialist': 'Hearing Aid Specialist',
    'Hearing Aid Specialist Trainee': 'Hearing Aid Specialist Trainee',
    'Licensed Clinical Social Worker': 'Licensed Clinical Social Worker',
    'Licensed Marriage and Family Therapist': 'Licensed Marriage and Family Therapist',
    'Licensed Mental Health Counselor': 'Licensed Mental Health Counselor',
    'Licensed Practical Nurse': 'Licensed Practical Nurse',
    'Marriage and Family Therapist Intern': 'Marriage and Family Therapist Intern',
    'Massage Therapist': 'Massage Therapist',
    'Massage Therapy Apprentice': 'Massage Therapy Apprentice',
    'Medical Doctor / Physician': 'Medical Doctor / Physician',
    'Medical Health Physicist': 'Medical Health Physicist',
    'Medical Nuclear Radiological Physicist': 'Medical Nuclear Radiological Physicist',
    'Medical Physicist In Training': 'Medical Physicist In Training',
    'Mental Health Counselor Intern': 'Mental Health Counselor Intern',
    'Naturopathic Physician': 'Naturopathic Physician',
    'NPI Number': 'NPI Number',
    'Nurse Practitioner': 'Nurse Practitioner',
    'Nursing Assistant': 'Nursing Assistant',
    'Nursing Home Administrator': 'Nursing Home Administrator',
    'Nutrition Counselor': 'Nutrition Counselor',
    'Occupational Therapist': 'Occupational Therapist',
    'Occupational Therapy Assistant': 'Occupational Therapy Assistant',
    Optician: 'Optician',
    Optometrist: 'Optometrist',
    Orthotist: 'Orthotist',
    'Osteopathic Physician': 'Osteopathic Physician',
    Paramedic: 'Paramedic',
    Pedorthist: 'Pedorthist',
    Pharmacist: 'Pharmacist',
    'Pharmacist Intern': 'Pharmacist Intern',
    'Pharmacy Technician': 'Pharmacy Technician',
    'Physical Therapist': 'Physical Therapist',
    'Physical Therapist Assistant': 'Physical Therapist Assistant',
    'Physician Assistant': 'Physician Assistant',
    'Pod X-Ray Assistant': 'Pod X-Ray Assistant',
    'Podiatric Physician': 'Podiatric Physician',
    'Professional Counselor': 'Professional Counselor',
    'Practical Nurse': 'Practical Nurse',
    Prosthetist: 'Prosthetist',
    'Prosthetist-Orthotist': 'Prosthetist-Orthotist',
    Psychologist: 'Psychologist',
    'Radiologist Assistant': 'Radiologist Assistant',
    'Registered Nurse': 'Registered Nurse',
    'Respiratory Therapist': 'Respiratory Therapist',
    'Social Worker': 'Social Worker',
    'Speech-Language Pathologist': 'Speech-Language Pathologist',
    'Speech-Language Pathology Assistant': 'Speech-Language Pathology Assistant',
    'Therapeutic Radiological Physicist': 'Therapeutic Radiological Physicist',
    Vaccination: 'Vaccination'
  },

  TYPH_DATA_ORG_LICENSE_TYPES: {
    'Aid Service License': 'Aid Service License',
    'Aid Service Verified License': 'Aid Service Verified License',
    'Air Ambulance Service License': 'Air Ambulance Service License',
    'Air Ambulance Service Verified License': 'Air Ambulance Service Verified License',
    'Ambulance Service License': 'Ambulance Service License',
    'Ambulance Service Verified License': 'Ambulance Service Verified License',
    'Ambulatory Surgical Facility License': 'Ambulatory Surgical Facility License',
    'Cherry Harvest Camp License': 'Cherry Harvest Camp License',
    'Child Birth Center License': 'Child Birth Center License',
    Clinic: 'Clinic',
    'DEA Registration': 'DEA Registration',
    'Drug Animal Control/Humane Society Registration Sodium Pentobarbital': 'Drug Animal Control/Humane Societ Registration Sodium Pentobarbital',
    'Drug Dog Handlers K9 Registration': 'Drug Dog Handlers K9 Registration',
    'Drug Fish and Wildlife Registration': 'Drug Fish and Wildlife Registration',
    'Drug Itinerant Vendor Registration': 'Drug Itinerant Vendor Registration',
    'Drug Other Controlled Substances Registration': 'Drug Other Controlled Substances Registration',
    'Drug Precursor Chemicals Registration': 'Drug Precursor Chemicals Registration',
    'Drug Sample Distributor Registration': 'Drug Sample Distributor Registration',
    'EMS Provider Supervisory Organization': 'EMS Provider Supervisory Organization',
    'Hospital Acute Care License': 'Hospital Acute Care License',
    'Hospital Alcohol and Chemical Dependency License': 'Hospital Alcohol and Chemical Dependency License',
    'Hospital Psychiatric License': 'Hospital Psychiatric License',
    'In Home Services Agency License': 'In Home Services Agency License',
    'Limited Veterinary Service/Animal Control Agency and Humane Society Registration': 'Limited Veterinar Service/Animal Control Agency and Humane Society Registration',
    'Medical Test Site Accredited License': 'Medical Test Site Accredited License',
    'Medical Test Site Categorized License': 'Medical Test Site Categorized License',
    'Medical Test Site Certificate of Waiver License': 'Medical Test Site Certificate of Waiver License',
    'Medical Test Site Provider Performed Microscopic Procedures License': 'Medical Test Site Provide Performed Microscopic Procedures License',
    'NPI Number': 'NPI Number',
    'Nursing Pool Registration': 'Nursing Pool Registration',
    'Pharmaceutical Manufacturer License': 'Pharmaceutical Manufacturer License',
    'Pharmaceutical Wholesaler License': 'Pharmaceutical Wholesaler License',
    'Pharmacy Health Care Entity License': 'Pharmacy Health Care Entity License',
    'Pharmacy License': 'Pharmacy License',
    'Pharmacy Non Resident License': 'Pharmacy Non Resident License',
    'Poison Distributor License': 'Poison Distributor License',
    'Poison Manufacturer License': 'Poison Manufacturer License',
    'Residential Treatment Facility License': 'Residential Treatment Facility License',
    'Temporary Worker Housing License': 'Temporary Worker Housing License',
    'Transient Accommodation License (Hotel/Motel)': 'Transient Accommodation License (Hotel/Motel)'
  },

  TYPH_DATA_SOURCE_TYPES: {
    'ABUSE REGISTRY': 'ABUSE REGISTRY',
    'ANIMAL ABUSE': 'ANIMAL ABUSE',
    'BOARD ACTION': 'BOARD ACTION',
    DEA: 'DEA',
    'FEDERAL ACTION': 'FEDERAL ACTION',
    'FEDERAL EXCLUSION': 'FEDERAL EXCLUSION',
    'FULL SAM': 'FULL SAM',
    'HISTORICAL EXCLUSION': 'HISTORICAL EXCLUSION',
    'IMPOSTER ALERT': 'IMPOSTER ALERT',
    'INTERNATIONAL ACTION': 'INTERNATIONAL ACTION',
    'LICENSE CHECK': 'LICENSE CHECK',
    'LICENSE CONDITION': 'LICENSE CONDITION',
    'LICENSE DENIAL': 'LICENSE DENIAL',
    'MEDICARE OPT-OUT': 'MEDICARE OPT-OUT',
    NEWS: 'NEWS',
    NPI: 'NPI',
    OFAC: 'OFAC',
    OPTIONAL: 'OPTIONAL',
    'PRESS RELEASE': 'PRESS RELEASE',
    'SPECIALTY ORG ACTION': 'SPECIALTY ORG ACTION',
    SSNDMF: 'SSNDMF',
    'STATE ACTION': 'STATE ACTION',
    'STATE EXCLUSION': 'STATE EXCLUSION'
  },
  TYPH_DATA_PROVIDER_TYPES: {
    249:'Acupuncturist',
    194:'Addictions Counselor',
    198:'Art Therapist',
    250:'Athletic Trainer',
    238:'Audiologist',
    180:'Certified or Qualified Medication Aide',
    190:'Chiropractic Assistant',
    189:'Chiropractor',
    175:'Clinical Nurse Specialist',
    192:'Counselor, Mental Health',
    244:'Cytotechnologist',
    200:'Dance Therapist',
    184:'Dental Assistant',
    186:'Dental Hygienist',
    183:'Dental Resident',
    185:'Dental Therapist/Dental Health Aide',
    182:'Dentist',
    187:'Denturist',
    203:'Dietitian',
    206:'Emergency Medical Responder',
    207:'Emergency Medical Technician',
    208:'Emergency Medical Technician, Advanced',
    179:'Health Care Aide/Direct Care Worker',
    259:'Health Care Facility Administrator',
    240:'Hearing Aid (or Instrument) Specialist, Dealer, Dispenser or Fitter',
    178:'Home Health Aide (Homemaker) ',
    251:'Homeopath',
    174:'Licensed Practical or Vocational Nurse',
    197:'Marriage and Family Therapist',
    228:'Massage Therapist',
    252:'Medical Assistant',
    242:'Medical or Clinical Lab Technician/Technologist',
    253:'Midwife, Lay (Non-Nurse)',
    201:'Music Therapist',
    254:'Naturopath',
    245:'Nuclear Medicine Technologist',
    177:'Nurse Aid/Nursing Assistant',
    171:'Nurse Anesthetist',
    172:'Nurse Midwife',
    173:'Nurse Practitioner',
    204:'Nutritionist',
    229:'Occupational Therapist',
    230:'Occupational Therapy Assistant',
    211:'Ocularist',
    212:'Optician',
    213:'Optometrist',
    255:'Orthotics/Prosthetics Fitter',
    168:'Osteopathic Physician (DO)',
    169:'Osteopathic Physician Resident (DO)',
    260:'Other General - Not Classified, Specify',
    209:'Paramedic',
    196:'Pastoral Counselor',
    256:'Perfusionist',
    215:'Pharmacist',
    217:'Pharmacy Assistant',
    216:'Pharmacy Intern',
    218:'Pharmacy Technician',
    231:'Physical Therapist',
    232:'Physical Therapy Assistant',
    166:'Physician (MD)',
    220:'Physician Assistant ',
    167:'Physician Resident (MD)',
    222:'Podiatric Assistant',
    221:'Podiatrist',
    193:'Professional Counselor',
    257:'Psychiatric Technician',
    226:'Psychological Assistant, Associate, Examiner',
    224:'Psychologist',
    246:'Radiation Therapy Technologist',
    247:'Radiologic Technician/Technologist',
    199:'Recreation Therapist',
    170:'Registered Nurse',
    233:'Rehabilitation Therapist',
    234:'Respiratory Therapist',
    235:'Respiratory Therapy Technician',
    225:'School Psychologist',
    195:'Sex Offender Counselor',
    237:'Social Worker',
    239:'Speech/Language Pathologist',
    243:'Surgical Technologist/Assistant'
  },
  TYPH_DATA_GENDERS: {
    U: 'UNKNOWN',
    M: 'MALE',
    F: 'FEMALE'
  },

  TYPH_DATA_JURISDICTIONS: {
    AL: 'ALABAMA',
    AK: 'ALASKA',
    AS: 'AMERICAN SAMOA',
    AZ: 'ARIZONA',
    AR: 'ARKANSAS',
    CA: 'CALIFORNIA',
    CO: 'COLORADO',
    CT: 'CONNECTICUT',
    DE: 'DELAWARE',
    DC: 'DISTRICT OF COLUMBIA',
    FM: 'FEDERATED STATES OF MICRONESIA',
    FL: 'FLORIDA',
    GA: 'GEORGIA',
    GU: 'GUAM GU',
    HI: 'HAWAII',
    ID: 'IDAHO',
    IL: 'ILLINOIS',
    IN: 'INDIANA',
    IA: 'IOWA',
    KS: 'KANSAS',
    KY: 'KENTUCKY',
    LA: 'LOUISIANA',
    ME: 'MAINE',
    MH: 'MARSHALL ISLANDS',
    MD: 'MARYLAND',
    MA: 'MASSACHUSETTS',
    MI: 'MICHIGAN',
    MN: 'MINNESOTA',
    MS: 'MISSISSIPPI',
    MO: 'MISSOURI',
    MT: 'MONTANA',
    NE: 'NEBRASKA',
    NV: 'NEVADA',
    NH: 'NEW HAMPSHIRE',
    NJ: 'NEW JERSEY',
    NM: 'NEW MEXICO',
    NY: 'NEW YORK',
    NC: 'NORTH CAROLINA',
    ND: 'NORTH DAKOTA',
    MP: 'NORTHERN MARIANA ISLANDS',
    OH: 'OHIO',
    OK: 'OKLAHOMA',
    OR: 'OREGON',
    PW: 'PALAU',
    PA: 'PENNSYLVANIA',
    PR: 'PUERTO RICO',
    RI: 'RHODE ISLAND',
    SC: 'SOUTH CAROLINA',
    SD: 'SOUTH DAKOTA',
    TN: 'TENNESSEE',
    TX: 'TEXAS',
    UT: 'UTAH',
    VT: 'VERMONT',
    VI: 'VIRGIN ISLANDS',
    VA: 'VIRGINIA',
    WA: 'WASHINGTON',
    WV: 'WEST VIRGINIA',
    WI: 'WISCONSIN',
    WY: 'WYOMING',
    US: 'UNITED STATES',
    IT: 'INTERNATIONAL'
  },

  TYPH_DATA_SOURCE_STATUSES: {
    ACTIVE: 'ACTIVE',
    HISTORICAL: 'HISTORICAL',
    ARCHIVED: 'ARCHIVED',
    'NOT REGULATED': 'NOT REGULATED'
  },

  TYPH_DATA_SOURCE_METHODS: {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL'
  },

  TYPH_DATA_SOURCE_FILE_TYPES: {
    INTERMEDIATE: 'INTERMEDIATE',
    TRANSLATABLE: 'TRANSLATABLE',
    REPAIR: 'REPAIR',
    REPAIRED: 'REPAIRED',
    STANDARDIZED: 'STANDARDIZED',
    DATAENTRY: 'DATAENTRY',
    POSTPONED: 'POSTPONED',
    OTHER: 'OTHER'
  },

  TYPH_DATA_SOURCE_FILE_STATUSES: {
    'IN-PROCESS': 'IN-PROCESS',
    'READY LOAD': 'READY LOAD',
    'NO NEW RECORDS': 'NO NEW RECORDS',
    LOADED: 'LOADED',
    TRANSLATED: 'TRANSLATED',
    REJECTED: 'REJECTED',
    ABANDONED: 'ABANDONED'
  },

  TYPH_DATA_USER_TYPES: {
    ADMIN: 'ADMIN',
    ADVANCED: 'ADVANCED',
    BILLING: 'BILLING',
    STANDARD: 'STANDARD',
    'TEAM MEMBER': 'TEAM MEMBER',
    XML: 'XML'
  },

  TYPH_DATA_SOURCE_FILE_HISTORY_ACTIONS: {
    ERROR: 'ERROR',
    COMMENT: 'COMMENT',
    'SENT-QC': 'SENT-QC',
    'QC-FAILED': 'QC-FAILED',
    'QC-PASSED': 'QC-PASSED',
    LOADED: 'LOADED'
  },

  TYPH_DATA_BATCH_ACTION_TYPE_OPTIONS: {
    ADD: 'ADD subjects in file to group',
    REMOVE: 'REMOVE subjects in file from group',
    REPLACE: 'REPLACE subjects in group with file (adds and deletes)'
  },

  TYPH_DATA_DIGEST_TYPES: {
    D: 'DAILY',
    W: 'WEEKLY',
    M: 'MONTHLY'
  },

  TYPH_DATA_NOTIFICATION_LEVELS: {
    NONE: 'NONE',
    ALL: 'ALL',
    LICENSE: 'LICENSE',
    VERIFICATION: 'VERIFICATIONS'
  },

  TYPH_DATA_INPUT_TYPES: {
    S: 'Single',
    B: 'Batch'
  },
  TYPH_DATA_INPUT_TYPES_LOOKUP: {
    single: 'S',
    batch: 'B'
  },
  TYPH_DATA_SUBJECT_TYPES: {
    I: 'Individual',
    O: 'Organization'
  },
  TYPH_DATA_SUBJECT_TYPES_ORG_ONLY: {
    O: 'Organization'
  },
  TYPH_DATA_SUBJECT_TYPES_LOOKUP: {
    individual: 'I',
    organization: 'O'
  },

  TYPH_DATA_SUBJECT_STATUSES: {
    ALL: 'ALL',
    ENROLLED: 'ENROLLED',
    REMOVED: 'REMOVED'
  },

  TYPH_DATA_LIC_STATUSES: {
    '': '',
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    INACTIVE: 'INACTIVE',
    'NOT FOUND': 'NOT FOUND',
    ' ': '--------',
    'BOARD PENDING': 'BOARD PENDING',
    // 'FEE :EQUIRED', 'FEE REQUIRED',
    // 'NEED :ORE INFO', 'NEED MORE INFO',
    PROBATION: 'PROBATION',
    RESTRICTED: 'RESTRICTED',
    REVOKED: 'REVOKED',
    SURRENDERED: 'SURRENDERED',
    SUSPENDED: 'SUSPENDED',
    TEMPORARY: 'TEMPORARY'
  },

  TYPH_DATA_VERIFIED_CATEGORIES: {
    '': '',
    'CURRENT-FEDERAL': 'CURRENT-FEDERAL',
    'CURRENT-STATE': 'CURRENT-STATE',
    'CURRENT-PROHIBITION': 'CURRENT-PROHIBITION',
    'CURRENT-IMPAIRMENT': 'CURRENT-IMPAIRMENT',
    HISTORICAL: 'HISTORICAL',
    'HISTORICAL-OVER7': 'HISTORICAL-OVER7',
    NONADVERSE: 'NONADVERSE'
  },

  TYPH_DATA_VERIFIED_METHODS: {
    '': '',
    'PERSONAL IDENTIFER': 'PERSONAL IDENTIFIER',
    'LICENSE NUMBER': 'LICENSE NUMBER',
    NAME: 'NAME',
    GENDER: 'GENDER',
    AGE: 'AGE',
    PICTURE: 'PICTURE',
    'ADDRESS HISTORY': 'ADDRESS HISTORY',
    'N/A': 'N/A'
  },

  TYPH_DATA_VERIFIED_RESULTS: {
    '': '',
    MATCH: 'MATCH',
    INVALID: 'INVALID',
    INDETERMINANT: 'INDETERMINANT',
    DUPLICATE: 'DUPLICATE'
  },
  TYPH_DATA_VERIFIED_RESULTS_ARRAY: ['', 'MATCH', 'INVALID', 'INDETERMINANT', 'DUPLICATE'],
  TYPH_DATA_VERIFIED_CATEGORIES_ARRAY: ['', 'CURRENT-FEDERAL', 'CURRENT-STATE', 'CURRENT-PROHIBITION', 'CURRENT-IMPAIRMENT', 'HISTORICAL', 'HISTORICAL-OVER7', 'NONADVERSE'],
  TYPH_DATA_VERIFIED_METHODS_ARRAY: ['', 'NAME', 'ADDRESS HISTORY', 'PERSONAL IDENTIFER', 'AGE', 'LICENSE NUMBER', 'GENDER', 'PICTURE', 'N/A'],
  TYPH_DATA_PROGRESS_STATUSES: {
    '': '',
    COMPLETE: 'COMPLETE',
    LAST_UPDATED: 'UPDATE',
    CANCELED: 'CANCEL',
    ' ': '----REQUEST INFO----',
    REQUESTED_INFO_DATA_ENTRY_ERROR: 'DATA ENTRY ERROR',
    REQUESTED_INFO_MISSING_PROVIDER_INFO: 'MISSING PROVIDER INFO',
    'REQUESTED INFO_MISSING_SOURCE_INFO': 'MISSING SOURCE INFO',
    '': '----DELAY----',
    DELAYED_FEE_REQUIRED: 'FEE REQUIRED',
    DELAYED_LIMITED_SOURCE_ACCESS: 'LIMITED SOURCE ACCESS',
    DELAYED_SOURCE_UNAVAILABLE: 'SOURCE UNAVAILABLE',
    DELAYED_WAITING_ON_SOURCE: 'WAITING ON SOURCE'
  },

  TYPH_DATA_STIPULATIONS: {
    '': 'UNKNOWN',
    '2': 'NO',
    '1': 'YES'
  }
}
export const getters = {
  verifiedOptions: state => {
    return state.TYPH_DATA_VERIFIED_RESULTS_ARRAY
  },
  verifiedCategoriesOptions: state => {
    return state.TYPH_DATA_VERIFIED_CATEGORIES_ARRAY
  },
  verifiedMethodsOptions: state => {
    return state.TYPH_DATA_VERIFIED_METHODS_ARRAY
  }
}
