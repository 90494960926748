import CoreService from '@/services/shared/CoreService.js'

export const namespaced = true

export const state = {
  percentCompleted: 0
}

export const mutations = {}

export const actions = {
  getBatchFile({}, { monitoring, batchId }) {
    let url = 'transactional/get_batch_file/'
    if (monitoring) url = 'monitoring/get_batch_file/'
    CoreService.downloadFile({ url: url, fileOptions: { batch_id: +batchId }, filename: 'file' + batchId + '.csv' })
  },
  getBatchReport({}, { batchId }) {
    let url = 'transactional/get_batch_report/'
    CoreService.downloadFile({ url: url, fileOptions: { batch_id: +batchId }, filename: 'file' + batchId + '.xlsx' })
  },
  getReport({}, { reportOptions }) {
    let url = 'monitoring/get_report/'
    let filename = 'report';
    // Check if it's a license report and withImages is true
    if ((reportOptions.report_type === 'license' ||
         reportOptions.report_type === 'license_next' ||
         reportOptions.report_type === 'license_expiration' ||
         reportOptions.report_type === 'alert') &&
         reportOptions.with_images == 'True') { 
      filename += '.zip';
    } else {
      filename += '.xls';
    }
  
    CoreService.downloadFile({ url: url, fileOptions: reportOptions, filename: filename })
  },
  uploadActionDoc({ state }, { options }) {
    state.percentCompleted = 0
    return BryceService.send({ method: 'uploadActionDoc', errorMessage: 'uploading file', params: { fd: options.fd } }).then(results => {
      if (results) return results.staged_file_id
    })
  }
}

export const getters = {
  percentUploaded: state => {
    return state.percentCompleted
  }
}
