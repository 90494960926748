import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {
  orderLogsTotal: 0,
  orderLogs: []
}

export const mutations = {
  SET_ORDER_LOGS(state, orderLogs) {
    state.orderLogs = orderLogs
  },
  SET_ORDER_LOG_COUNT(state, count) {
    state.orderLogsTotal = count
  }
}

export const actions = {
  fetchOrderLogs({ commit }, { perPage, page }) {
    return BryceService.send({ method: 'getOrderLogs', errorMessage: 'fetching orderLogs', params: { perPage: perPage, page: page } }).then(results => {
      if (results) {
        commit('SET_ORDER_LOGS', results.data)
        commit('SET_ORDER_LOG_COUNT', results.data.length)
      }
    })
  }
}
