<template>
  <div>
    <ProviderForm :frequency="'one-time'" :showFrequency="false" />
  </div>
</template>

<script>
import ProviderForm from '@/components/bryce/ProviderForm.vue'

export default {
  components: {
    ProviderForm
  }
}
</script>

<style scoped></style>
