<template>
  <button type="submit" :class="brandName" class="button is-block is-medium is-fullwidth is-primary">{{ label }}</button>
</template>

<script>
import { mapState } from 'vuex'

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('layout', ['brandName'])
  },
  methods: {}
}
</script>

<style scoped>
/* .hds,
.iqvia,
.sjv {
  background-color: #2a4a8e;
  border-color: transparent;
  color: #fff;
}
.typhoon {
  background-color: #005442;
  border-color: transparent;
  color: #fff;
} */
</style>
