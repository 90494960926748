<template>
  <b-field label="Address Zip" :type="fieldType">
    <div class="control has-icons-right is-clearfix" style="margin:0px;padding:0px">
      <Cleave v-model="zipValue" placeholder="nnnnn" :class="inputClass" :options="optionsZip" :raw="false" v-bind="$attrs"></Cleave>
      <span v-if="isRequired">
        <span v-if="isValidZip()" class="icon is-right has-text-success"><i class="mdi mdi-check mdi-24px"></i></span>
        <span v-else class="icon is-right has-text-danger"><i class="mdi mdi-alert-circle mdi-24px"></i></span>
      </span>
    </div>
  </b-field>
</template>

<script>
import Cleave from 'vue-cleave-component'
export default {
  inheritAttrs: false,
  props: {
    isRequired: {
      type: Boolean,
      default: false
    },
    monitoring: {
      type: Boolean,
      default: false
    },
    zip: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      zipValue: this.zip,
      optionsZip: {
        numericOnly: true,
        blocks: [5]
        // delimiter: '-',
        // blocks: [5, 4]
      }
    }
  },
  components: {
    Cleave
  },
  watch: {
    zip(newValue) {
      this.zipValue = newValue
    }
  },
  computed: {
    fieldType() {
      if (this.zipValue.length > 0) {
        return this.isValidZip() ? 'is-success' : 'is-danger'
      } else {
        return ''
      }
    },
    inputClass() {
      if (this.zipValue.length > 0) {
        return this.isValidZip() ? 'input is-success' : 'input is-danger'
      } else {
        return 'input'
      }
    }
  },
  methods: {
    isValidZip() {
      this.$emit('update:zip', this.zipValue)
      if (this.isRequired && this.zipValue.length == 0) return false
      if (this.zipValue.length < 5) return false
      return true
    }
  }
}
</script>

<style scoped></style>
