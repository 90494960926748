import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const RESULT_LIST_COLUMNS = [
  { field: 'id', label: 'Alert ID', display: true },
  { field: 'customer_id', label: 'Customer ID', display: false },
  { field: 'created_at', label: 'Date', display: true },
  { field: 'subject_id', label: 'Provider ID', display: false },
  { field: 'group', label: 'Group', display: true },
  { field: 'name', label: 'Name', display: true },
  { field: 'reference', label: 'Reference', display: false },
  { field: 'source', label: 'Source', display: true },
  { field: 'alertLevel', label: 'Level', display: true },
  { field: 'detail', label: 'Short Description', display: true },
  { field: 'actions', label: 'Actions', display: true }
]
export const ROSTER_LIST_COLUMNS = [
  { field: 'id', label: 'Provider ID', display: true },
  { field: 'customer_id', label: 'Customer ID', display: false },
  { field: 'subject_created_at', label: 'Provider Date', display: true },
  { field: 'name', label: 'Name', display: true },
  { field: 'reference', label: 'Reference', display: true },
  { field: 'group', label: 'Group', display: true },
  { field: 'source', label: 'Source(s)', display: false },
  { field: 'created_at', label: 'Results Date', display: false },
  { field: 'alertLevel', label: 'Level', display: true },
  { field: 'actions', label: 'Actions', display: true }
]

export const state = {
  levels: {},
  labels: {},
  announcements: {
    icon: 'information-outline',
    messages: [],
    tracking: []
  },
  menuSettings: { position: 'top' },
  resultsListColumns: RESULT_LIST_COLUMNS,
  rosterListColumns: ROSTER_LIST_COLUMNS,
  resultsListOptions: {},
  rosterListOptions: {},
  userProfile: {}
}

export const mutations = {
  MARK_AS_READ(state, options) {
    state.announcements.tracking.push(options.id)
    let i = state.announcements.messages.findIndex(message => message.id == options.id)
    state.announcements.messages.splice(i, 1)
  },
  RESET_SETTINGS(state) {
    state.labels = {
      NonAdverse: 'Non-Adverse',
      NoResultsFound: 'No Results Found',
      Pending: 'Pending',
      ReadUnRead: 'ack',
      ReviewRequired: 'Review Required',
      VerifiedImpairment: 'Verified Impairment'
    }
    state.levels = {
      NonAdverse: true,
      NoResultsFound: true,
      Pending: true,
      ReadUnRead: true,
      ReviewRequired: true,
      VerifiedImpairment: true
    }
    if (state.menuSettings) {
    } else {
      state.menuSettings = { position: 'top' }
    }
    state.resultsListOptions = {
      quickSearch: false
    }
    state.resultsListColumns = RESULT_LIST_COLUMNS
    state.rosterListOptions = {
      frequency: null,
      quickSearch: false
    }
    state.rosterListColumns = ROSTER_LIST_COLUMNS
  },
  SET_USER_PROFILE(state, userProfile) {
    state.userProfile = {
      digest_type: userProfile.digest_type,
      digest_level: userProfile.digest_level,
      notify_level: userProfile.notify_level,
      include_historical_exclusions: userProfile.include_historical_exclusions
    }
    if (userProfile.bryce) {
      let settings = JSON.parse(userProfile.bryce)
      if (settings.menuSettings) state.menuSettings.position = settings.menuSettings.position
      // state.labels = settings.levels.labels
      // state.levels = settings.levels.display
      // state.resultsListColumns = { ...settings.resultsList.columns, ...RESULT_LIST_COLUMNS }
      // state.resultsListOptions = settings.resultsList.options
      // state.rosterListColumns = { ...settings.rosterList.columns, ...ROSTER_LIST_COLUMNS }
      // state.rosterListOptions = settings.rosterList.options
      state.announcements.tracking = settings.messageTracking
    }
  }
}

export const actions = {
  fetch({ commit, state }) {
    BryceService.send({ method: 'getUserProfile', errorMessage: 'getting user profile', params: {} }).then(results => {
      if (results) {
        commit('RESET_SETTINGS')
        commit('SET_USER_PROFILE', results.data)
      }
      // let msgTracking = localStorage.getItem('messageTracking')
      // if (msgTracking) {
      //   let msgTrackingJson = JSON.parse(msgTracking)
      //   state.announcements.tracking = msgTrackingJson
      // } else {
      //   state.announcements.tracking = []
      //   localStorage.removeItem('messageTracking')
      // }
      let idToAdd = 2
      let indexTracking = state.announcements.tracking.findIndex(id => id == idToAdd)
      let indexMessages = state.announcements.messages.findIndex(message => message.id == idToAdd)
      if (indexTracking < 0 && indexMessages < 0) {
        // state.announcements.messages.push({
        //   id: idToAdd,
        //   title: 'More Updates',
        //   body: "You can now opt for a more familiar layout - see 'Menu' in Profile and '<' icon. We now export into multiple formats and use more familiar terms.",
        //   type: 'is-info'
        // })
      }
      // let setting = localStorage.getItem('settings')
      // if (setting) {
      //   let settingJson = JSON.parse(setting)
      //   state.levels.labels = settingJson.labels
      //   state.levels.display = settingJson.display
      //   state.resultsList.columns = { ...settingJson.resultsListColumns, ...RESULT_LIST_COLUMNS }
      //   state.resultsList.options = settingJson.resultsListOptions
      //   state.rosterList.columns = { ...settingJson.rosterListColumns, ...ROSTER_LIST_COLUMNS }
      //   state.rosterList.options = settingJson.rosterListOptions
      //   localStorage.removeItem('settings')
      // }
      // let filters = localStorage.getItem('filters')
      // if (filters) {
      //   let filtersJson = JSON.parse(filters)
      //   localStorage.removeItem('filters')
      // }
    })
  },
  markAnnouncementAsRead({ commit }, { options }) {
    commit('MARK_AS_READ', { id: options.id })
  },
  resetSettings({ commit, dispatch }) {
    commit('RESET_SETTINGS')
    dispatch('save')
  },
  setMenuTop({ state }) {
    state.menuSettings.position = 'top'
  },
  setMenuSide({ state }) {
    state.menuSettings.position = 'side'
  },
  save({ state }) {
    BryceService.send({
      method: 'updateUserProfile',
      errorMessage: 'updating user profile',
      params: {
        id: state.userProfile.id,
        digest_type: state.userProfile.digest_type,
        digest_level: state.userProfile.digest_level,
        notify_level: state.userProfile.notify_level,
        include_historical_exclusions: state.userProfile.include_historical_exclusions,
        bryce: JSON.stringify({
          levels: { display: state.levels, labels: state.labels },
          menuSettings: state.menuSettings,
          resultsList: { columns: state.resultsListColumns, options: state.resultsListOptions },
          rosterList: { columns: state.rosterListColumns, options: state.rosterListOptions },
          messageTracking: state.announcements.tracking
        })
      }
    }).then(results => {
      if (results) {
        // console.log(results)
      }
    })
  }
}

export const getters = {
  profileReady: state => {
    if (state.labels && state.labels.VerifiedImpairment) return true
    else return false
  },
  // For now (state, getters) => must include 'state' too
  labelRead: (state, getters) => {
    return getters.useAckButtons ? 'Acknowledged' : 'Read'
  },
  labelUnRead: (state, getters) => {
    return getters.useAckButtons ? 'Un-Acknowledged' : 'Un-Read'
  },
  labelBtnRead: (state, getters) => {
    return getters.useAckButtons ? 'ACK' : ''
  },
  labelBtnUnRead: (state, getters) => {
    return getters.useAckButtons ? 'UNACK' : ''
  },
  menuPosition: state => {
    return state.menuSettings.position
  },
  resultsColumns: state => {
    return state.resultsListColumns
  },
  resultsOptions: state => {
    return state.resultsListOptions
  },
  rosterColumns: state => {
    return state.rosterListColumns
  },
  rosterOptions: state => {
    return state.rosterListOptions
  },
  useAckButtons: state => {
    return state.labels.ReadUnRead == '' ? false : true
  }
}
