<template>
  <div>
    <div class="columns">
      <div class="column">
        <table class="table table-bordered">
          <tr>
            <th>ID</th>
            <td><span v-if="monitoring">M</span>{{ batch.batch_id }}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{{ batch.name }}</td>
          </tr>
          <tr>
            <th>Submitted By</th>
            <td>{{ batch.submitted_by }}</td>
          </tr>
          <tr v-if="intuitiveUI">
            <th>{{ intuitiveUI ? 'Submitted At' : 'Created At' }}</th>
            <td>{{ batch.created_at | shortNoTz }}</td>
          </tr>
          <tr v-if="!monitoring">
            <th>{{ intuitiveUI ? 'Record Count' : 'Providers' }}</th>
            <td>{{ batch.record_count }}</td>
          </tr>
          <tr v-if="!monitoring">
            <th>Completed At</th>
            <td>{{ batch.completed_at | shortNoTz }}</td>
          </tr>
          <tr v-if="batch.products && batch.products.length > 0">
            <th>Products</th>
            <td>{{ batch.products.join(' | ') }}</td>
          </tr>
          <tr v-if="batch.new_products && batch.new_products.length > 0">
            <th>Products</th>
            <td>{{ batch.new_products.join(' | ') }}</td>
          </tr>
          <tr>
            <th>Batch Status</th>
            <td>{{ batch.status }}</td>
          </tr>
          <tr v-if="monitoring">
            <th>Group</th>
            <td>{{ batch.group }}</td>
          </tr>
          <tr>
            <th>Group Status</th>
            <td>{{ batch.group_status }}</td>
          </tr>
          <tr>
            <th>{{ intuitiveUI ? 'Subject Type' : 'Provider Type' }}</th>
            <td>{{ batch.subject_type == 'I' ? 'Individual' : 'Organization' }}</td>
          </tr>
          <tr v-if="monitoring">
            <th>Action Type</th>
            <td>{{ batch.action_type }}</td>
          </tr>
        </table>
      </div>
      <div class="column">
        <table class="table table-bordered">
          <tr v-if="monitoring">
            <th>Added</th>
            <td>{{ batch.records_added }}</td>
          </tr>
          <tr v-if="monitoring">
            <th>Enabled</th>
            <td>{{ batch.records_enabled }}</td>
          </tr>
          <tr v-if="monitoring">
            <th>Removed</th>
            <td>{{ batch.records_removed }}</td>
          </tr>
          <tr v-if="monitoring">
            <th>Updated</th>
            <td>{{ batch.records_updated }}</td>
          </tr>
          <tr v-if="hasAdmin">
            <th>Submitted Batch File</th>
            <td>
              <button type="button" @click="getBatchFile(batch.batch_id)" class="button is-default">Download</button>
            </td>
          </tr>
          <tr v-if="!monitoring && showXLSDownload">
            <th>Batch Results File (XLSX)</th>
            <td><button @click="getBatchReportFile(batch.batch_id)" class="button is-default">Download</button></td>
          </tr>
          <tr v-if="hasAdmin">
            <th>CustomerUserId</th>
            <td>{{ batch.customer_user_id }}</td>
          </tr>
          <tr v-if="batch.customer_id != auth_user.authUser.customer_id">
            <th>Customer ID</th>
            <td>{{ batch.customer_id }}</td>
          </tr>
          <tr v-if="batch.filter_state && batch.filter_state.length > 0">
            <th>Batch Filtered By</th>
            <td>{{ batch.filter_state }}</td>
          </tr>
        </table>
      </div>
      <div class="column">
        <table class="table table-bordered">
          <tr v-if="monitoring">
            <th>Group</th>
            <td>{{ batch.group }}</td>
          </tr>
          <tr v-if="hasAdmin && monitoring">
            <th>License Check</th>
            <td>{{ batch.include_license_check | yesNo }}</td>
          </tr>
          <tr v-if="hasAdmin && monitoring">
            <th>License Search</th>
            <td>{{ batch.include_license_search | yesNo }}</td>
          </tr>
          <tr v-if="hasAdmin && monitoring">
            <th>License Info</th>
            <td>{{ batch.include_license_info | yesNo }}</td>
          </tr>
          <tr v-if="hasAdmin && monitoring && !isFADV">
            <th>Tier</th>
            <td>{{ batch.tier }}</td>
          </tr>
          <tr v-if="hasAdmin && monitoring">
            <th>Verify</th>
            <td>{{ batch.verify | yesNo }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
// import TransactionalSubjectHistory from '@/components/TransactionalSubjectHistory.vue'

export default {
  props: {
    batchId: {
      required: true
    },
    batch: {
      type: Object,
      required: true
    },
    showXLSDownload: {
      type: Boolean,
      required: true
    },
    monitoring: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      historyResults: []
    }
  },
  computed: {
    ...mapGetters('layout', ['intuitiveUI', 'isFADV']),
    ...mapGetters('auth_user', ['currentUser', 'hasAdmin']),
    ...mapState(['auth_user'])
  },
  components: {},
  created() {
    // let frequency = ['one-time', 'monitoring']
    // if (this.monitoring) {
    //   frequency = ['monitoring']
    // } else {
    //   frequency = ['one-time']
    // }
    // this.$store
    //   .dispatch('provider/fetchProviders', {
    //     setState: false,
    //     searchOptions: {
    //       batch_id: +this.batchId,
    //       frequency: frequency
    //     }
    //   })
    //   .then(historyResults => {
    //     this.historyResults = historyResults
    //   })
  },
  methods: {
    getBatchFile(batchId) {
      // console.log(batchId)
      this.$store.dispatch('file/getBatchFile', { batchId: batchId, monitoring: this.monitoring }).then(() => {})
    },
    getBatchReportFile(batchId) {
      this.$store.dispatch('file/getBatchReport', { batchId: batchId }).then(() => {})
    },
    gotoResults(batchId) {
      this.$router.push({
        name: 'provider-results',
        query: {
          batchId: batchId
        }
      })
    }
  }
}
</script>

<style scoped></style>
