import BryceService from '@/services/BryceService.js'
import { getProductOption } from '@/helpers/product_helpers'

export const namespaced = true

export const state = {
  product_orders: [],
  hasLicenseProduct: false,
  subjectId: null
}

export const mutations = {
  INIT_PRODUCT_ORDERS(state) {
    state.product_orders = []
  },
  PUSH_BLANK_PRODUCT_ORDER(state) {
    let newIndex = state.product_orders.length
    if (state.product_orders.length > 0) +state.product_orders[state.product_orders.length - 1].index + 1
    state.product_orders.push({
      name: 'License',
      server_name: 'License',
      id: null,
      monitoring_product_id: 0,
      monitoring: false,
      subject_id: state.subjectId,
      detailHidden: true,
      license_number: null,
      license_type: null,
      license_state: null,
      license_multistate: null,
      index: newIndex
    })
  },
  REMOVE_PRODUCT_ORDER(state, index) {
    let i = state.product_orders.findIndex(po => po.index == index)
    state.product_orders.splice(i, 1)
  },
  SET_PRODUCT_ORDER_ID(state, payload) {
    state.product_orders.filter(po => po.index == payload.index)[0].id = payload.id
  },
  SET_PRODUCT_ORDERS(state, options) {
    state.product_orders = []
    state.hasLicenseProduct = false
    for (let i in options.products) {
      let product = options.products[i]
      // console.log(product)
      product.server_name = product.name
      if (product.server_name.indexOf('License') > -1) state.hasLicenseProduct = true
      product.name = getProductOption(options.layout, product.product_id, product.name).name
      product.detailHidden = false
      product.index = i
      // console.log(product.server_name)
      // console.log(product.name)
      state.product_orders.push(product)
    }
  },
  SET_SUBJECT_ID(state, subjectId) {
    state.subjectId = subjectId
  }
}

export const actions = {
  fetchProductOrders({ commit, rootState }, { subjectId }) {
    commit('SET_SUBJECT_ID', subjectId)
    return BryceService.send({ method: 'getMonitorProductOrders', errorMessage: 'fetching product orders', params: { subjectId: subjectId } }).then(results => {
      if (results) {
        commit('SET_PRODUCT_ORDERS', { layout: rootState.layout, products: results.data || [] })
        // console.log(results.data)
        return results.data || []
      }
    })
  },
  addLicenseOrder({ commit, getters }, { index, subjectId, monitoring, info_only }) {
    let order = getters.getLicenseOrder(index)
    order.subject_id = subjectId
    order.monitoring = monitoring
    order.info_only = info_only
    return BryceService.send({ method: 'addLicenseOrder', errorMessage: 'adding product orders', params: { order: order } }).then(results => {
      if (results) {
        commit('SET_PRODUCT_ORDER_ID', { index: order.index, id: results.monitoring_product_id })
        return results.monitoring_product_id
      }
    })
  },
  addSearchOrder({}, { searchProduct }) {
    return BryceService.send({ method: 'addSearchOrder', errorMessage: 'adding product order', params: searchProduct }).then(results => {
      if (results) {
        if (searchProduct.monitoring) return results.monitoring_product_id
        else return results.order_id
      }
    })
  },
  removeLicenseOrder({ commit, getters }, { index }) {
    let order = getters.getLicenseOrder(index)
    commit('REMOVE_PRODUCT_ORDER', index)
    if (!order.id) return
    return BryceService.send({ method: 'removeLicenseOrder', errorMessage: 'removing product order', params: { subjectId: order.monitoring_subject_id, monitoringProductId: order.id } }).then(() => {
      return index
    })
  },
  initProductOrders({ commit }) {
    commit('INIT_PRODUCT_ORDERS')
  },
  pushProductOrder({ commit }) {
    commit('PUSH_BLANK_PRODUCT_ORDER')
  }
}

export const getters = {
  getLicenseOrder: state => index => {
    return state.product_orders.filter(po => po.index == index)[0]
  },
  licenseOrders: state => {
    return state.product_orders.filter(po => po.server_name && po.server_name.indexOf('License') > -1)
  },
  nonLicenseOrders: state => {
    return state.product_orders.filter(po => po.server_name && po.server_name.indexOf('License') == -1)
  }
}
