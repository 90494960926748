import CoreService from '@/services/shared/CoreService.js'

export const namespaced = true

export const state = {
  authUser: {},
  message: null,
  proxyStatus: '',
  revertUser: {},
  revertToken: localStorage.getItem('user-revert-token') || '',
  status: '',
  token: localStorage.getItem('user-token') || ''
}

export const mutations = {
  SET_AUTH_USER(state, user) {
    state.authUser = user
  },
  AUTH_ERROR: state => {
    state.status = 'error'
    state.token = ''
    localStorage.removeItem('user-token')
  },
  AUTH_EXPIRE: state => {
    state.status = 'logout'
    state.message = null
    state.token = ''
    state.revertToken = ''
    state.revertUser = {}
    localStorage.removeItem('user-token')
    localStorage.removeItem('user-revert-token')
    CoreService.removeToken()
  },
  AUTH_FAIL: (state, message) => {
    state.status = 'failed'
    state.message = message
    state.token = ''
    localStorage.removeItem('user-token')
  },
  AUTH_REQUEST: state => {
    state.message = null
    state.status = 'loading'
  },
  AUTH_SUCCESS: (state, authOptions) => {
    state.message = null
    state.status = 'success'
    state.token = authOptions.token_id
    state.revertToken = ''
    state.revertUser = {}
    localStorage.setItem('user-token', state.token)
    localStorage.removeItem('user-revert-token')
    CoreService.setToken(state.token)
    state.authUser = authOptions.user
  },
  PROXY_LOGOUT: state => {
    state.proxyStatus = 'logout'
    state.token = state.revertToken
    state.authUser = JSON.parse(JSON.stringify(state.revertUser))
    CoreService.setToken(state.token)
    localStorage.setItem('user-token', state.token)
    localStorage.setItem('user-revert-token', '')
    state.revertToken = ''
    state.revertUser = {}
  },
  PROXY_SUCCESS: (state, authOptions) => {
    state.proxyStatus = 'success'
    state.revertToken = authOptions.revert_token_id
    localStorage.setItem('user-revert-token', state.revertToken)
    state.revertUser = state.authUser
    state.token = authOptions.token_id
    localStorage.setItem('user-token', state.token)
    CoreService.setToken(state.token)
    state.authUser = authOptions.user
  }
}

export const actions = {
  fetchAuthUser({ commit }) {
    CoreService.send({ method: 'getAuthValid', errorMessage: 'fetching auth info', params: {} }).then(results => {
      if (results) commit('SET_AUTH_USER', results.user)
      // console.log(results)
    })
  },
  authLogin({ commit, dispatch }, { username, password }) {
    return new Promise((resolve, reject) => {
      CoreService.getAuthUser(username, password)
        .then(response => {
          if (response && response.data) {
            if (response.data.status == 'OK') {
              commit('AUTH_SUCCESS', response.data)
              resolve(response)
            } else {
              commit('AUTH_FAIL', response && response.data && response.data.message)
            }
          }
        })
        .catch(error => {
          commit('AUTH_ERROR', error)
          const notification = { type: 'error', message: 'There was an error fetching user: ' + error.message }
          dispatch('notification/add', notification, { root: true })
          reject(error)
        })
    })
  },
  authExpire({ commit }, {}) {
    commit('AUTH_EXPIRE')
    return
  },
  authLogout({ commit }, {}) {
    CoreService.logout()
    commit('AUTH_EXPIRE')
    return
  },
  proxyLogout({ commit }, {}) {
    return CoreService.logout().then(() => {
      commit('PROXY_LOGOUT')
    })
  },
  proxyLogin({ commit }, { customer_user_id }) {
    return CoreService.send({ method: 'proxyLogin', errorMessage: 'with auth proxy', params: { imitate_customer_user_id: customer_user_id } }).then(result => {
      commit('PROXY_SUCCESS', result)
    })
  },
  confirmPassword({}, { passwordResetToken, password }) {
    return CoreService.send({
      method: 'confirmPassword',
      errorMessage: 'with auth proxy',
      params: {
        password_reset_token: passwordResetToken,
        new_password: password
      }
    }).then(result => {
      return result
    })
  },
  rescuePassword({ dispatch }, { username }) {
    // let domainParts = 'testportal.typhoonapp.com'.split('.')
    let domainParts = window.location.host.split('.')
    let subdomain = domainParts.shift()
    let domain = domainParts.join('.')
    return CoreService.rescuePassword(username, subdomain, domain)
      .then(() => {
        return
      })
      .catch(error => {
        const notification = { type: 'error', message: 'There was an error rescuing password: ' + error.message }
        dispatch('notification/add', notification, { root: true })
      })
  }
}

export const getters = {
  authStatus: state => {
    return state.status
  },
  currentUser: state => {
    return state.authUser
  },
  errorMessage: state => {
    return state.message
  },
  isTDStaff: state => {
    if (state.authUser && (state.authUser.is_super || state.authUser.is_staff)) return true
    else return false
  },
  hasAdmin: (state, getters) => {
    // state.authUser.role == 'ADMIN' are resellers!
    if (state.authUser && (state.authUser.role == 'ADMIN' || getters.isTDStaff)) return true
    else return false
  },
  hasReseller: (state, getters) => {
    if (state.authUser && state.authUser.reseller_id && getters.hasAdmin) return true
    else return false
  },
  isAuthenticated: state => {
    return !!state.token
  },
  isProxy: state => {
    return !!state.revertToken
  }
}
