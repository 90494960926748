<template>
  <div>
    <Reports />
  </div>
</template>

<script>
import Reports from '@/components/bryce/Reports.vue'

export default {
  components: {
    Reports
  }
}
</script>

<style scoped></style>
