import BryceService from '@/services/BryceService.js'
import { getProductOption } from '@/helpers/product_helpers'

export const namespaced = true

export const state = {
  isLoading: false,
  isNetworking: 0,
  list: [],
  percentCompleted: 0
}

export const mutations = {
  ADD_MONITOR_RESULT(state, result) {
    if (!result.disabled_at) result.disabled_at = null
    state.batchMonitorResults.push(result)
  },
  APPEND_MONITORING_TO_LIST(state, results) {
    let index = state.list.length
    for (let i in results) {
      let item = results[i]
      if (!item.disabled_at) item.disabled_at = null
      // if (item.status == 'LOADED') item.group_status = 'Loaded'
      if (+item.count_not_done > 0) item.group_status = 'In Progress'
      else item.group_status = 'Complete'
      item.isMonitoring = true
      item.index = index
      state.list.push(item)
      index++
    }
  },
  APPEND_TRANSACTIONAL_TO_LIST(state, results) {
    let index = state.list.length
    for (let i in results) {
      let item = results[i]
      if (!item.disabled_at) item.disabled_at = null
      // if (item.status == 'LOADED') item.group_status = 'Loaded'
      if (+item.count_not_done > 0) item.group_status = 'In Progress'
      else item.group_status = 'Complete'
      item.isMonitoring = false
      item.index = index
      state.list.push(item)
      index++
    }
  },
  CLEAR_LIST(state) {
    state.list = []
  },
  NETWORKING_STARTED(state) {
    state.isNetworking += 1
  },
  NETWORKING_FINISHED(state) {
    state.isNetworking -= 1
  }
}

export const actions = {
  fetchBatch({ rootState, getters }, { monitoring, batchId }) {
    var batch = getters.getBatchById(monitoring, batchId)
    if (batch) {
      return batch
    } else {
      return BryceService.send({ method: 'getBatchInfo', errorMessage: 'fetching batch', params: { monitoring: monitoring, searchOptions: { batch_id: +batchId } } }).then(results => {
        if (results) {
          let batch = results.data
          if (+batch.count_not_done > 0) batch.group_status = 'In Progress'
          else batch.group_status = 'Complete'
          batch.products = []
          for (let p in batch.product_ids) {
            let option = getProductOption(rootState.layout, batch.product_ids[p], '')
            batch.products.push(option.name)
          }
          // console.log(products)
          // batch.products = products.join(' | ')
          // console.log(batch.products)
          return batch
        }
      })
    }
  },
  clearList({ commit }) {
    commit('CLEAR_LIST', [])
  },
  fetchBatches({ commit }, { searchOptions }) {
    if (searchOptions.getMonitoring) {
      commit('NETWORKING_STARTED')
      BryceService.send({ method: 'getBatches', errorMessage: 'fetching batches', params: { monitoring: true, searchOptions: searchOptions } }).then(results => {
        if (results) commit('APPEND_MONITORING_TO_LIST', results.data)
        commit('NETWORKING_FINISHED')
      })
    }
    // console.log(searchOptions.group)
    if (searchOptions.getOnetime) {
      if (!searchOptions.group) {
        commit('NETWORKING_STARTED')
        BryceService.send({ method: 'getBatches', errorMessage: 'fetching batches', params: { monitoring: false, searchOptions: searchOptions } }).then(results => {
          if (results) {
            commit('APPEND_TRANSACTIONAL_TO_LIST', results.data)
          }
          commit('NETWORKING_FINISHED')
        })
      }
    }
  },
  stageFile({ state }, { fd }) {
    state.percentCompleted = 0
    return BryceService.send({ method: 'stageBatchFile', errorMessage: 'staging file', params: { fd: fd } }).then(results => {
      if (results) return results.staged_file_id
    })
  },
  validateBatchFile({}, { batchProduct, monitoring }) {
    return BryceService.send({ method: 'validateBatch', errorMessage: 'validating file', params: { batchProduct: batchProduct, monitoring: monitoring } }).then(results => {
      if (results) return results
    })
  },
  uploadBatch({}, { batchProduct, monitoring }) {
    return BryceService.send({ method: 'uploadBatch', errorMessage: 'uploading file', params: { batchProduct: batchProduct, monitoring: monitoring } }).then(results => {
      if (results) return results.batch_id
    })
  }
}
export const getters = {
  batchsCountTotal: state => {
    return state.list.length
  },
  getBatchById: state => batchId => {
    return state.list.find(batch => batch.batch_id === batchId)
  },
  isBusy: state => {
    return state.isLoading || state.isNetworking > 0
  }
}
