import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {
  userLogsTotal: 0,
  userLogs: []
}

export const mutations = {
  SET_USER_LOGS(state, userLogs) {
    state.userLogs = userLogs
  },
  SET_USER_LOG_COUNT(state, count) {
    state.userLogsTotal = count
  }
}

export const actions = {
  fetchUserLogs({ commit }) {
    BryceService.send({ method: 'getUserLogs', errorMessage: 'getting user logs', params: {} }).then(results => {
      if (results) {
        commit('SET_USER_LOGS', results.data)
        commit('SET_USER_LOG_COUNT', results.data.length)
      }
    })
  }
}
