import CoreService from '@/services/shared/CoreService.js'
import BryceService from '@/services/BryceService.js'

export const namespaced = true

export const state = {
  customers: []
}

export const mutations = {
  ADD_RESULT(state, result) {
    state.customers.push(result)
  },
  SET_RESULTS(state, results) {
    state.customers = results
  }
}

export const actions = {
  fetchCustomer({ commit, getters }, { customer_id }) {
    var customer = getters.getCustomerById(customer_id)
    if (customer) {
      return customer
    } else {
      return BryceService.send({ method: 'getCustomer', errorMessage: 'fetching customers', params: { id: +customer_id } }).then(results => {
        if (results) {
          let customer = results.data
          commit('ADD_RESULT', customer)
          return customer
        }
      })
    }
  },
  fetchCustomers({ commit }, { searchOptions }) {
    return BryceService.send({ method: 'getCustomers', errorMessage: 'fetching customers', params: searchOptions }).then(results => {
      if (results) commit('SET_RESULTS', results.data)
    })
  },
  fetchCustomerProducts({}, { customerId }) {
    return BryceService.send({ method: 'getProductsCustomer', errorMessage: 'fetching products', params: { customerId: +customerId } }).then(results => {
      if (results) return results.data
    })
  },
  fetchCustomerUsers({}, { customerId }) {
    return CoreService.send({ method: 'getUsers', errorMessage: 'fetching products', params: { customerId: +customerId } }).then(results => {
      if (results) return results.data
    })
  },
  updateProductEnabled({}, { customerId, productId }) {
    return BryceService.send({ method: 'updateProductEnabled', errorMessage: 'fetching products', params: { customer_id: customerId, product_id: productId } }).then(() => {
      return true
    })
  },
  resetResults({ commit }) {
    commit('SET_RESULTS', [])
  }
}

export const getters = {
  getCustomerById: state => customerId => {
    return state.customers.find(customer => customer.id === customerId)
  }
}
