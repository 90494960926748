<template>
  <div>
    <TdPanel panelId="licenseOrdersOnly" panelTitle="Results" :panelSubTitle="licenseOrder.product_name" v-for="licenseOrder in licenseOrdersOnly" :key="licenseOrder.order_id">
      <ProviderOneTimeResultsLicenseList :licenseOrder="licenseOrder" :alertLevels="alertLevels" />
    </TdPanel>
    <TdPanel panelId="adverseOrdersOnly" panelTitle="Results" :panelSubTitle="subjectOrder.product_name" v-for="subjectOrder in adverseOrdersOnly" :key="subjectOrder.order_id">
      <ProviderOneTimeResultsAdverseList :subjectOrder="subjectOrder" :alertLevels="alertLevels" />
    </TdPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getProductOption } from '@/helpers/product_helpers'
import ProviderOneTimeResultsAdverseList from '@/components/bryce/ProviderOneTimeResultsAdverseList.vue'
import ProviderOneTimeResultsLicenseList from '@/components/bryce/ProviderOneTimeResultsLicenseList.vue'

export default {
  props: {
    subjectId: {
      required: false
    },
    subject: {
      required: false
    },
    alertLevels: {
      default: () => [],
      required: false
    }
  },
  computed: {
    adverseOrdersOnly() {
      return this.orders ? this.orders.filter(order => order.product_category.includes('Exclusion Search')) : []
    },
    licenseOrdersOnly() {
      return this.orders ? this.orders.filter(order => order.product_category.includes('License')) : []
    },
    ...mapGetters('auth_user', ['isTDStaff'])
  },
  data() {
    return {
      orders: [],

      defaultOpenedOrder: [],
      defaultSortDirection: 'desc',
      perPage: 20,
      selectedAdverseAction: null,
      selectedLicenseAction: null
    }
  },
  components: {
    ProviderOneTimeResultsAdverseList,
    ProviderOneTimeResultsLicenseList
  },
  created() {
    this.$store.dispatch('transactional/fetchSubjectOrders', { subjectId: this.subjectId }).then(orders => {
      let tiers = []
      for (let i in orders) {
        let product_option = getProductOption(this.$store.state.layout, orders[i].product_id, '')
        orders[i].product_option = product_option
        if (!orders[i].product_category.includes('License')) {
          if (!product_option || tiers.indexOf(product_option.tier) > -1) continue
          tiers.push(orders[i].product_option.tier)
        }
        this.orders.push(orders[i])
        orders[i].product_name = orders[i].product_option.name
      }
    })
  },
  methods: {}
}
</script>

<style scoped></style>
