<template>
  <div>
    <nav class="navbar is-black is-fixed-top" role="navigation" aria-label="main navigation"></nav>
    <section class="hero is-white is-fullheight signin">
      <div class="hero-body" :class="brandName">
        <div class="container" :class="brandName">
          <div class="columns is-centered">
            <div class="column is-5">
              <figure class="" style="">
                <img :src="logoImagePath" style="padding-left:80px;width:75%;height:75%" alt="Brand" />
              </figure>
              <div class="has-text-centered" style="padding:30px">
                <h3 class="title is-4" v-if="brandName == 'typhoon'" style="color: #FFF; text-transform: none;">RESET PASSWORD</h3>
                <h3 class="title is-4" v-if="brandName == 'hds'" style="color: #000; text-transform: none;">MEDICAL LICENSE MONITOR RESET PASSWORD</h3>
                <h3 class="title is-4" v-if="brandName == 'iqvia'" style="color: #000; text-transform: none;">MEDICAL LICENSE MONITOR RESET PASSWORD</h3>
                <h3 class="title is-4" v-if="brandName == 'sjv'" style="color: #000; text-transform: none;">Med<span style="color: red;">Ex</span> System </h3>
              </div>
              <router-view />
              <NotificationContainer />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NotificationContainer from '@/components/shared/NotificationContainer.vue'

export default {
  components: {
    NotificationContainer
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    logoImagePath() {
      return '/assets/images/' + this.$store.state.layout.brandName + '/logo_450.png'
    },
    ...mapState('layout', ['brandName'])
  },
  methods: {}
}
</script>

<style lang="scss">
.hds {
  background: white;
}
.iqvia {
  background: white;
}
.typhoon {
  background: transparent;
}
.sjv {
  background: white;
}
</style>
