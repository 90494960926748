import BryceService from '@/services/BryceService.js'
export const namespaced = true

export const state = {}

export const mutations = {}

export const actions = {
  fetchSubjectOrders({}, { subjectId }) {
    return BryceService.send({ method: 'getTransactionalSubjectOrders', errorMessage: 'fetching orders', params: { subject_id: +subjectId } }).then(results => {
      if (results) return results.data
    })
  },
  fetchAdverseActions({}, { orderId }) {
    return BryceService.send({ method: 'getTransactionalAdverseActions', errorMessage: 'fetching orders', params: { order_id: +orderId } }).then(results => {
      if (results) return results.data
    })
  },
  fetchAdverseAction({}, { searchResultId }) {
    return BryceService.send({ method: 'getTransactionalAdverseAction', errorMessage: 'fetching orders', params: { search_result_id: +searchResultId } }).then(results => {
      // console.log(results.data)
      if (results) return results.data
    })
  },
  fetchLicenseOrders({}, { orderId }) {
    return BryceService.send({ method: 'getTransactionalLicenseResults', errorMessage: 'fetching orders', params: { order_id: +orderId } }).then(results => {
      if (results) return results.license_data
    })
  },
  fetchLicenseOrder({}, { orderId }) {
    return BryceService.send({ method: 'getTransactionalLicenseResult', errorMessage: 'fetching orders', params: { order_id: +orderId } }).then(results => {
      if (results) return results.license_data
    })
  }
}

export const getters = {}
