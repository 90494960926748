<template>
  <div>
    <TdPanel panelId="batch_view" panelTitle="Batch">
      <BatchDetail :monitoring="monitoring" :batchId="id" :batch="batch" :showXLSDownload="false" />
    </TdPanel>
    <section v-if="isIqvia" class="box">
      <ProviderRosterList @refresh="onGetAll({})" :showMonitoring="monitoring" :showOnetime="!monitoring" :isBatch="true" />
    </section>
    <section v-else class="box">
      <ProviderResultsSearchBar v-if="true" :byDate="true" :byGroups="true" :byName="true" :byReference="true" :byCountLimit="true" />
      <b-tabs type="is-boxed">
        <b-tab-item label="by Provider" icon="format-list-bulleted-type">
          <ProviderRosterList @refresh="onGetAll({})" :showMonitoring="monitoring" :showOnetime="!monitoring" :isBatch="true" :overrideIntuitive="true" />
        </b-tab-item>
        <b-tab-item label="by Result" icon="format-list-bulleted-type">
          <ProviderResultsList @refresh="onGetAll({})" :showMonitoring="monitoring" :showOnetime="!monitoring" :isBatch="true" :overrideIntuitive="true" />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BatchDetail from '@/components/bryce/BatchDetail.vue'
import ProviderResultsList from '@/components/bryce/ProviderResultsList.vue'
import ProviderResultsSearchBar from '@/components/bryce/ProviderResultsSearchBar.vue'
import ProviderRosterList from '@/components/bryce/ProviderRosterList.vue'

export default {
  props: {
    id: {
      required: true
    },
    monitoring: {
      required: true
    }
  },
  data() {
    return {
      batch: {}
    }
  },
  computed: {
    ...mapGetters('layout', ['intuitiveUI', 'isIqvia']),
    ...mapState(['provider_result'])
  },
  components: {
    BatchDetail,
    ProviderResultsList,
    ProviderResultsSearchBar,
    ProviderRosterList
  },
  created() {
    this.$store.dispatch('user_profile/fetch', {})
  },
  mounted() {
    let fetchAction = 'Onetime'
    if (this.monitoring) {
      fetchAction = 'Monitoring'
    }
    if (this.$store.getters['layout/isIqvia']) {
      fetchAction += 'Roster'
    } else {
      fetchAction += 'Results'
    }
    this.$store.dispatch('provider_result/fetchInit', { options: { fetchAction: fetchAction, enableLocalFilters: true } }).then(() => {
      this.fetchAll()
    })
  },
  methods: {
    fetchAll() {
      this.$store.dispatch('batch/fetchBatch', { monitoring: this.monitoring, batchId: this.id }).then(batch => {
        this.batch = batch
        let searchOptions = {}
        if (this.monitoring) {
          // When we get enrollment tables working...this can be more meaningful
          searchOptions = {
            batch_group: this.batch.group,
            includeMonitoring: true
          }
        } else {
          searchOptions = {
            batch_id: +this.id,
            includeOnetime: true
          }
        }
        this.$store.dispatch('provider_result/fetchIt', { options: searchOptions })
      })
    },
    onGetAll() {
      this.fetchAll({})
    }
  }
}
</script>

<style scoped>
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #3498db;
  border-color: #3498db;
}
/* buefy BEGIN */
.button {
  white-space: normal;
  height: auto;
}
/* buefy END */

.btn-primary {
  border-color: #3498db;
}

.btn-primary:hover {
  background-color: #369ade;
  border-color: #3498db;
}

.btn-primary:active {
  background-color: #3498db;
  border-color: #3498db;
}

.btn-primary:visited {
  background-color: #3498db;
  border-color: #3498db;
}
</style>
