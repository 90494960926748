<template>
  <div class="control has-icons-right" :class="hasIconLeft ? 'has-icons-left' : ''">
    <input :placeholder="label ? label : ''" :class="inputClass" v-model="fieldValue" v-bind="$attrs" />
    <span v-if="required">
      <span v-if="isValid" class="icon is-right has-text-success"><i class="mdi mdi-check mdi-24px"></i></span>
      <span v-else class="icon is-right has-text-danger"><i class="mdi mdi-alert-circle mdi-24px"></i></span>
    </span>
    <div class v-if="required && (reqMaxLength || reqMinLength)">
      <div class="help is-danger" v-if="!isValid">Must have at least {{ reqMinLength }} characters.</div>
    </div>
    <div class v-if="!required && (reqMaxLength || reqMinLength)">
      <div class="help is-warning" v-if="!isValid">Must have at least {{ reqMinLength }} characters.</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    hasIconLeft: {
      type: Boolean,
      default: false
    },
    includeClass: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    reqMaxLength: [String, Number],
    reqMinLength: [String, Number],
    value: [String, Number]
  },
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    fieldType() {
      if (this.includeClass && this.isRequired) {
        return this.isValid ? 'is-success' : 'is-danger'
      } else {
        return ''
      }
    },
    inputClass() {
      if (this.includeClass && this.isRequired) {
        return this.isValid ? 'input is-success' : 'input is-danger'
      } else {
        return 'input'
      }
    },
    isValid() {
      let val = this.fieldValue
      if (val == null || val == '' || val.length == 0) {
        return false
      } else {
        if (val.length < this.reqMinLength || val.length > this.reqMaxLength) {
          return false
        }
      }
      return true
    },
    required() {
      if (this.includeClass && this.isRequired) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style scoped></style>
