export function getProductOption(layout, productId, serverName) {
  let prodOption = {}
  prodOption.canOrg = true
  if (productId == 1 || productId == 2 || productId == 4 || productId == 5) {
    prodOption.tier = 'BASIC'
    prodOption.name = 'OIG (O) Check'
    if (layout.brandName == 'fadv') prodOption.name = 'HEAL SAM and OIG (O)'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 15 || productId == 17 || productId == 18 || productId == 19) {
    prodOption.tier = 'BASICPLUS'
    prodOption.name = 'OIG, Federal (OF) Check'
    if (layout.brandName == 'jdp') prodOption.name += ' [OIG,SAM]'
    if (layout.brandName == 'fadv') prodOption.name = 'Heal with OIG, SAM and all Federal (OF)'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 6 || productId == 7 || productId == 9 || productId == 10) {
    prodOption.tier = 'STANDARD'
    prodOption.name = 'OIG, Federal, State (OFS) Check'
    if (layout.brandName == 'jdp') prodOption.name += ' [Healthcare Sanctions Search]'
    if (layout.brandName == 'fadv') prodOption.name = 'Heal M + OIG, Federal, State Exclusions (OFS)'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 26 || productId == 28 || productId == 29 || productId == 30) {
    prodOption.tier = 'STANDARDPLUS'
    prodOption.name = 'OIG, Federal, State Board (OFSB) Check'
    // if (layout.brandName == 'fadv') prodOption.name += ' Quest-like'
    prodOption.category = 'Exclusion Search'
    prodOption.canOrg = false
  } else if (productId == 11 || productId == 13) {
    prodOption.tier = 'LICENSECHECK'
    prodOption.name = 'License Check'
    prodOption.category = 'License'
  }  else if (productId == 115 || productId == 116){
    prodOption.tier = 'LICENSEINFO'
    prodOption.name = 'License Info'
    prodOption.category = 'License'
  } else if (productId == 34 || productId == 35) {
    // SKIP - this is automatically handled as an addon to LICENSECHECK now
    // | 34 | License Search | License | License
    // | 35 | License Search Monitoring | License | License
  } else if (productId == 31) {
    prodOption.tier = 'AKA'
    prodOption.name = 'AKA Enhanced Search'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 32 || productId == 37) {
    prodOption.tier = 'BOARDONLY'
    prodOption.name = 'Board Actions Only'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 42 || productId == 43 || productId == 44 || productId == 45) {
    prodOption.tier = 'SPECIAL'
    prodOption.name = 'Special Data Set Search'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 46 || productId == 47) {
    prodOption.tier = 'STANDARDNOPRESS'
    prodOption.name = 'Standard Special - No Press Releases'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 48 || productId == 49) {
    prodOption.tier = 'ABUSE'
    prodOption.name = 'Abuse'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 51) {
    prodOption.tier = 'OFACONLY'
    prodOption.name = 'OFAC Only Check'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 53) {
    prodOption.tier = 'ONETIME'
    prodOption.name = 'One Time Special'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 54) {
    // prodOption.tier = 'NULL'
  } else if (productId == 55) {
    // prodOption.tier = 'LICENSELOOKUP'
  } else if (productId == 56 || productId == 57 || productId == 58 || productId == 59) {
    prodOption.tier = 'OFACDELUXE'
    prodOption.name = 'OFAC Deluxe'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 60 || productId == 61 || productId == 62 || productId == 63) {
    prodOption.tier = 'GLOBALTHREAT'
    prodOption.name = 'Global Threat'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 64 || productId == 65 || productId == 66 || productId == 67) {
    prodOption.tier = 'OIGSAMONLY'
    prodOption.name = 'OIG+SAM Search'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 68 || productId == 69 || productId == 70 || productId == 71) {
    prodOption.tier = 'ESSENTIALS'
    prodOption.name = 'Essentials'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 72 || productId == 73 || productId == 74 || productId == 75) {
    prodOption.tier = 'INFOCSPECIAL'
    prodOption.name = 'Info Special'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 76 || productId == 77 || productId == 78 || productId == 79) {
    prodOption.tier = 'STBA'
    prodOption.name = 'STBA'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 80 || productId == 81 || productId == 82 || productId == 83) {
    prodOption.tier = 'STEX'
    prodOption.name = 'STEX'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 84 || productId == 85 || productId == 86 || productId == 87) {
    prodOption.tier = 'STAR'
    prodOption.name = 'STAR'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 88 || productId == 89 || productId == 90 || productId == 91) {
    prodOption.tier = 'STMOP'
    prodOption.name = 'STMOP'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 92 || productId == 93 || productId == 94 || productId == 95) {
    prodOption.tier = 'USLSTS'
    prodOption.name = 'USLSTS'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 96 || productId == 97 || productId == 98 || productId == 99) {
    prodOption.tier = 'EXPGLOBALTHREAT'
    prodOption.name = 'Expanded Global Threat'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 101 || productId == 103 || productId == 105 || productId == 107) {
    prodOption.tier = 'RXESSENTIALS'
    prodOption.name = 'Pharmacy Essentials'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 108) {
    prodOption.tier = 'CVO'
    prodOption.name = 'CVO'
    prodOption.category = 'Exclusion Search'
  } else if (productId == 109) {
    prodOption.tier = 'CVODMF'
    prodOption.name = 'CVO with SSNDMF'
    prodOption.category = 'Exclusion Search'
  }
  else if (productId == 113 || 114) {
    prodOption.tier = 'DMF'
    prodOption.name = 'SSNDMF'
    prodOption.category = 'Exclusion Search'
  } 
  else {
    // } else if (productId > 30) {
    prodOption.name = serverName
  }
  return prodOption
}
