<template>
  <div>
    <ProviderResultsSearchBar
      :byDob="false"
      :byId="false"
      :byGroups="true"
      :byName="true"
      :bySsn="false"
      :byReference="true"
      :byUser="true"
      :byStatus="true"
      :includeMonitoring="true"
      :includeOnetime="false"
      :isResults="false"
    />
    <section class="box">
      <ProviderRosterList @refresh="onGetResults({})" :showMonitoring="true" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProviderRosterList from '@/components/bryce/ProviderRosterList.vue'
import ProviderResultsSearchBar from '@/components/bryce/ProviderResultsSearchBar.vue'

export default {
  components: {
    ProviderRosterList,
    ProviderResultsSearchBar
  },
  created() {
    this.$store.dispatch('user_profile/fetch', {})
  },
  mounted() {
    this.$store.dispatch('provider_result/fetchInit', { options: { fetchAction: 'MonitoringRoster', enableLocalFilters: false } })
  },
  computed: {
    ...mapState(['provider_result'])
  },
  methods: {
    fetchResults() {
      let searchOptions = {
        includeMonitoring: true
      }
      this.$store.dispatch('provider_result/fetchIt', { options: searchOptions })
    },
    onGetResults() {
      this.fetchResults({})
    }
  }
}
</script>

<style scoped></style>
